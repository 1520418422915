(function () {
    'use strict';

    angular.module('app')
        .factory('Comentario', Comentario);

    /* @ngInject */
    function Comentario(ResourceHelper) {
        return ResourceHelper.getResource("comentario", {}, {
            'findAllByLibro': {
                method: 'GET',
                url: 'api/public/comentario/findAllByLibro',
                isArray: false
            },
            'findAllByTarea': {
                method: 'GET',
                url: 'api/public/comentario/findAllByTarea',
                isArray: false
            },
            'findAllByLibroAndCentro': {
                method: 'GET',
                url: 'api/public/comentario/findAllByLibroAndCentro',
                isArray: false
            },
            'findAllByClub': {
                method: 'GET',
                url: 'api/public/comentario/findAllByClub',
                isArray: false
            },
            'getActivatedTypesByUsuarioActivo': {
                method: 'GET',
                url: 'api/public/comentario/getActivatedTypesByUsuarioActivo',
                isArray: false
            },
            'filterByTypeAndUsuarioActivo': {
                method: 'GET',
                url: 'api/public/comentario/filterByTypeAndUsuarioActivo',
                isArray: false
            },
            'getPuntuacionMedia': {
                method: 'GET',
                url: 'api/public/comentario/getPuntuacionMedia',
                isArray: false,
                transformResponse: function (data) {
                    return {data: data};
                }
            },
            'elevar': {
                method: 'POST',
                url: 'api/public/comentario/elevar',
                isArray: false
            }
        }, true);
    }

})();
