(function () {
    'use strict';

    angular.module('app')
        .factory('Historico', Historico);

    /* @ngInject */
    function Historico(ResourceHelper) {
        return ResourceHelper.getResource("historico", {}, {});
    }

})();
