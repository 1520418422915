(function () {
    'use strict';

    angular.module('app')
        .factory('Deseo', Deseo);

    /* @ngInject */
    function Deseo(ResourceHelper) {
        return ResourceHelper.getResource("deseo", {}, {
            'getLista': {
                method: 'GET',
                url: 'api/public/deseo/getLista',
                isArray: true
            },
            'addDeseo': {
                method: 'POST',
                url: 'api/public/deseo/addDeseo',
                isArray: false
            },
            "remove": {
                method: 'DELETE',
                url: 'api/public/deseo/:id'
            }
        });
    }

})();
