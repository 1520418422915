(function () {
    'use strict';

    angular.module('app')
        .factory('Estadisticas', Estadisticas);

    /* @ngInject */
    function Estadisticas($resource) {

        var entidad = "estadisticas";
        return $resource('api/admin/' + entidad + '/:id', {}, {
            'getEstadisticasUsuarios': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/usuarios',
                isArray: false
            },
            'getEstadisticasUsuario': {
                method: 'GET',
                url: 'api/public/' + entidad + '/usuario',
                isArray: false
            },
            'getEstadisticasUsuarioDetallado': {
                method: 'GET',
                url: 'api/public/' + entidad + '/usuarioDetallado',
                isArray: false
            },
            'getEstadisticasLectura': {
                method: 'GET',
                url: 'api/public/' + entidad + '/usuarioLecturas',
                isArray: true
            },
            'getEstadisticasLecturaCentro': {
                method: 'GET',
                url: 'api/public/' + entidad + '/centroLecturas',
                isArray: true
            },
            'getEstadisticasAlumno': {
                method: 'GET',
                url: 'api/public/' + entidad + '/estadisticasAlumno',
                isArray: false
            },
            'getEstadisticasLibros': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/libros',
                isArray: false
            },
            'getEstadisticasVentas': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/ventas',
                isArray: false
            },
            'getEstadisticasCentros': {
                method: 'GET',
                url: 'api/public/' + entidad + '/estadisticasCentro',
                isArray: false
            },
            'getBeneficioTotal': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/beneficio',
                transformResponse: function (data) {
                    return {data: data};
                }
            },
            'getEstadisticaLibroDetallada': {
                method: 'GET',
                url: 'api/public/' + entidad + '/usuarioLibroDetails',
                isArray: false,
                params: {
                    id: Number
                }
            }
        });
    }

})();
