(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/perfil', {
                parent: 'miespacio',
                url: '/perfil',
                data: {
                    pageTitle: 'global.menu.miespacio.title',
                    titulo: 'miespacio.miperfil.title',
                    tituloImg: 'assets/images/iconos/perfil.png'
                },
                views: {
                    'contentEspacio': {
                        templateUrl: 'app/public/cuenta/miespacio/perfil/perfil.form.html',
                        controller: 'PerfilFormController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    itemData: function (Principal) {
                        return Principal.identity().then(function (data) {
                            return data;
                        });
                    }
                }
            })
            .state('miespacio/perfil/contrasena', {
                parent: 'miespacio',
                url: '/perfil/modificar-contrasena',
                views: {
                    'contentEspacio': {
                        template: "<h1 class='tituloPlan'>{{'miespacio.miperfil.cambiarcontrasena' | translate}}</h1>" +
                            "<change-password></change-password>"
                    }
                }
            })
            .state("miespacio/perfil/email", {
                parent: 'miespacio',
                url: "/perfil/modificar-email",
                views: {
                    'contentEspacio': {
                        template: "<h1 class='tituloPlan'>{{'miespacio.miperfil.cambiaremail.title' | translate}}</h1>" +
                            "<change-email></change-email>"
                    }
                }
            });
    }
})();
