(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/estadisticas', {
                parent: 'admin',
                url: '/admin/estadisticas',
                redirectTo: 'admin/estadisticas/usuarios',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                params: {
                    page: {value: '1', dynamic: true}
                },
                ncyBreadcrumb: {
                   skip: true
                }
            })
            .state('admin/estadisticas/usuarios', {
                parent: 'admin/estadisticas',
                url: '/usuarios/:page',
                views: {
                    'content@': {
                        templateUrl: 'app/admin/estadisticas/estadisticas.usuario.list.html',
                        controller: 'EstadisticasUsuarioController',
                        controllerAs: 'ctrl'
                    }
                },
                ncyBreadcrumb: {
                    label: "global.menu.users"
                }
            })
            .state('admin/estadisticas/centros', {
                parent: 'admin/estadisticas',
                url: '/centros/:page',
                views: {
                    'content@': {
                        templateUrl: 'app/admin/estadisticas/estadisticas.centro.list.html',
                        controller: 'EstadisticasCentroController',
                        controllerAs: 'ctrl'
                    }
                },
                ncyBreadcrumb: {
                    label: "global.menu.centros"
                }
            })
            .state('admin/estadisticas/libros', {
                parent: 'admin/estadisticas',
                url: '/libros/:page',
                views: {
                    'content@': {
                        templateUrl: 'app/admin/estadisticas/estadisticas.libro.list.html',
                        controller: 'EstadisticasLibroController',
                        controllerAs: 'ctrl'
                    }
                },
                ncyBreadcrumb: {
                    label: "global.menu.libros"
                }
            })
            .state('admin/estadisticas/ventas', {
                parent: 'admin/estadisticas',
                url: '/ventas',
                redirectTo: 'admin/estadisticas/ventas/list',
                ncyBreadcrumb: {
                    label: "global.menu.ventas"
                }
            })
            .state('admin/estadisticas/ventas/compra', {
                parent: 'admin/estadisticas/ventas',
                url: '/compra/:compraId/usuario/:id',
                views: {
                    'content@': {
                        template: '<div><label>{{\'admin.form.email\' | translate}}:</label> {{$resolve.factura.compra.usuario.email}}</div><div><label>{{\'admin.form.role\' | translate}}:</label> <enumerado base="enum.rol" value="{{$resolve.factura.compra.usuario.rol}}"></enumerado></div><compras-details factura="$resolve.factura" id="$resolve.id"></compras-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    factura: function (Factura, $stateParams) {
                        return Factura.get($stateParams.compraId);
                    },
                    /* @ngInject */
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                ncyBreadcrumb: {
                    label: "global.menu.detalles"
                }
            })
            .state('admin/estadisticas/ventas/list', {
                parent: 'admin/estadisticas/ventas',
                url: '/{page:int}',
                views: {
                    'content@': {
                        templateUrl: 'app/admin/estadisticas/estadisticas.venta.list.html',
                        controller: 'EstadisticasVentaController',
                        controllerAs: 'ctrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }
})();
