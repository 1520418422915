(function () {
    'use strict';

    // ID: ID del responsable (o del miembro) de los proyectos (opcional)
    // Activos: booleano para saber si los proyectos siguen vigentes


    angular
        .module('app')
        .component('proyectosAdmin', {
            templateUrl: 'app/admin/components/proyecto/proyectos.admin.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                id: '<',
                activos: '<'
            }
        });

    /* @ngInject */
    function Controller($stateParams, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper, Proyecto, Principal, ModalService) {
        var vm = this;

        vm.proyectos = [];
        vm.item = Proyecto;
        vm.function = 'findByUsuarioActivo';
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS_PUBLIC.size;
        vm.filter = {
            key: undefined,
            id: vm.id,
            activos: vm.activos
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {nombre: 'asc'}
        }, NgTableHelper.settings(vm));

        vm.today = new Date();

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    resolve: {
                        /* @ngInject */
                        item: function (Proyecto) {
                            return Proyecto.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'miespacio.proyecto.delete.title',
                                body: 'miespacio.proyecto.delete.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

        // Cerrar
        vm.showCerrarConfirmation = function (tarea) {
            var proyecto = getProyecto(tarea, vm.proyectos);
            showConfirmation(proyecto, 'cerrar');
        };

        // Reabrir
        vm.showReabrirConfirmation = function (proyecto) {
            showConfirmation(proyecto, 'reabrir');
        };

        vm.showAlumnos = function (tarea) {
            var proyecto = getProyecto(tarea, vm.proyectos);
            ModalService.open({
                templateUrl: 'app/public/components/proyectos/alumno/alumnos-proyecto.modal.html',
                controller: 'AlumnosProyectoModalController',
                controllerAs: 'ctrl',
                resolve: {
                    item: function () {
                        return proyecto;
                    },
                    params: function () {
                        return {id: proyecto.id};
                    }
                }
            }).result.then(function () {
            });
        };

        function showConfirmation(proyecto, metodo) {
            ModalService.open({
                    templateUrl: 'app/components/form/confirm/confirm.modal.html',
                    controller: 'ConfirmModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        item: function () {
                            return proyecto;
                        },
                        /* @ngInject */
                        tipo: function (Proyecto) {
                            return Proyecto;
                        },
                        funcion: function () {
                            return metodo;
                        },
                        parametros: function () {
                            return {id: proyecto.id};
                        },
                        params: function () {
                            return {
                                title: 'miespacio.proyecto.' + metodo + '.title',
                                body: 'miespacio.proyecto.' + metodo + '.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        }

        function init() {
            Principal.identity().then(function (data) {
                vm.responsableVisible = vm.id === undefined && (data.type === 'centro' || data.type === 'lector');
                vm.profesorActivado = data && data.activado && data.centro != null;
                vm.tieneCentro = data && data.centro && data.centro.id != null;
                vm.tareas = [];
            });
        }

        init();

        vm.getTareas = function (proyectos) {
            vm.proyectos = proyectos;
            var tareas = [];
            for (var i = 0; i < proyectos.length; i++) {
                tareas = tareas.concat(proyectos[i].tareas);
            }
            return tareas;
        };

        vm.getResponsableProyecto = function (tarea, proyectos) {
            for (var i = 0; i < proyectos.length; i++) {
                for (var j = 0; j < proyectos[i].tareas.length; j++) {
                    if (proyectos[i].tareas[j].id === tarea.id) {
                        return proyectos[i].responsableNombre;
                    }
                }
            }
        };

        vm.getFechaInicioProyecto = function (tarea, proyectos) {
            for (var i = 0; i < proyectos.length; i++) {
                for (var j = 0; j < proyectos[i].tareas.length; j++) {
                    if (proyectos[i].tareas[j].id === tarea.id) {
                        return proyectos[i].fechaInicio;
                    }
                }
            }
        };

        vm.getFechaFinProyecto = function (tarea, proyectos) {
            for (var i = 0; i < proyectos.length; i++) {
                for (var j = 0; j < proyectos[i].tareas.length; j++) {
                    if (proyectos[i].tareas[j].id === tarea.id) {
                        return proyectos[i].fechaFin;
                    }
                }
            }
        };

        vm.getProyectoId = function (tarea, proyectos) {
            for (var i = 0; i < proyectos.length; i++) {
                for (var j = 0; j < proyectos[i].tareas.length; j++) {
                    if (proyectos[i].tareas[j].id === tarea.id) {
                        return proyectos[i].id;
                    }
                }
            }
        };

        function getProyecto(tarea, proyectos) {
            for (var i = 0; i < proyectos.length; i++) {
                for (var j = 0; j < proyectos[i].tareas.length; j++) {
                    if (proyectos[i].tareas[j].id === tarea.id) {
                        return proyectos[i];
                    }
                }
            }
        }
    }

})();
