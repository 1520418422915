(function () {
    'use strict';

    angular.module('app').component('mainSlider', {
        controllerAs: 'ctrl',
        template:
            '<div ng-if="ctrl.slides && ctrl.slides.length" class="main-slider"><slider slides="ctrl.slides"></slider></div>',
        controller: function ($rootScope, Slide) {

            var vm = this;
            var deregister = $rootScope.$on('$stateChangeSuccess', function (_, toState) {
                if (toState.name === 'home') {
                    loadSlides();
                } else {
                    vm.slides = [];
                }
            });

            vm.$onDestroy = function () {
                deregister();
            };

            function loadSlides() {
                Slide.findAll().$promise.then(function (data) {
                    vm.slides = data;
                });
            }
        }

    });
})();
