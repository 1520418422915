(function () {
    'use strict';

    var buttons = {
        templateUrl: 'app/components/form/buttons/buttons.form.template.html',
        bindings: {
            canSave: '<',
            mode: '<'
        }
    };

    angular
        .module('app')
        .component('buttons', buttons);

})();
