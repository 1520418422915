(function () {
    'use strict';

    angular.module('app')
        .factory('Club', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = 'club';

        return ResourceHelper.getResource(entidad, {}, {
            'findByUsuarioActivo': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findByUsuarioActivo',
                isArray: false
            },
            'renovar': {
                method: 'GET',
                url: 'api/public/' + entidad + '/renovar'
            },
            'unirse': {
                method: 'GET',
                url: 'api/public/' + entidad + '/unirse',
                isArray: false
            }
        }, true);
    }

})();
