(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('SuscripcionPackRevisarController', Controller);

    /* @ngInject */
    function Controller($state, $window, $stateParams, Compra, AlertService) {
        var vm = this;

        vm.pack = $stateParams.pack;

        vm.confirmar = function () {
            Compra.crearSuscripcionPack(vm.pack).$promise.then(function () {
                AlertService.success('catalogo.packs.confirmar.exito');
                $state.go('miespacio/packs/mispacks');
            });
        };

        vm.cancel = function () {
            $window.history.back();
        };

    }

})(angular);
