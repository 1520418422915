(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        // Confirmación de solicitud de un pack para los centros
        $stateProvider.state('suscripcionPack', {
            parent: 'public',
            url: '/suscripcion-pack',
            params: {
                pack: null
            },
            data: {
                authorities: ['ROLE_CENTRO'],
                pageTitle: 'global.menu.compra'
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/suscripcion/pack/suscripcion-pack.revisar.html',
                    controller: 'SuscripcionPackRevisarController',
                    controllerAs: 'ctrl'
                }
            }
            // Confirmación de solicitud de un libro (que se tratará como un pack) para los centros
        }).state('suscripcionLibro', {
            parent: 'public',
            url: '/suscripcion-libro',
            params: {
                libro: null
            },
            data: {
                authorities: ['ROLE_CENTRO'],
                pageTitle: 'global.menu.compra'
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/suscripcion/libro/suscripcion-libro.revisar.html',
                    controller: 'SuscripcionLibroRevisarController',
                    controllerAs: 'ctrl'
                }
            },
            resolve: {}
        });
    }
})();
