(function () {
    'use strict';

    angular.module('app')
        .controller('EstadisticasVentaController', EstadisticasVentaController);

    /* @ngInject */
    function EstadisticasVentaController($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
                                         Estadisticas) {
        var vm = this;
        var filter = {};

        vm.roles = ['ROLE_LECTOR', 'ROLE_PROFESOR', 'ROLE_CENTRO', 'ROLE_LIBRERIA'];

        vm.item = Estadisticas;
        vm.function = "getEstadisticasVentas";
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            roles: undefined
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {fecha: 'desc'}
        }, NgTableHelper.settings(vm));

        function calcularBeneficio(filtro) {
            Estadisticas.getBeneficioTotal(filtro).$promise.then(function (result) {
                vm.beneficioTotal = result.data;
            });
        }

        vm.updateRoles = function (input) {
            calcularBeneficio(angular.extend(filter, vm.filter, {roles: input}));
        };

        vm.updateFechaInicio = function (input) {
            calcularBeneficio(angular.extend(filter, vm.filter, {fechaInicio: input}));
        };

        vm.updateFechaFin = function (input) {
            calcularBeneficio(angular.extend(filter, vm.filter, {fechaFin: input}));
        };

        calcularBeneficio(vm.filter);

    }

})();
