(function () {
    'use strict';

    angular
        .module('app')
        .component('libroDetails', {
            templateUrl: 'app/public/components/libro/libro.details.html',
            controller: LibroController,
            controllerAs: 'ctrl',
            bindings: {
                libro: '<',
                estadisticas: '<'
            }
        });

    /* @ngInject */
    function LibroController($state, Principal, AlumnoCentro, CarroHelper, DeseoHelper, Recurso, LibroAdquirido,
        URL_RECURSO, RutasService, ModalService, Estadisticas, LibroModalHelper, $timeout) {
        var vm = this;


        Principal.identity().then(function (data) {
            vm.isAuthenticated = !!data;
            if (data && data.rol === 'ROLE_CENTRO') {
                vm.esCentro = true;
            }
            if (data && data.rol === 'ROLE_PROFESOR') {
                vm.esProfesor = true;
            }
        });

        vm.rutaPortadaFull = RutasService.getPortada(vm.libro.id, vm.libro.portada, true);

        vm.addToCarro = function (libro) {
            CarroHelper.addProducto(libro);
        };

        vm.cogerPrestado = function (libro) {
            LibroAdquirido.cogerPrestado({libroId: libro.id}).$promise.then(function () {
                $state.go('miespacio/mislibros');
            });
        };

        vm.addRegaloToCarro = function (libro) {
            CarroHelper.addRegalo(libro);
        };

        vm.addDeseo = function (libro) {
            DeseoHelper.addDeseo(libro);
        };

        vm.crearTarea = function () {
            if (vm.esProfesor) {
                $state.go('miespacio/tarea/create', {libroModal: vm.libro});
            } else {
                LibroModalHelper.addTarea();
            }
        };

        vm.crearClub = function () {
            if (vm.isAuthenticated) {
                $state.go('miespacio/club/create', {libroModal: vm.libro});
            } else {
                LibroModalHelper.addClub();
            }
        };

        // Si se ha intentado añadir un libro a la lista de deseos sin estar autenticado
        if ($state.params.deseo) {
            vm.addDeseo(vm.libro);
        }

        vm.abrirImagen = function () {
            if (vm.libro.portada !== null) {
                ModalService.openImagen(vm.rutaPortadaFull);
            }
        };

        // Recursos

        Recurso.findByLibroAgrupadoPorCategoria({libroId: vm.libro.id}).$promise.then(function (data) {
            if (!angular.equals({}, data)) {
                vm.mapaCategoriasRecursos = data;
            }
        });

        vm.urlRecurso = URL_RECURSO(vm.libro.id);

        vm.actualizarTipoRecurso = function (archivo) {
            var filter = /(?:\.([^.]+))?$/;
            var ext = filter.exec(archivo)[1];
            switch (ext) {
            case "pdf":
                return "fa fa-file-pdf-o";
            case "xls":
            case "xlsx":
                return "fa fa-file-excel-o";
            case "ppt":
            case "pptx":
                return "fa fa-file-powerpoint-o";
            case "doc":
            case "docx":
                return "fa fa-file-word-o";
            case "txt":
                return "fa fa-file-text";
            default:
                return "fa fa-file";
            }
        };

        if (vm.libro.libroAdquirido) {
            vm.estadisticas = Estadisticas.getEstadisticaLibroDetallada({id: vm.libro.id});
        }

        // *** Alto de columna resumen ***

        vm.resumenLineas = undefined;
        vm.resumenColapsado = true;
        vm.mostrarVerMasResumen = false;

        vm.toggleResumen = function () {
            if (vm.resumenColapsado) {
                vm.resumenLineas = undefined;
            } else {
                updateResumenHeight();
            }
            vm.resumenColapsado = !vm.resumenColapsado;
        };

        vm.initBloqueDatosLibro = function () {
            $timeout(function () {
                updateResumenHeight();
            }, 0);
        };

        vm.getResumenStyles = function () {
            return {
                '-webkit-line-clamp': vm.resumenLineas + ''
            };
        };

        // Obtener y establecer la altura de la primera columna a la segunda
        function updateResumenHeight() {
            var columnaDatosLibro = angular.element("#bloqueDatosLibro");
            var columnaResumen = angular.element("#bloqueResumen");
            columnaResumen.css('min-height', columnaDatosLibro.outerHeight()); // altura mínima para que encajen los separadores
            var alturaFinal = columnaDatosLibro.height() - 45;
            calcularNumLineasResumen(alturaFinal);
        }

        // Cálculo del nº de líneas que se pueden mostrar
        function calcularNumLineasResumen(altura) {
            var contenido = angular.element("#textoResumen");
            var lineHeight = parseInt(contenido.css('line-height'));

            vm.mostrarVerMasResumen = (contenido.height() - 20) > altura; // si no hay mucho texto, ya no se muestra el botón de ver más
            if (vm.mostrarVerMasResumen) {
                vm.resumenLineas = Math.floor(altura / lineHeight);
            }
        }

    }

})();
