(function () {
    'use strict';

    angular.module('app')
        .factory('Recurso', Recurso);

    /* @ngInject */
    function Recurso(ResourceHelper) {
        return ResourceHelper.getResource("recurso", {}, {
            'findByLibroAgrupadoPorCategoria': {
                method: 'GET',
                url: 'api/public/recurso/findByLibroAgrupadoPorCategoria',
                isArray: false
            },
            'findAllByLibro': {
                method: 'GET',
                url: 'api/public/recurso/findAllByLibro',
                isArray: false
            }
        });
    }

})();
