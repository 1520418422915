(function () {
    'use strict';

    angular
        .module('app')
        .component('clubList', {
            templateUrl: 'app/public/components/clubes/club.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                administra: '<'
            }
        });

    /* @ngInject */
    function Controller($state, $stateParams, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper, ModalService,
                        Club, Miembro, Invitacion) {
        var vm = this;

        vm.item = Club;
        vm.activos = true;
        vm.function = 'findByUsuarioActivo';
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS_PUBLIC.size;
        vm.filter = {
            key: undefined,
            administra: vm.administra,
            activos: vm.activos
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {'nombre': 'asc'}
        }, NgTableHelper.settings(vm));

        vm.today = new Date().getTime() / 1000;

        if (vm.administra) {
            // Renovar club
            vm.showRenovarConfirmation = function (item) {
                ModalService.open({
                        templateUrl: 'app/components/form/confirm/confirm.modal.html',
                        controller: 'ConfirmModalController',
                        controllerAs: 'ctrl',
                        resolve: {
                            item: function () {
                                return item;
                            },
                            /* @ngInject */
                            tipo: function (Club) {
                                return Club;
                            },
                            funcion: function () {
                                return "renovar";
                            },
                            parametros: function () {
                                return {clubId: item.id}
                            },
                            params: function () {
                                return {
                                    title: 'miespacio.club.renovar.title',
                                    body: 'miespacio.club.renovar.confirm',
                                    property: 'nombre'
                                };
                            }
                        }
                    }
                ).result.then(function (item) {
                    item.fechaFin = item.fechaFin + (15 * 24 * 3600);
                });
            };
        }

        if (!vm.administra) {
            Invitacion.checkPendientes().$promise.then(function (result) {
                vm.pendientes = result.data;
            });

            vm.responderInvitacion = function (item, aceptar) {
                Invitacion.responderInvitacion({clubId: item.id, aceptar: aceptar}).$promise.then(function () {
                    if (aceptar) {
                        $state.go('miespacio/club/details', {id: item.id});
                    } else {
                        vm.tableParams.reload();
                    }
                });
            };

            // Abandonar club
            vm.showAbandonarConfirmation = function (item) {
                ModalService.open({
                        templateUrl: 'app/components/form/confirm/confirm.modal.html',
                        controller: 'ConfirmModalController',
                        controllerAs: 'ctrl',
                        resolve: {
                            item: function () {
                                return item;
                            },
                            /* @ngInject */
                            tipo: function (Miembro) {
                                return Miembro;
                            },
                            funcion: function () {
                                return "abandonar";
                            },
                            parametros: function () {
                                return {clubId: item.id, abandonar: true}
                            },
                            params: function () {
                                return {
                                    title: 'miespacio.club.abandonar.title',
                                    body: 'miespacio.club.abandonar.confirm',
                                    property: 'nombre'
                                };
                            }
                        }
                    }
                ).result.then(function () {
                    vm.tableParams.reload();
                });
            };

            // Volver club
            vm.volver = function (item) {
                Miembro.abandonar({clubId: item.id, abandonar: false}).$promise.then(function () {
                    vm.tableParams.reload();
                });
            };
        }
    }

})
();
