(function () {
    'use strict';

    angular.module('app')
        .factory('CompraHelper', CompraHelper);

    /* @ngInject */
    function CompraHelper($window, $state, Compra, AlertService) {
        return {
            crearSuscripcionCentro: crearSuscripcionCentro
        };

        // Crear suscripción de un centro en el momento en el que se registra
        function crearSuscripcionCentro(centroId, packs) {
            Compra.crearSuscripcionCentro({
                centroId: centroId,
                packs: packs
            }).$promise.then(function (data) {
                console.log("Suscripción creada:", data);

                Compra.iniciarPaypalCentro({compraId: data.id}).$promise.then(function (result) {
                    if (!result.msg) {
                        $state.go("registro/info");
                        AlertService.success("compra.compra.successcentro");
                    } else {
                        $window.location.href = result.msg;
                    }
                });
            });
        }

    }

})();
