(function () {
    'use strict';

    angular.module('app')
        .controller('SuscripcionDeleteModalController', SuscripcionDeleteModalController);

    /* @ngInject */
    function SuscripcionDeleteModalController(item, Suscripcion, params, ModalService) {
        var vm = this;

        vm.dismiss = ModalService.dismiss;
        vm.suscripcion = item;
        vm.params = params;

        vm.confirm = function (suscripcion) {
            Suscripcion.cambiarEstado(suscripcion).$promise.then(function () {
                suscripcion.solicitudCancelacion = false;
                suscripcion.fechaSolicitudCancelacion = null;
            }).catch(function () {
                suscripcion.activa = !suscripcion.activa;
            });
            vm.suscripcion.activa = !vm.suscripcion.activa;
            ModalService.close();
        };
    }
})();
