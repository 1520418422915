(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/promocion";
        var params = {
            base: base,
            baseUrl: "/" + base,
            templateUrlBase: "app/admin/promocion/promocion/promocion",
            controller: 'PromocionAdminController',
            controllerForm: 'PromocionFormAdminController',
            translateBase: "admin.promocion.promocion."
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN']
            }
        });
        // Listar
        angular.merge(state.list, {});
        // Crear
        angular.merge(state.create, {
            resolve: {
                /* @ngInject */
                libros: function (Libro) {
                    return Libro.filter({enVenta: true}).$promise.then(function (data) {
                        return data.content;
                    });
                },
                /* @ngInject */
                categorias: function (Categoria) {
                    return Categoria.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
                        return data.content;
                    });
                },
                /* @ngInject */
                contactos: function (Contacto) {
                    return Contacto.findAll({sortProperty: 'categoria.nombre'}).$promise.then(function (data) {
                        return data.content;
                    });
                },
                /* @ngInject */
                ivas: function (Iva) {
                    return Iva.findAll().$promise.then(function (data) {
                        return data.content;
                    });
                },

                /* @ngInject */
                tiposPromocion: function (EnumService) {
                    return EnumService.get("tipo-promocion").then(function (result) {
                        return result;
                    });
                }
            }
        });
        // Editar
        angular.merge(state.edit, {
            views: {
                'content@': {
                    templateUrl: 'app/admin/promocion/promocion/promocion.details.html',
                    controller: 'PromocionDetailsAdminController',
                    controllerAs: 'ctrl'
                }
            },
            resolve: {
                /* @ngInject */
                promocion: function (Promocion, $stateParams) {
                    return Promocion.getDto({id: $stateParams.id}).$promise;
                }
            },
            ncyBreadcrumb: {
                label: "admin.promocion.promocion.details.title"
            }
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
