(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/libro/recurso";
        var params = {
            base: base,
            baseUrl: "/recurso",
            parent: 'admin/libro',
            templateUrlBase: "app/admin/libro/recurso/recurso",
            controller: 'RecursoAdminController',
            controllerForm: 'RecursoFormAdminController',
            translateBase: "admin.libro.recurso."
        };
        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            params: {
                libroId: undefined,
                titulo: undefined
            },
            ncyBreadcrumb: {
                parent: "admin/libro/list"
            }
        });
        // Listar
        angular.merge(state.list, {
        });
        // Crear
        angular.merge(state.create, {
            params: {},
            resolve: {
                /* @ngInject */
                categoriaRecursos: function (CategoriaRecurso) {
                    return CategoriaRecurso.findAll().$promise.then(function (data) {
                        return data.content;
                    });
                },
                /* @ngInject */
                itemData: function (Recurso) {
                    return new Recurso();
                }
            }
        });
        // Editar
        angular.merge(state.edit, {
            params: {},
            resolve: {
                /* @ngInject */
                categoriaRecursos: function (CategoriaRecurso) {
                    return CategoriaRecurso.findAll().$promise.then(function (data) {
                        return data.content;
                    });
                },
                /* @ngInject */
                itemData: function ($stateParams, Recurso) {
                    return Recurso.get({id: $stateParams.id}).$promise;
                }
            }
        });
        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
