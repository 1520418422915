(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('ConfiguracionFormAdminController', ConfiguracionFormAdminController);

    /* @ngInject */
    function ConfiguracionFormAdminController($timeout, $state, itemData) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        // Guardar formulario
        vm.save = function () {
            // FIXME: Apaño temporal para la edición de fechas...
            if (angular.isNumber(vm.item.inicioCursoAcademico)) {
                vm.item.inicioCursoAcademico = vm.item.inicioCursoAcademico / 1000;
            }
            vm.item.$save().then(function (data) {
                vm.item.inicioCursoAcademico = data.inicioCursoAcademico * 1000;
            });
        };
    }

})(angular);
