(function () {
    'use strict';

    angular.module('app')
        .factory('Pdf', Pdf);

    /* @ngInject */
    function Pdf(ResourceHelper) {
        return ResourceHelper.getResource("pdf", {}, {
            'numPalabrasTotal': {
                method: 'GET',
                url: 'api/public/libro/pdfPalabrasTotal',
                params: {
                    id: '@ids',
                    paginas: '@paginas'
                },
                isArray: false
            },
            'guardarAvance': {
                method: 'POST',
                params: {
                    libroAdquirido: "",
                    tiempo: 0,
                    numeroPalabras: 0
                },
                url: 'api/public/pdf/guardarAvance',
                isArray: false
            }
        });
    }

})();
