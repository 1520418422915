(function () {
    'use strict';

    angular.module('app').controller('SlideAdminController', SlideAdminController);


    function SlideAdminController($state, Slide, ModalService) {
        var vm = this;

        vm.item = Slide;

        loadData();

        vm.edit = function (id) {
            $state.go('admin/slide/edit', {slideId: id});
        };

        vm.create = function () {
            $state.go('admin/slide/create');
        };

        vm.subir = function (id, index) {
            if (index === 0) {
                return;
            }
            Slide.subir({slideId: id}, function () {
                var previous = vm.items[index - 1];
                vm.items[index - 1] = vm.items[index];
                vm.items[index] = previous;
            });
        };

        vm.bajar = function (id, index) {
            if (index === vm.items.length - 1) {
                return;
            }

            Slide.bajar({slideId: id}, function () {
                var next = vm.items[index + 1];
                vm.items[index + 1] = vm.items[index];
                vm.items[index] = next;
            });

        };

        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                controller: 'EntityDeleteModalController',
                controllerAs: 'deleteCtrl',
                resolve: {
                    /* @ngInject */
                    item: function (Slide) {
                        return Slide.get({slideId: id}).$promise;
                    },
                    params: function () {
                        return {
                            title: 'admin.configuracion.slide.delete.title',
                            body: 'admin.configuracion.slide.delete.confirm',
                            property: 'id'
                        };
                    }
                }
            }
            ).result.then(function () {
                loadData();
            });
        };

        function loadData() {
            vm.items = Slide.query();
        }
    }
})();
