(function () {
    'use strict';

    angular.module('app')
        .factory('Configuracion', Configuracion);

    /* @ngInject */
    function Configuracion(ResourceHelper) {
        var entidad = "configuracion";
        return ResourceHelper.getResource(entidad, {}, {
            'getFechaFinCurso': {
                method: 'GET',
                url: 'api/public/' + entidad + '/getFechaFinCurso',
                transformResponse: function (data) {
                    return {data: data * 1};
                }
            },
            'getFechasCurso': {
                method: 'GET',
                url: 'api/public/' + entidad + '/getFechasCurso',
                isArray: true
            }
        });
    }

})();
