(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('ContactoFormAdminController', ContactoFormAdminController);

    /* @ngInject */
    function ContactoFormAdminController($timeout, $state, itemData, previousParams, categorias) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        vm.categorias = categorias.content;

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                $state.go('admin/contacto/list', previousParams);
            });
        };

    }

})(angular);
