(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('CompraController', CompraController);

    /* @ngInject */
    function CompraController($state, $window, compra, Compra, CarroHelper, AlertService) {
        var vm = this;

        vm.compra = compra;

        CarroHelper.sync(true).then(function () {
            vm.carro = CarroHelper.get();
        });

        vm.confirmFree = function () {
            Compra.tramitarGratuita().$promise.then(function () {
                console.log("compra tramitada");
                CarroHelper.clear();
                $state.go('miespacio/miscompras');
            });
        };

        vm.confirmPaypal = function () {
            Compra.iniciarPaypal().$promise.then(function (result) {
                if (!result.msg) {
                    // Mensaje de error
                    AlertService.error("compra.compra.error.paypal");
                } else {
                    $window.location.href = result.msg;
                }
            });
        };

        vm.cancel = function () {
            $state.go('compra/cancel');
        };

    }

})(angular);
