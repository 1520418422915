(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('HomeController', HomeController);

    /* @ngInject */
    function HomeController(Libro, Slide, $stateParams, $anchorScroll, $location, $timeout, $rootScope) {
        var vm = this;

        //limite grande para traer una lista
        vm.limite = 100;
        vm.iProximamente = 0;
        vm.iNovedades = 0;
        vm.iMasLeido = 0;
        vm.iMasComentado = 0;

        Libro.findNovedades(vm.limite).$promise.then(function (data) {
            vm.novedades = data.content;
            vm.loadedNovedades = true;
            setShownDataNovedades();
            navegarAncla();
        });

        Libro.findMasLeidos(vm.limite).$promise.then(function (data) {
            vm.masLeidos = data;
            vm.loadedMasLeidos = true;
            setShownDataMasLeido();
            navegarAncla();
        });

        Libro.findMasComentados(vm.limite).$promise.then(function (data) {
            vm.masComentados = data;
            vm.loadedMasComentados = true;
            setShownDataMasComentado();
            navegarAncla();
        });

        Libro.findProximamente(vm.limite).$promise.then(function (data) {
            vm.proximamente = data;
            vm.loadedProximamente = true;
            setShownDataProximamente();
            navegarAncla();
        });


        function navegarAncla() {
            if (vm.loadedNovedades && vm.loadedMasLeidos && vm.loadedMasComentados && vm.loadedProximamente) {
                var anchor = $location.hash();
                if (anchor) {
                    $timeout(function () {
                        $anchorScroll(anchor);
                    }, 100);
                }
            }
        }

        //PROXIMAMENTE
        function setShownDataProximamente(){
            vm.proximamenteShown = vm.proximamente.slice(vm.iProximamente*5, (vm.iProximamente+1)*5);
        }

        vm.previousProximamente = function () {
            if(vm.iProximamente !== 0) {
                vm.iProximamente = vm.iProximamente - 1;
                setShownDataMasLeido();
            }
        };

        vm.nextProximamente = function () {
            if( ((vm.iProximamente+1) * 5) < vm.proximamente.length){
                vm.iProximamente = vm.iProximamente + 1;
                setShownDataMasLeido();
            }
        };

        //NOVEDADES
        function setShownDataNovedades(){
            vm.novedadesShown = vm.novedades.slice(vm.iNovedades*5, (vm.iNovedades+1)*5);
        }

        vm.previousNovedades = function () {
            if(vm.iNovedades !== 0) {
                vm.iNovedades = vm.iNovedades - 1;
                setShownDataNovedades();
            }
        };

        vm.nextNovedades = function () {
            if( ((vm.iNovedades+1) * 5) < vm.novedades.length){
                vm.iNovedades = vm.iNovedades + 1;
                setShownDataNovedades();
            }
        };

        //MAS LEIDO
        function setShownDataMasLeido(){
            vm.masLeidoShown = vm.masLeidos.slice(vm.iMasLeido*5, (vm.iMasLeido+1)*5);
        }

        vm.previousMasLeido = function () {
            if(vm.iMasLeido !== 0) {
                vm.iMasLeido = vm.iMasLeido - 1;
                setShownDataMasLeido();
            }
        };

        vm.nextMasLeido = function () {
            if( ((vm.iMasLeido+1) * 5) < vm.masLeidos.length){
                vm.iMasLeido = vm.iMasLeido + 1;
                setShownDataMasLeido();
            }
        };

        //MAS COMENTADO
        function setShownDataMasComentado(){
            vm.masComentadoShown = vm.masComentados.slice(vm.iMasComentado*5, (vm.iMasComentado+1)*5);
        }

        vm.previousMasComentado = function () {
            if(vm.iMasComentado !== 0) {
                vm.iMasComentado = vm.iMasComentado - 1;
                setShownDataMasComentado();
            }
        };

        vm.nextMasComentado = function () {
            if( ((vm.iMasComentado+1) * 5) < vm.masComentados.length){
                vm.iMasComentado = vm.iMasComentado + 1;
                setShownDataMasComentado();
            }
        };

        vm.isMovil = function () {
            return $rootScope.isHorizontalScroll;
        };
        /*
        $( document ).ready(function() {
            $(window).on("orientationchange resize",function(){
                location.reload();
          });  
        });
        */
    }

})(angular);
