(function () {
    'use strict';

    angular.module('app')
        .component('datosUsuario', {
            templateUrl: 'app/public/components/datos/datos-usuario.html',
            bindings: {
                usuario: '<'
            },
            controller: datosUsuarioController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function datosUsuarioController($state, EnumService, Usuario, User, ModalService, AlertService) {
        var vm = this;

        EnumService.get("numAlumnos").then(function (data) {
            vm.categoriasCentro = data;
        });

        vm.actualizarCentro = function (centro) {
            ModalService.open({
                templateUrl: 'app/public/components/datos/confirmarEdicionModal.html',
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: function (ModalService) {
                    var vm = this;

                    vm.dismiss = function () {
                        ModalService.dismiss();
                    };

                    vm.accept = function () {
                        if (vm.email && vm.email.length > 0) {
                            ModalService.close();
                        }
                    };
                }
            }).result.then(function () {
                Usuario.editAdmin(centro).$promise.then(function () {
                    $state.reload();
                });
            });
        };

        vm.activar = function (usuario) {
            User.activar({email: usuario.email, activar: !usuario.activado}).$promise.then(function () {
                usuario.activado = !usuario.activado;
            });
        };


        vm.openModalActualizarEmail = function (usuario) {
            var isModalAceptarSolicitud = usuario.nuevoEmail !== null
                && angular.isDefined(usuario.nuevoEmail)
                && usuario.nuevoEmail.length > 0;

            var templateUrl = isModalAceptarSolicitud
                ? 'app/public/components/datos/modal-email/modal-aceptar-cambio-email.html'
                : 'app/public/components/datos/modal-email/modal-email.html';

            ModalService.open({
                templateUrl: templateUrl,
                controllerAs: 'ctrl',
                backdrop: 'static',
                /* @ngInject */
                controller: function (ModalService, email, nuevoEmail, $scope) {
                    var vm = this;

                    vm.email = email;
                    vm.nuevoEmail = nuevoEmail;
                    vm.accept = function () {
                        if (isModalAceptarSolicitud) {
                            ModalService.close(nuevoEmail);
                        } else if ($scope.form.$valid) {
                            ModalService.close(vm.email);
                        }
                    };

                    vm.reject = function () {
                        ModalService.dismiss('reject');
                    };

                    vm.dismiss = function () {
                        ModalService.dismiss('cancel');
                    };
                },
                resolve: {
                    email: function () {
                        return usuario.email;
                    },
                    nuevoEmail: function () {
                        return usuario.nuevoEmail;
                    }
                }
            }).result.then(function (email) {
                if (email) {
                    Usuario.cambiarEmail({id: usuario.id, email: email}).$promise.then(function () {
                        $state.reload();
                        AlertService.success("admin.form.modal-cambio-email.success");
                    });
                }
            }).catch(function (reason) {
                if (reason === 'reject') {
                    Usuario.rechazarCambioEmail({id: usuario.id}).$promise.then(function () {
                        $state.reload();
                    });
                }
            });
        };

    }

})();
