(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:contactoForm
     * @description
     * Formulario de contacto que envía un email al administrador de la plataforma (el email es configurable).
     *
     */
    angular
        .module('app')
        .component('contactoForm', {
            templateUrl: 'app/public/components/contactoform/contacto-form.html',
            controllerAs: 'ctrl',
            controller: Controller
        });

    /* @ngInject */
    function Controller(ContactoForm, AlertService, vcRecaptchaService) {
        var vm = this;
        vm.captcha = {
            resuelto: false,
            response: undefined
        };

        function init() {
            vm.item = new ContactoForm();
        }

        // Guardar formulario
        vm.save = function () {
            if (!vm.captcha.resuelto){
                AlertService.warning('contacto.form.mensajes.captchanocompletado', {}, 0);
                return;
            }

            vm.item.captchaToken = vm.captcha.response;

            vm.item.$save().then(function () {
                init();
                vm.form.$setPristine();
                vcRecaptchaService.reload();
                AlertService.clear();
                AlertService.success('contacto.form.mensajes.exito');
            }).catch(function () {
                AlertService.error('contacto.form.mensajes.error');
            });
        };

        vm.captchaSuccess = function(response) {
            vm.captcha.resuelto = true;
            vm.captcha.response = response.response;
        };

        init();
    }

})();
