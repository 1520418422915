(function () {
    'use strict';

    // Suscripción a medida para un centro
    angular
        .module('app')
        .component('suscripcionForm', {
            templateUrl: 'app/public/components/suscripciones/suscripcion.form.html',
            controllerAs: 'ctrl',
            controller: Controller,
            bindings: {
                item: '=',
                libros: '<',
                ivas: '<',
                numAlumnos: '<'
            }
        });

    /* @ngInject */
    function Controller($timeout, $state, $stateParams, Suscripcion, AlertService) {
        var vm = this;

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        var centroId = $stateParams.id;
        var numAlumnos = getNumAlumnos();

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function initForm() {
            if (!vm.item.pack.iva) {
                vm.item.pack.iva = vm.ivas[0];
            }
            if (!vm.item.pack.precioBase) {
                vm.item.pack.precioBase = 0;
                vm.precioConIva = 0;
                vm.precioCentroConIva = 0;
            } else {
                vm.precioConIva = vm.item.pack.precioBase + vm.item.pack.precioBase * (vm.item.pack.iva.valor / 100);
                vm.precioConIva = vm.precioConIva.toFixed(2) * 1;
                vm.precioCentroConIva = vm.precioConIva * numAlumnos;
            }

            if (vm.item.pack.libros) {
                vm.item.pack.libros.forEach(function (packLibro) {
                    var libro = vm.libros.find(function (libro) {
                        return packLibro.id === libro.libro.id;
                    });
                    if (libro) {
                        libro.checked = true;
                    }
                });
            }

            vm.updatePrecio();
        }

        // Precio
        vm.updatePrecio = function (iva) {
            if (iva) {
                vm.item.pack.precioBase = vm.precioConIva / (1 + (iva.valor / 100));
                vm.precioCentroConIva = vm.precioConIva * numAlumnos;
            }
        };

        // Número de libros seleccionados
        vm.countLibros = function () {
            return {
                num: vm.libros.filter(function (libro) {
                    return libro.checked;
                }).length
            }
        };

        function getNumAlumnos() {
            if (vm.numAlumnos !== undefined) {
                var split = vm.numAlumnos.split("_");
                return split[split.length - 1];
            }
            return undefined;
        }

        // Guardar formulario
        vm.save = function () {
            // Añadimos los libros al Pack
            vm.item.pack.libros = [];
            vm.libros.forEach(function (libro) {
                if (libro.checked) {
                    vm.item.pack.libros.push(libro.libro);
                }
            });

            if (vm.item.pack.libros.length === 0) {
                AlertService.warning("admin.pack.error.emptylibros");
                return;
            }

            Suscripcion.saveAMedida(vm.item).$promise.then(function () {
                $state.go('admin/usuario/suscripciones', {id: centroId});
            });
        };

        initForm();
    }

})();
