(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('empezarTarea', {
            templateUrl: 'app/public/cuenta/empezartarea/empezar-tarea.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tarea: '<'
            }
        });


    /* @ngInject */
    function Controller(LibroAdquirido, $state) {

        var vm = this;

        LibroAdquirido.getByLibroAndUsuarioActivo({libroId: vm.tarea.libro.id}).$promise.then(function (libroAdquirido) {
            if (libroAdquirido.id) {
                $state.go("visor/epub", {
                    id: libroAdquirido.id,
                    frommail: true,
                    tarea: vm.tarea
                }, {location: "replace"});
            } else {
                $state.go("miespacio/tarea/details", {
                    proyectoId: vm.tarea.proyecto.id,
                    id: vm.tarea.id
                }, {location: "replace"});
            }
        });
    }
})(angular);
