(function () {
    'use strict';

    angular.module('app')
        .factory('Centro', Centro);

    /* @ngInject */
    function Centro($resource) {
        var urlAdmin = 'api/admin/user/centros';
        return $resource(urlAdmin, {},
            {
                'findAll': {
                    method: 'GET',
                    url: urlAdmin + '/findAll'
                },
                'findCentrosList': {
                    method: 'GET',
                    url: urlAdmin + '/findCentroList',
                    isArray: true
                }
            });
    }

})();
