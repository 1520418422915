(function () {
    'use strict';
    angular.module('app')
        .component('estadisticasCentroDetails', {
            templateUrl: 'app/public/components/estadisticas/estadisticas.centro.html',
            bindings: {
                estadisticasLectura: '<',
                id: '<'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller(NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
                        Estadisticas, Configuracion, DescargaUtil) {
        var vm = this;

        vm.item = Estadisticas;
        vm.function = "getEstadisticasCentros";
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;

        Configuracion.getFechasCurso().$promise.then(function(data) {
            vm.filter = {
                fechaInicio: new Date(data[0][0], data[0][1] - 1, data[0][2]), // -1 bc 0-based months
                fechaFin: new Date(data[1][0], data[1][1] - 1, data[1][2]), // -1 bc 0-based months
                id: vm.id
            };

            vm.tableParams = new NgTableParams({
                count: vm.elementosPorPagina,
                page: 1,
                filter: vm.filter,
                sorting: {centro: 'asc'}
            }, NgTableHelper.settings(vm));

            vm.generarPdf = function() {
                var customFilter = {
                    fechaInicio: vm.filter.fechaInicio,
                    fechaFin: vm.filter.fechaFin,
                    id: vm.id,
                    page: 0,
                    sortProperty: 'centro',
                    sortDirection: 'ASC'
                };
                return DescargaUtil.descargar('api/public/estadisticas/descargarEstadisticas', customFilter, 'applicaton/pdf');
            };

            vm.generarPdfLibros = function($event) {
                $event.preventDefault();
                var customFilter = {
                    fechaInicio: vm.filter.fechaInicio,
                    fechaFin: vm.filter.fechaFin,
                    id: vm.id
                };
                return DescargaUtil.descargar('api/public/estadisticas/descargarEstadisticasLibros', customFilter, 'application/pdf');
            }
        });
    }
})();
