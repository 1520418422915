(function () {
    'use strict';

    /**
     * https://github.com/legalthings/pdf.js-viewer
     * Directiva inspirada en https://github.com/legalthings/angular-pdfjs
     *
     */

    /* @ngInject */
    function visorPdf($interval, Pdf, MIN_TIEMPO_PAGINA, OfflineService, $translate, $state) {
        return {
            templateUrl: 'app/public/components/visor/pdf/visor.pdf.html',
            restrict: 'E',
            scope: {
                libroAdquirido: '<',
                muestra: '<',
                admin: '<'
            },
            /* @ngInject */
            controller: function (RutasService, $scope) {
                $scope.loading = true;
                // Obtenemos la ruta del PDF (en función de si es muestra o no)
                if ($scope.libroAdquirido) {
                    console.log("libro: ", $scope.libroAdquirido.libro);
                    $scope.libro = $scope.libroAdquirido.libro;
                } else if ($scope.muestra) {
                    $scope.libro = $scope.muestra;
                }

                if ($scope.libroAdquirido || $scope.admin) {
                    $scope.ruta = RutasService.getPdf($scope.libro.id, $scope.libro.pdf.archivo, false);
                } else {
                    $scope.ruta = RutasService.getPdf($scope.libro.id, $scope.libro.pdf.muestra, true);
                }

                $scope.esMuestra = !$scope.libroAdquirido;
            },
            controllerAs: 'ctrl',
            link: function ($scope, $element, $attrs) {

                console.log("Abriendo pdf:" + $scope.ruta);

                $element.children().wrap('<div class="pdfjs" style="width: 100%; height: 100%;"></div>');

                var initialised = false;
                var loaded = {};
                var numLoaded = 0;

                var tiempo = 0;
                var tiempoAux = 0;
                var palabras = 0;
                var startDate = new Date();
                var pagina = 0;
                var paginaAnterior = 0;
                var numPalabrasPaginaAnteriorOff = [];
                var numPalabrasLibroIdOff = [];

                var libro = $scope.libro;
                var guardadoEnBd = true;

                // ************************* EVENTOS *************************

                function onPageChange(e) {
                    if (pagina != e.pageNumber) {
                        console.log("Cambiando de página --------------------------------------");
                        pagina = e.pageNumber;
                        avance(false);
                        startDate = new Date();
                        paginaAnterior = pagina;
                    }
                }

                // Scalechange coge los cambios de escala tanto del desplegable como de los botones zoomin y zoomout,
                // pero almacena valores decimales al seleccionar full-width o fit-page
                function onScaleChange(e) {
                    console.log("Ajustada escala a: " + e.scale);
                }

                // Change guarda bien los valores full-witdh o fit-page pero no los botones zoomin y zoomout
                function onChange(e) {
                    console.log("Ajustada escala a: " + e.target.value);
                }

                function addEvents() {
                    document.addEventListener('pagechange', onPageChange);
                    document.addEventListener('scalechange', onScaleChange);
                    document.addEventListener('change', onChange);
                }

                function removeEvents() {
                    document.removeEventListener('pagechange', onPageChange);
                    document.removeEventListener('scalechange', onScaleChange);
                    document.removeEventListener('change', onChange);
                }

                $element.on('$destroy', function () {
                    console.log("Destruyendo visor PDF");
                    PDFViewerApplication.pdfViewer.pdfDocument.destroy();
                    removeEvents();
                    $interval.cancel(poller);
                });

                // ************************* FIN EVENTOS *************************

                function salir() {
                    // FIXME: Cuando entro en el PDF desde Mis libros, la primera vez sale bien, pero si vuelvo a entrar, la segunda vez se recarga el visor por algún motivo. Se hacen dos cambios de state.
                    // Sólo falla en modo desarrollo.
                    if ($scope.esMuestra) {
                        if ($scope.admin) {
                            window.close();
                        } else {
                            $state.go('libro', {tituloAbrev: libro.tituloAbrev});
                        }
                    } else {
                        $state.go('miespacio/mislibros', null);
                    }
                    console.log("Salir :::::::::::::::::::::::::::::");
                }

                $scope.changeHandToolText = function () {
                    if (angular.element('#toggleHandTool')[0].title === "Disable hand tool") {
                        angular.element('#toggleHandTool')[0].textContent = $translate.instant("visor.pdf.habilitarmano");
                    } else {
                        angular.element('#toggleHandTool')[0].textContent = $translate.instant("visor.pdf.deshabilitarmano");
                    }
                    angular.element('#secondaryToolbarToggle').click();
                };

                $scope.close = function () {
                    console.log("Cerrando :::::::::::::::::::::::::::::");
                    if ($scope.esMuestra) {
                        salir();
                    } else {
                        avance(true);
                    }
                };

                function guardarAvance(exit) {
                    Pdf.guardarAvance({
                        libroAdquirido: $scope.libroAdquirido,
                        tiempo: tiempo,
                        numeroPalabras: palabras
                    }).$promise.then(function () {
                        OfflineService.actualizarAvancePdf($scope.libroAdquirido.libro.tituloAbrev, $scope.libroAdquirido.pagina, 0, [], [], true);
                        tiempo = 0;
                        numPalabrasPaginaAnteriorOff = [];
                        numPalabrasLibroIdOff = [];
                        palabras = 0;
                        if (exit) {
                            salir();
                        }
                    });
                }

                function avance(exit) {
                    if ($scope.esMuestra) return;

                    $scope.libroAdquirido.pagina = window.PDFView.page;
                    tiempoAux = new Date() - startDate;
                    if (tiempoAux >= MIN_TIEMPO_PAGINA) {
                        numPalabrasLibroIdOff.push(libro.pdf.id);
                        numPalabrasPaginaAnteriorOff.push(paginaAnterior);
                        tiempo += tiempoAux;
                    }

                    console.log("Tiempo: ", tiempoAux, " siendo el total: ", tiempo);
                    if (navigator.onLine) {
                        // Almacenamos el avance en el servidor
                        if (numPalabrasLibroIdOff.length == 0) {
                            guardarAvance(exit);
                        } else {
                            Pdf.numPalabrasTotal({
                                ids: numPalabrasLibroIdOff,
                                paginas: numPalabrasPaginaAnteriorOff
                            }).$promise.then(function (data) {
                                palabras = data.msg;
                                guardarAvance(exit);
                            });
                        }
                    } else {
                        // Almacenamos el avance en Offline
                        OfflineService.actualizarAvancePdf($scope.libroAdquirido.libro.tituloAbrev,
                            $scope.libroAdquirido.pagina, tiempo, numPalabrasLibroIdOff, numPalabrasPaginaAnteriorOff, false);
                        if (exit) salir();
                    }
                }

                function cleanInterface() {
                    // Eliminamos los campos innecesarios (abrir archivo, imprimir y descargar)
                    angular.element("#openFile").remove();
                    angular.element("#secondaryOpenFile").remove();
                    angular.element("#print").remove();
                    angular.element("#secondaryPrint").remove();
                    angular.element("#download").remove();
                    angular.element("#secondaryDownload").remove();
                    angular.element("#documentProperties").remove();
                    angular.element("#viewBookmark").hide();
                    angular.element("#secondaryViewBookmark").hide();
                    angular.element("#viewAttachments").hide();
                    angular.element("#secondaryViewAttachments").hide();
                    if (window.innerWidth <= 510) {
                        angular.element("#scaleSelectContainer").css('display', 'none');
                    }
                }

                function goToLastPage() {
                    if ($scope.esMuestra) return;

                    console.log("GoToLastPage");

                    if (guardadoEnBd) {
                        window.PDFView.page = $scope.libroAdquirido.pagina;
                    } else {
                        window.PDFView.page = paginaAnterior;
                    }

                    pagina = window.PDFView.page;
                    addEvents();
                }

                var poller = $interval(
                    function () {
                        var pdfViewer = PDFViewerApplication.pdfViewer;
                        if (pdfViewer) {
                            if ($scope.scale !== pdfViewer.currentScale) {
                                loaded = {};
                                numLoaded = 0;
                                $scope.scale = pdfViewer.currentScale;
                            }
                        } else {
                            console.warn("PDFViewerApplication.pdfViewer is not set");
                        }
                        var pages = document.querySelectorAll('.page');
                        angular.forEach(pages, function (page) {
                            var element = angular.element(page);
                            var pageNum = element.data('page-number');

                            if (!element.data('loaded')) {
                                delete loaded[pageNum];
                                return;
                            }

                            if (pageNum in loaded)
                                return;

                            if (!initialised)
                                onPdfInit();

                            loaded[pageNum] = true;
                            numLoaded++;
                        });
                    }, 200);

                function initPDFJSOffline() {
                    getComponentOffline();
                    // Igual era mejor esperar para ejecutar lo siguiente a que terminase la ejecución de getComponentOffline()...
                    OfflineService.getComponenteOff($scope.libro.tituloAbrev, $scope.libro.pdf.archivo, (function (contido) {
                        if (!contido) {
                            // FIXME: Si el PDF no se almacenó bien en indexedDB, iniciar mediante la ruta
                            console.log("El PDF no está bien almacenado");
                            return;
                        }

                        function base64ToUint8Array(base64) {
                            var raw = atob(base64);
                            var uint8Array = new Uint8Array(raw.length);
                            for (var i = 0; i < raw.length; i++) {
                                uint8Array[i] = raw.charCodeAt(i);
                            }
                            return uint8Array;
                        }

                        var res = contido.substring(28); // Base64 String from response shortened

                        var pdfData = base64ToUint8Array(res);
                        PDFViewerApplication.open(pdfData);
                    }).bind(this));
                }

                function initPDFJS(ruta) {
                    $scope.loading = false;
                    // Ruta del archivo JS
                    PDFJS.workerSrc = 'assets/js/pdf.worker.js';
                    PDFJS.webViewerLoad(ruta);
                    if (ruta == null) {
                        console.log("Ruta ocultada");
                        angular.element("#errorWrapper").hide();
                    }
                }

                function estadoOffline(ta, callback) {
                    var e = {};
                    var estadoOff = OfflineService.recuperarAvance(ta);
                    if (estadoOff) {
                        e.libroAdquirido = estadoOff.libroAdquirido;
                        e.tiempo = estadoOff.tiempoPdf;
                        e.numPalabrasLibroId = estadoOff.arrayPdfId;
                        e.numPalabrasPaginaAnterior = estadoOff.arrayPdfPaginaAnterior;
                        e.pagina = estadoOff.paginaPdf;
                        e.pdfCreado = estadoOff.pdf;
                        e.guardadoEnBd = estadoOff.guardadoEnBd;
                    }
                    callback(e);
                }

                function getComponentOffline() {
                    OfflineService.getComponenteOff(libro.tituloAbrev, libro.pdf.archivo, (function () {
                        initPDFJS();
                    }).bind(this));
                }

                function onPdfInit() {
                    if ($scope.esMuestra) {
                        document.title = $translate.instant("visor.muestra");
                    } else {
                        document.title = $translate.instant("global.menu.miespacio.title");
                    }

                    // El PDF ya está inicializado
                    initialised = true;

                    if ($attrs.removeMouseListeners === "true") {
                        window.removeEventListener('DOMMouseScroll', handleMouseWheel);
                        window.removeEventListener('mousewheel', handleMouseWheel);

                        var pages = document.querySelectorAll('.page');
                        angular.forEach(pages, function (page) {
                            angular.element(page).children().css('pointer-events', 'none');
                        });
                    }

                    document.getElementById('numPages').textContent = $translate.instant("visor.pdf.de") + " " + PDFViewerApplication.pagesCount;
                    cleanInterface();

                    if (navigator.onLine && !$scope.esMuestra) {
                        if (numPalabrasLibroIdOff.length == 0) {
                            guardarAvance(false);
                        } else {
                            Pdf.numPalabrasTotal({
                                ids: numPalabrasLibroIdOff,
                                paginas: numPalabrasPaginaAnteriorOff
                            }).$promise.then(function (data) {
                                palabras = data.msg;
                                guardarAvance(false);
                            });
                        }
                    }

                    goToLastPage();

                    for (var i = 1, len = PDFViewerApplication.pagesCount; i <= len; i++) {
                        if (angular.element('#thumbnailView').contents()[i]) {
                            angular.element('#thumbnailView').contents()[i].title = $translate.instant("visor.pdf.pagina") + " " + i;
                        }
                    }
                }

                function init() {
                    if (!$scope.esMuestra) {
                        estadoOffline(libro.tituloAbrev, function (data) {
                            if (!angular.equals(data, {})) {
                                console.log("Recuperando información de localstorage");
                                tiempo = data.tiempo;
                                numPalabrasPaginaAnteriorOff = data.numPalabrasPaginaAnterior;
                                numPalabrasLibroIdOff = data.numPalabrasLibroId;
                                paginaAnterior = data.pagina;
                                guardadoEnBd = data.guardadoEnBd;
                            }
                        });

                        if (navigator.onLine) {
                            console.log("Existe conexión a internet");
                            if (!OfflineService.existePdfOff(libro.tituloAbrev)) {
                                console.log("*******************************************");
                                console.log("No existe el libro offine");
                                // Se carga el libro mediante la URL mientras se descarga en segundo plano
                                OfflineService.descargarComponentesPdf($scope.libroAdquirido, function () {
                                });
                                initPDFJS($scope.ruta);
                            } else {
                                console.log("Existe el libro offine");
                                initPDFJSOffline();
                            }
                        } else {
                            console.log("No hay internet");
                            initPDFJSOffline();
                        }
                    } else {
                        console.log("Es un libro de muestra");
                        initPDFJS($scope.ruta);
                    }
                }

                // FIXME: Las peticiones HTTP son asíncronas, por lo que no se realizan. Probar este ejemplo: https://gist.github.com/981746/3b6050052ffafef0b4df/
                $scope.$on('onBeforeUnload', function (e, confirmation) {

                    // TODO: Refactorizar. Ya existe un método (avance) que realiza estas acciones cuando no hay conexión. Se le puede pasar un parámetro para forzar que almacene offline en este caso concreto.
                    confirmation.message = "All data will be lost.";
                    tiempoAux = new Date() - startDate;
                    if (tiempoAux >= MIN_TIEMPO_PAGINA) {
                        numPalabrasLibroIdOff.push(libro.pdf.id);
                        numPalabrasPaginaAnteriorOff.push(paginaAnterior);
                        tiempo += tiempoAux;
                    }
                    console.log($scope.libroAdquirido.libro.tituloAbrev, $scope.libroAdquirido.pagina, tiempo, numPalabrasLibroIdOff, numPalabrasPaginaAnteriorOff);
                    OfflineService.actualizarAvancePdf($scope.libroAdquirido.libro.tituloAbrev,
                        $scope.libroAdquirido.pagina, tiempo, numPalabrasLibroIdOff, numPalabrasPaginaAnteriorOff, false);
                    e.preventDefault();
                });

                $scope.$on('onUnload', function (e) {
                    console.log('leaving page'); // Use 'Preserve Log' option in Console
                });

                init();
            }
        };
    }

    angular.module('app').directive('visorPdf', visorPdf);
})();
