(function () {
    'use strict';

    angular.module('app')
        .factory('Contacto', Service);

    /* @ngInject */
    function Service($resource) {
        var entidad = "contacto";
        return $resource('api/' + entidad);
    }

})();
