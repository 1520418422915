(function () {
    'use strict';

    // ID: ID del responsable (o del miembro) de los proyectos (opcional)
    // Activos: booleano para saber si los proyectos siguen vigentes

    angular
        .module('app')
        .component('proyectos', {
            templateUrl: 'app/public/components/proyectos/proyecto/proyectos.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                id: '<',
                activos: '<',
                libro: '<'
            }
        });

    /* @ngInject */
    function Controller($stateParams, $rootScope, $state, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper, Proyecto, Tarea,
        Principal, ModalService, LibroAdquirido, AlertService, Libro) {
        var vm = this;
        vm.item = Proyecto;
        vm.datos = [];
        vm.datosTareas = [];
        vm.today = new Date();

        function init() {
            vm.loading = true;

            if ($rootScope.ordenListaProyectos) {
                vm.filter = {
                    key: undefined,
                    id: vm.id,
                    activos: vm.activos,
                    sortProperty: $rootScope.ordenListaProyectos.sortProperty,
                    sortDirection: $rootScope.ordenListaProyectos.sortDirection
                };
            } else {
                vm.filter = {
                    key: undefined,
                    id: vm.id,
                    activos: vm.activos,
                    sortProperty: 'fechaCreacion',
                    sortDirection: 'DESC'
                };
            }

            Principal.identity().then(function (data) {
                vm.responsableVisible = vm.id === undefined && (data.type === 'centro' || data.type === 'lector');
                vm.esProfesor = data && data.type === 'profesor';
                vm.profesorActivado = data && data.activado && data.centro != null;
                vm.tieneCentro = data && data.centro && data.centro.id != null;
                vm.centroIndependiente = data && !data.centro || (data.centro && data.centro.centroIndependiente);
            });

            vm.getProyectos(vm.filter);
        }

        vm.getProyectos = function (filtro) {
            vm.loading = true;

            Proyecto.findByUsuarioActivo(filtro).$promise
                .then(function successCallback(response) {
                    vm.loading = false;
                    vm.datos = response.content;
                }, function errorCallback(response) {
                    vm.loading = false;
                });
        };

        vm.getTareas = function (filtro) {
            Tarea.findByUsuarioActivo(filtro).$promise
                .then(function successCallBack(response) {
                    vm.datosTareas = response.content;
                    vm.loading = false;
                }, function errorCallback() {
                    vm.loading = false;
                });
        };

        vm.ordenarRecientes = function () {
            if (vm.filter.sortDirection === 'DESC') {
                vm.filter = {
                    activos: vm.activos,
                    sortProperty: 'fechaCreacion',
                    sortDirection: 'ASC'
                };

                $rootScope.ordenListaProyectos = {
                    sortProperty: 'fechaCreacion',
                    sortDirection: 'ASC'
                };
            } else if (vm.filter.sortDirection === 'ASC') {
                vm.filter = {
                    activos: vm.activos,
                    sortProperty: 'fechaCreacion',
                    sortDirection: 'DESC'
                };

                $rootScope.ordenListaProyectos = {
                    sortProperty: 'fechaCreacion',
                    sortDirection: 'DESC'
                };
            }

            vm.getProyectos(vm.filter);
        };

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                controller: 'EntityDeleteModalController',
                controllerAs: 'deleteCtrl',
                resolve: {
                    /* @ngInject */
                    item: function (Proyecto) {
                        return Proyecto.get({id: id}).$promise;
                    },
                    params: function () {
                        return {
                            title: 'miespacio.proyecto.tarea.delete.title',
                            body: 'miespacio.proyecto.tarea.delete.confirm',
                            property: 'nombre'
                        };
                    }
                }
            }).result.then(function () {
                vm.getProyectos(vm.filter);
            });
        };

        vm.getFechaFin = function (tarea) {
            return new Date(tarea.fechaInicio);
        };

        //Ver detalles tarea
        vm.showDetails = function (libro, proyecto, tarea) {
            libro = tarea.libro;
            if (!vm.esProfesor && proyecto.esCentroIndependiente) {
                vm.abrirTarea(libro, proyecto.id, tarea);
            } else {
                $state.go('miespacio/tarea/details', {proyectoId: proyecto.id, id: tarea.id});
            }

        };

        // Cerrar
        vm.showCerrarConfirmation = function (tarea) {
            showConfirmation(tarea, 'cerrar');
        };

        // Reabrir
        vm.showReabrirConfirmation = function (tarea) {
            showConfirmation(tarea, 'reabrir');
        };

        vm.showAlumnos = function (proyecto) {
            ModalService.open({
                templateUrl: 'app/public/components/proyectos/alumno/alumnos-proyecto.modal.html',
                controller: 'AlumnosProyectoModalController',
                controllerAs: 'ctrl',
                resolve: {
                    item: function () {
                        return proyecto;
                    },
                    params: function () {
                        return {id: proyecto.id};
                    }
                }
            }).result.then(function () {
            });
        };

        function showConfirmation(tarea, metodo) {
            ModalService.open({
                templateUrl: 'app/components/form/confirm/confirm.modal.html',
                controller: 'ConfirmModalController',
                controllerAs: 'ctrl',
                resolve: {
                    item: function () {
                        tarea.nombre = tarea.libro.titulo;
                        return tarea;
                    },
                    /* @ngInject */
                    tipo: function (Tarea) {
                        return Tarea;
                    },
                    funcion: function () {
                        return metodo;
                    },
                    parametros: function () {
                        return {id: tarea.id};
                    },
                    params: function () {
                        return {
                            title: 'miespacio.proyecto.tarea.' + metodo + '.title',
                            body: 'miespacio.proyecto.tarea.' + metodo + '.confirm',
                            property: 'nombre'
                        };
                    }
                }
            }).result.then(function () {
                vm.getProyectos(vm.filter);
            });
        }

        vm.abrirLibro = function (libro) {
            // Si se tiene, se accede a la lectura del libro. Si no, a la ficha pública.
            LibroAdquirido.getByLibroAndUsuarioActivo({libroId: libro.id}).$promise.then(function (libroAdquirido) {
                if (libroAdquirido.id) {
                    $state.go("visor/epub", {id: libroAdquirido.id});
                } else {
                    $state.go("libro", {tituloAbrev: libro.tituloAbrev});
                }
            });
        };

        vm.abrirTarea = function (libro, proyectoId, tarea) {
            // Si se tiene el libro, se accede a la tarea. Si no, a la ficha pública
            if (tarea.hasLibro) {
                $state.go('miespacio/tarea/details', {proyectoId: proyectoId, id: tarea.id});
            } else {
                AlertService.warning('miespacio.proyecto.tarea.error.sinLibro');
                $state.go("libro", {tituloAbrev: libro.tituloAbrev});
            }
        };

        vm.leerLibro = function (libro, tarea) {
            Libro.findByUrl({tituloAbrev: libro.tituloAbrev}).$promise.then(function (data) {
                if (data.libroAdquirido) {
                    $state.go('visor/epub', {id: data.libroAdquirido.id});
                } else {
                    $state.go('libro', {tituloAbrev: libro.tituloAbrev});
                }
            });
        };


        init();
    }

})();
