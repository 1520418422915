(function () {
    'use strict';

    angular.module('app')
        .factory('Ejecucion', Ejecucion);

    /* @ngInject */
    function Ejecucion(ResourceHelper) {
        return ResourceHelper.getResource("ejecucion", {}, {
            'comprobar': {
                method: 'GET',
                url: 'api/public/ejecucion/comprobar',
                isArray: false
            },
            'iniciar': {
                method: 'GET',
                url: 'api/public/ejecucion/iniciar',
                isArray: false
            },
            'responder': {
                method: 'POST',
                url: 'api/public/ejecucion/responder',
                isArray: false
            },
            'finalizar': {
                method: 'POST',
                url: 'api/public/ejecucion/finalizar',
                isArray: false
            },
            'evaluar': {
                method: 'POST',
                url: 'api/public/ejecucion/evaluar',
                isArray: false
            },
            'publicarNotas': {
                method: 'GET',
                url: 'api/public/ejecucion/publicarNotas',
                isArray: false
            }
        }, true);
    }

})();
