(function () {
    'use strict';

    angular.module('app')
        .factory('AlumnoCentro', AlumnoCentro);

    /* @ngInject */
    function AlumnoCentro($http) {
        var entidad = 'alumno-centro';

        return {
            'getCentros': getCentros,
            'tieneCentros': tieneCentros,
            'getNumAlumnos': getNumAlumnos
        };

        function getCentros() {
            return $http({
                method: 'GET',
                url: 'api/public/' + entidad + '/getCentros',
                isArray: true
            });
        }

        function tieneCentros() {
            return $http({
                method: 'GET',
                url: 'api/public/' + entidad + '/tieneCentros',
                isArray: false
            });
        }

        function getNumAlumnos(numAlumnos) {
            return $http({
                method: 'GET',
                url: 'api/public/' + entidad + '/getNumAlumnos',
                params: {numAlumnos: numAlumnos},
                isArray: false
            });
        }
    }

})();
