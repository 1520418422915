(function () {
    'use strict';

    //

    var notificacionesPopup = {
        templateUrl: 'app/components/notificaciones/notificaciones.popover.html',
        /* @ngInject */
        controller: function ($interval, Notificacion, Principal, NOTIFICACIONES_TIME, NotificacionHelper) {
            var vm = this;

            function getUserParams() {
                return Principal.identity().then(function (data) {
                    if (!data) return null;
                    return {rol: data.rol, id: data.id};
                });
            }

            // Contador

            vm.count = 0;
            function updateCount() {
                getUserParams().then(function (user) {
                    if (user) {
                        Notificacion.contar(user.rol, user.id).then(function (data) {
                            console.log("Contando notificaciones");
                            vm.count = data;
                        });
                    }
                });
            }

            updateCount();
            NotificacionHelper.initCount(updateCount);
        },
        controllerAs: 'ctrl'
    };

    angular
        .module('app')
        .component('notificacionesPopover', notificacionesPopup);

})();
