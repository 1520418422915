(function () {
    'use strict';

    angular
        .module('app')
        .component('buscadorAvanzado', {
            templateUrl: 'app/public/components/buscador/buscador.avanzado.html',
            controller: BuscadorController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function BuscadorController(Libro, Autor, Coleccion, Idioma, GrupoEdad, EnumService, ValorSocial) {
        var vm = this;

        vm.search = function () {
            var request = {
                titulo: vm.titulo,
                genero: vm.genero,
                idiomaCodigo: vm.idioma ? vm.idioma.codigo : null,
                autorUrl: vm.autor ? vm.autor.url : null,
                coleccionUrl: vm.coleccion ? vm.coleccion.url : null,
                grupoEdadUrl: vm.grupoEdad ? vm.grupoEdad.url : null,
                valorSocialUrl: vm.valorSocial ? vm.valorSocial.url : null,

                sortProperty: 'titulo',
                sortDirection: 'ASC',
                genericFilter: vm.query
            };

            Libro.filter(request).$promise.then(function (data) {
                vm.libros = data.content;
            });
        };

        // Selects

        Autor.findAll().$promise.then(function (data) {
            vm.autores = data.content;
        });

        Idioma.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
            vm.idiomas = data.content;
        });

        GrupoEdad.findAll({sortProperty: 'orden'}).$promise.then(function (data) {
            vm.gruposedad = data.content;
        });


        Coleccion.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
            vm.colecciones = data.content;
        });

        EnumService.get("genero").then(function (data) {
            vm.generos = data;
        });

        ValorSocial.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
            vm.valoresSociales = data.content;
        });

    }

})();
