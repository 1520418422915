(function () {
    'use strict';

    // proyecto: Proyecto del que se va a mostrar la información

    angular
        .module('app')
        .component('proyecto', {
            templateUrl: 'app/public/components/proyectos/proyecto/proyecto.details.html',
            controllerAs: 'ctrl',
            bindings: {
                proyecto: '=',
                grupoNombre: '<'
            }
        });

})();
