(function () {
    'use strict';

    angular.module('app')
        .factory('Codigo', Codigo);

    /* @ngInject */
    function Codigo(ResourceHelper) {
        var nombre = "codigo";

        return ResourceHelper.getResource(nombre, {}, {
            'consumir': {
                method: 'GET',
                url: 'api/public/' + nombre + '/consumir',
                isArray: false
            }
        });
    }

})();
