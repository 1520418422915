(function () {
    'use strict';
    angular.module('app')
        .component('compras', {
            templateUrl: 'app/public/components/compras/compras.html',
            bindings: {
                id: '<'
            },
            controller: MisComprasController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function MisComprasController($stateParams, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper,
                                  Compra) {
        var vm = this;

        vm.item = Compra;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS_PUBLIC.size;

        if (vm.id == null) {
            vm.filter = {
                key: undefined
            };
            vm.function = "findAllByUsuarioActivo";
        } else {
            vm.filter = {
                key: undefined,
                id: vm.id
            };
            vm.function = "findAllByUsuario"
        }
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {fecha: 'desc'}
        }, NgTableHelper.settings(vm));

    }
})();
