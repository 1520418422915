(function () {
    'use strict';

    angular
        .module('app')
        .component('changeEmail', {
            templateUrl: 'app/public/components/email/email.component.html',
            controller: EmailController,
            controllerAs: 'ctrl'
        });


    function EmailController($rootScope, $timeout, User, AlertService) {

        var vm = this;

        vm.form = {
            email: null
        };

        vm.confirmEmail = null;

        $timeout(function () {
            angular.element('[ng-model="registerAccount.login"]').focus();
        });

        vm.save = function () {
            if (vm.form.email !== vm.confirmEmail) {
                AlertService.error("admin.register.error.emaildontmatch");
                return;
            }
            User.cambioEmail(vm.form).$promise.then(function () {
                AlertService.success("miespacio.miperfil.cambiaremail.success");
                $rootScope.back();
            }).catch(function (response) {
                if (response.status === 200) {
                    AlertService.success("miespacio.miperfil.cambiaremail.success");
                    $rootScope.back();
                } else if (response.status === 409) {
                    AlertService.error("miespacio.miperfil.cambiaremail.error.exist");
                } else if (response.status === 400) {
                    AlertService.error("miespacio.miperfil.cambiaremail.error.invalid");
                } else {
                    AlertService.error("miespacio.miperfil.cambiaremail.error.fail");
                }
            });
        };
    }
})();
