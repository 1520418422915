(function () {
    'use strict';

    //

    var staticPage = {
        /* @ngInject */
        templateUrl: function ($element, $attrs, Language) {
            var lang = Language.getCurrent();
            var carpeta = "";
            if ($attrs.carpeta) {
                carpeta = $attrs.carpeta + "/";
            }
            return 'statics/' + carpeta + $attrs.id + '/' + $attrs.id + '.' + lang + '.html';
        },
        bindings: {
            id: '@', // required
            carpeta: '@'
        }
    };

    angular
        .module('app')
        .component('static', staticPage);

})();
