(function () {
    'use strict';
    angular.module('app')
        .component('comprasDetails', {
            templateUrl: 'app/public/components/compras/compras.details.html',
            bindings: {
                factura: '<',
                id: '<'
            },
            controller: MisComprasDetailsController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function MisComprasDetailsController(Factura) {
        var vm = this;

        vm.esLibreria = vm.factura.compra.usuario.type == 'libreria';

        vm.generarFactura = function (id) {
            return Factura.descargar(id);
        };

        vm.generarFacturaCliente = function (id) {
            return Factura.descargarCliente(id);
        };
    }
})();
