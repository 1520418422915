(function () {
    'use strict';

    angular.module('app')
        .factory('Pack', Pack);

    /* @ngInject */
    function Pack(ResourceHelper) {
        return ResourceHelper.getResource("pack", {}, {
            'filter': {
                method: 'GET',
                url: 'api/public/pack/filter',
                isArray: false
            },
            'filterForCentro': {
                method: 'GET',
                url: 'api/public/pack/filterForCentro',
                isArray: false
            }
        });
    }

})();
