(function () {
    'use strict';

    angular.module('app')
        .factory('LibroModalHelper', LibroModalHelper);

    /* @ngInject*/
    function LibroModalHelper(Proyecto, ModalService) {
        var vm = this;

        /* @ngInject */
        vm.getController = function (Principal, $state, ModalService) {
            var vm = this;
            vm.isAuthenticated = false;
            function init() {
                Principal.identity().then(function (data) {
                    vm.isAuthenticated = !!data;
                });
            }

            vm.close = function() {
                ModalService.close();
            };

            vm.continue = function () {
                ModalService.close();
                if (!vm.isAuthenticated) {
                    $state.go('registro/info', {'#': 'tiposUsuario'});
                }
            };

            init();
        };

        return {
            addTarea: addTarea,
            addClub: addClub
        };

        function addTarea() {
            ModalService.open({
                templateUrl: 'app/public/components/libro/modal/libro-crear-tarea.modal.html',
                size: 'md',
                /* @ngInject */
                controller: vm.getController,
                controllerAs: 'ctrl'
            });
        }

        function addClub() {
            ModalService.open({
                templateUrl: 'app/public/components/libro/modal/libro-crear-club.modal.html',
                size: 'md',
                /* @ngInject */
                controller: vm.getController,
                controllerAs: 'ctrl'
            });
        }

    }
})();
