(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/misestadisticas', {
                parent: 'miespacio',
                url: '/mis-estadisticas',
                data: {
                    authorities: ['ROLE_LECTOR', 'ROLE_PROFESOR'],
                    pageTitle: 'global.menu.miespacio.title',
                    titulo: 'global.menu.miespacio.estadisticas',
                    tituloImg: 'assets/images/iconos/estadisticas.png'
                },
                views: {
                    'contentEspacio': {
                        template: '<estadisticas-usuario-details estadisticas="$resolve.estadisticas" ' +
                        'estadisticas-lectura="$resolve.estadisticasLectura"></estadisticas-usuario-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    estadisticas: function (Estadisticas) {
                        return Estadisticas.getEstadisticasUsuario().$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    estadisticasLectura: function (Estadisticas) {
                        return Estadisticas.getEstadisticasLectura().$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            })
            .state('miespacio/misestadisticasCentro', {
                parent: 'miespacio',
                url: '/centro-estadisticas',
                data: {
                    authorities: ['ROLE_CENTRO'],
                    pageTitle: 'global.menu.miespacio.title',
                    titulo: 'global.menu.miespacio.estadisticas',
                    tituloImg: 'assets/images/iconos/estadisticas.png'
                },
                views: {
                    'contentEspacio': {
                        template: '<estadisticas-centro-details estadisticas-lectura="$resolve.estadisticasLecturaCentro"' +
                            '></estadisticas-centro-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    estadisticasLecturaCentro: function (Estadisticas) {
                        return Estadisticas.getEstadisticasLecturaCentro().$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            });
    }
})();
