(function () {
    'use strict';

    angular.module('app')
        .controller('TareaAlumnosFormModalController', TareaAlumnosFormModalController);

    /* @ngInject */
    function TareaAlumnosFormModalController(alumnosProyecto, alumnosTarea, tarea, ModalService, Tarea) {
        var vm = this;

        vm.dismiss = ModalService.dismiss;
        vm.alumnosProyecto = alumnosProyecto;
        vm.all = false;
        vm.tarea = tarea;

        function initForm() {
            alumnosTarea.forEach(function (alumno) {
                vm.alumnosProyecto.find(function (alumnoProyecto) {
                    return alumno.id == alumnoProyecto.alumno.id;
                }).checked = true;
            });
        }

        vm.selectAll = function (check) {
            vm.alumnosProyecto.forEach(function (alumnoProyecto) {
                alumnoProyecto.checked = check;
            });
            vm.tarea.proyecto.grupos.forEach(function (grupo) {
                grupo.checked = check;
            });
        };

        vm.selectGrupo = function (grupo) {
            vm.alumnosProyecto.forEach(function (alumnoProyecto) {
                if (alumnoProyecto.grupo.id == grupo.id) {
                    alumnoProyecto.checked = grupo.checked;
                }
            });
        };

        // Guardar formulario
        vm.save = function () {
            var alumnos = [];
            vm.alumnosProyecto.forEach(function (alumnoProyecto) {
                if (alumnoProyecto.checked) {
                    alumnos.push(alumnoProyecto.alumno);
                }
            });
            Tarea.asignar({id: tarea.id, alumnos: alumnos}).$promise.then(function () {
                ModalService.close(alumnos);
            });
        };

        initForm();

    }
})();
