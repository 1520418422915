var Book;

(function () {
    'use strict';

    /**
     * https://github.com/futurepress/epub.js/
     * http://futurepress.org/
     */

    // libroAdquirido: Entidad LibroAdquirido en caso de que el usuario haya comprado el libro.
    // muestra: Libro de muestra.
    // prerenciasUsuario: Preferencias del usuario.
    // notas: Lista de notas del usuario.
    // admin: (boolean) True si se está viendo desde la administración.

    angular
        .module('app')
        .component('visorEpub', {
            templateUrl: 'app/public/components/visor/epub/visor.epub.html',
            bindings: {
                libroAdquirido: '<',
                muestra: '<',
                preferenciasUsuario: '<',
                notas: '<',
                admin: '<'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($state, $scope, $translate, $window, TAMANO_FUENTES, COLORES_FONDO, TIPOS_FUENTE, INTERLINEADOS, MIN_TIEMPO_PAGINA,
        MAX_VELOCIDAD_LECTORA, MIN_VELOCIDAD_LECTORA, Epub, RutasService, OfflineService, VisorEpubUtil, Preferencias, AlertService, NotaEpub, Libro) {
        var vm = this;

        var fromTareaList = vm.fromTareas;

        var esMuestra = !vm.libroAdquirido;

        vm.libro = esMuestra ? vm.muestra : vm.libroAdquirido.libro;

        vm.rutaPortadaFull = RutasService.getPortada(vm.libro.id, vm.libro.portada, true);

        // Obtenemos la ruta del ePub.
        vm.ruta = RutasService.getEpub(vm.libro.id, esMuestra && !vm.admin ? vm.libro.epub.muestra : vm.libro.epub.archivo, esMuestra && !vm.admin);

        if (!vm.preferenciasUsuario) {
            // Si se modifican las preferencias por defecto, modificar también en PreferenciasController.java > obtenerPreferenciasByUsuarioActivo()
            vm.preferenciasUsuario = {
                fuente: "18px",
                fondo: "white",
                color: "black",
                tipo: "Trebuchet MS",
                interlineado: "1.25em",
                singlepage: false
            };
        }
        vm.preferenciasActualizadas = angular.copy(vm.preferenciasUsuario);

        vm.fuentes = TAMANO_FUENTES;
        vm.fondos = COLORES_FONDO;
        vm.tipos = TIPOS_FUENTE;
        vm.interlineados = INTERLINEADOS;
        vm.sidebar = false;

        // Barra de progreso
        vm.locationsReady = false;
        vm.slider = {
            options: {
                ceil: 100,
                showSelectionBar: true,
                hideLimitLabels: true,
                onEnd: function () {
                    // Actualizamos la página activa
                    var cfi = Book.book.locations.cfiFromPercentage(vm.libroAdquirido.porcentaje);
                    Book.book.gotoCfi(cfi);
                }
            }
        };

        var guardadoEnBdEpub = true;
        var paginaAnterior = 0;
        var capituloAnterior = 0;
        var startDate = new Date();
        var tiempo = 0;
        var tiempoAux = 0;
        var velocidad = 0;
        var palabras = 0;
        var palabrasPaginaAnterior = 0;
        var NUM_LOCATIONS = 250;

        function initEpubjs(data) {
            vm.iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
            Book = ePubReader(data, {
                history: false,
                generatePagination: false,
                sidebarReflow: false,
                annotations: vm.notas
            });
            initEvents();
        }

        function initEpubjsOffline(res) {
            console.log("Iniciando ePub Offline");
            var byteArray = VisorEpubUtil.get().decodeArrayBuffer(res.substring(33));
            initEpubjs(byteArray);
        }

        function getEstadoOffline(tituloAbrev, callback) {
            callback(OfflineService.recuperarAvance(tituloAbrev));
        }

        function initEpub() {
            if (!esMuestra) {
                if (navigator.onLine) {
                    // Para hacer pruebas con libros descomprimidos en el servidor:
                    // vm.ruta = "upload/libros/5/epub/";
                    console.log("Abriendo epub en ruta: ", vm.ruta);
                    initEpubjs(vm.ruta);
                }

                getEstadoOffline(vm.libro.tituloAbrev, function (data) {
                    console.log("Obtenido estado offline");
                    if (data) {
                        tiempo = data.tiempoEpub;
                        palabras = data.palabrasEpub;
                        paginaAnterior = data.cfiEpub;
                        guardadoEnBdEpub = typeof data.guardadoEnBdEpub !== 'undefined' ? data.guardadoEnBdEpub : true;
                        console.log("console.log(guardadoEnBdEpub):", guardadoEnBdEpub);
                    } else {
                        console.log("No hay info en localstorage");
                    }
                });

                if (navigator.onLine) {
                    console.log("Existe conexión a internet");
                    if (!OfflineService.existeEpubOff(vm.libro.tituloAbrev)) {
                        console.log("No existe el libro offine");
                        OfflineService.descargarComponentesEpub(vm.libroAdquirido);
                    } else {
                        console.log("Existe el libro offine");
                    }
                } else {
                    console.log("No hay internet");
                    OfflineService.getComponenteOff(vm.libro.tituloAbrev, vm.libro.epub.archivo, initEpubjsOffline);
                }
            } else {
                console.log("Libro de muestra");
                Libro.updateCountMuestra({idLibro: vm.libro.id}).promise;
                initEpubjs(vm.ruta);
            }
        }

        // ******************************** EVENTOS ********************************

        var widthOld = window.innerWidth;
        var heightOld = window.innerHeight;
        var firstTime = true;

        function countPalabrasVisibles() {
            var cfirange = Book.book.renderer.getVisibleRangeCfi();
            var text = '';
            var cfi = new EPUBJS.EpubCFI();
            var startRange = cfi.generateRangeFromCfi(cfirange.start, Book.book.renderer.render.document);
            var endRange = cfi.generateRangeFromCfi(cfirange.end, Book.book.renderer.render.document);
            // Create a new range to handle full cfi range (this should be fixed in v0.3)
            var fullRange = document.createRange();

            if (startRange) {
                fullRange.setStart(startRange.startContainer, startRange.startOffset);
            }

            if (endRange) {
                fullRange.setEnd(endRange.startContainer, endRange.startOffset);
            }

            text = fullRange.toString();
            var palabrasVisualizadas = text.split(" ");
            console.log("Número palabras contabilizadas en pantalla: " + palabrasVisualizadas.length)

            return palabrasVisualizadas.length;
        }

        function initEvents() {

            Book.book.on('book:ready', function () {
                angular.element(".overlay").unbind("click");
                setPreferencias(vm.preferenciasUsuario, true);

                initClickEvents();

                // Generamos las localizaciones
                Book.book.locations.generate(NUM_LOCATIONS).then(function (data) {
                    console.log("Locations generadas: ", data.length);
                    vm.locationsReady = true;
                    $scope.$apply();
                });

                if (!esMuestra) {
                    if (navigator.onLine && !guardadoEnBdEpub) {
                        console.log("Guardando PRIMER avance");
                        avance(false);
                    }

                    var paginaOff = paginaAnterior ? paginaAnterior : 0;
                    if (navigator.onLine) {
                        if (guardadoEnBdEpub) {
                            //Cargar cfi especifico
                            Epub.obtenerCfi({
                                id: vm.libroAdquirido.id
                            }).$promise.then(function (data) {
                                var paginaOn = data.msg ? data.msg : 0;
                                console.log("Capítulo de BD:", paginaOn);
                                Book.book.displayChapter(paginaOn);
                            });
                        } else {
                            console.log("Capítulo Off (con conexión):", paginaOff);
                            Book.book.displayChapter(paginaOff);
                        }
                    } else {
                        console.log("Capítulo Off (sin conexión):", paginaOff);
                        Book.book.displayChapter(paginaOff);
                    }
                } else {
                    Book.book.displayChapter(0);
                    angular.element("#show-Notes").remove();
                }
            });

            // El contenido que se está mostrando cambia
            Book.book.on('renderer:locationChanged', function (pagina) {
                console.log("renderer:locationChanged", pagina);
                if (vm.locationsReady) {
                    vm.libroAdquirido.porcentaje = Math.round(Book.book.locations.percentageFromCfi(pagina) * 100);
                    if (esMuestra) {
                        $scope.$apply();
                    }
                }
                if (!esMuestra) {
                    if (widthOld == window.innerWidth && heightOld == window.innerHeight && firstTime) {
                        console.log("Primera iteración. Contando palabras");
                        palabrasPaginaAnterior = countPalabrasVisibles();
                        firstTime = false;
                        return;
                    }
                    // Obtener cfi y porcentaje de epub actual
                    vm.libroAdquirido.cfi = pagina;


                    // Siempre que se cambie de pagina...
                    if (((paginaAnterior != Book.book.renderer.chapterPos) || (capituloAnterior != Book.book.renderer.currentChapter.spinePos))) {
                        avance(false);
                    }

                    palabrasPaginaAnterior = countPalabrasVisibles();
                    paginaAnterior = Book.book.renderer.chapterPos;
                    capituloAnterior = Book.book.renderer.currentChapter.spinePos;
                }
            });

            if (!esMuestra) {
                Book.book.on('notes:new', function (nota) {
                    nota.libroAdquirido = {id: vm.libroAdquirido.id};
                    NotaEpub.save(nota).$promise.then(function (data) {
                        console.log("Nota creada:", data);
                        vm.notas[vm.notas.indexOf(nota)].id = data.id;
                        console.log("Notas: ", vm.notas);
                    });
                });

                Book.book.on('notes:remove', function (nota) {
                    NotaEpub.eliminar({clientId: nota.clientId}).$promise.then(function () {
                        vm.notas.splice(vm.notas.indexOf(nota), 1);
                    });
                });

                Book.book.on("renderer:selected", function (range) {
                    var selectedText = range.toString();
                    if (selectedText) {
                        vm.compartirTexto = selectedText;
                        // var epubcfi = new EPUBJS.EpubCFI();
                        // var cfi = epubcfi.generateCfiFromRangeAnchor(range, Book.book.renderer.currentChapter.cfiBase);
                        vm.compartir = true;
                    } else {
                        vm.compartirTexto = "";
                        vm.compartir = false;
                    }
                    $scope.$apply();
                });
            }
        }

        // ******************************** PREFERENCIAS ********************************

        vm.cerrarPreferencias = function () {
            angular.element('#settings-modal').removeClass("md-show");
        };

        function setColorTitlebar(color) {
            angular.element('#titlebar').css('color', color);
            angular.element('#titlebar a:not(.dropdown-menu a)').css('color', color);
            angular.element('setting').css('color', color);
            angular.element('fullscreen').css('color', color);
            angular.element('close').css('color', color);
        }

        function setPreferencias(preferencias, forzar) {
            // Solo actualizamos las propiedades que han sido modificadas
            if (vm.preferenciasActualizadas.fondo != preferencias.fondo || forzar) {
                angular.element('#main').css('background-color', preferencias.fondo);
                // Con el fondo cambia el color de la barra superior
                setColorTitlebar(preferencias.color);
                Book.book.setStyle("color", preferencias.color);
            }
            if (vm.preferenciasActualizadas.fuente != preferencias.fuente || forzar) {
                Book.book.setStyle("font-size", preferencias.fuente);
            }
            if (vm.preferenciasActualizadas.tipo != preferencias.tipo || forzar) {
                Book.book.setStyle("font-family", preferencias.tipo);
            }
            if (vm.preferenciasActualizadas.interlineado != preferencias.interlineado || forzar) {
                Book.book.setStyle("line-height", preferencias.interlineado);
            }
            if (vm.preferenciasActualizadas.singlepage != preferencias.singlepage || forzar) {
                Book.book.forceSingle(preferencias.singlepage);
            }
        }

        function setFondoAux(color) {
            Book.book.setStyle("color", color);
            vm.preferenciasActualizadas.color = color;
            setColorTitlebar(color);
        }

        vm.setFondo = function (fondo) {
            angular.element('#main').css('background-color', vm.preferenciasActualizadas.fondo);
            vm.preferenciasActualizadas.fondo = fondo;
            if (fondo === "white") setFondoAux("black");
            if (fondo === "#fbf0d9") setFondoAux("#4c3c28");
            if (fondo === "black") setFondoAux("white");
        };

        vm.setFuente = function (fuente) {
            Book.book.setStyle("font-size", fuente);
            vm.preferenciasActualizadas.fuente = fuente;
        };

        vm.setTipo = function (tipo) {
            Book.book.setStyle("font-family", tipo);
            vm.preferenciasActualizadas.tipo = tipo;
        };

        vm.setInterlineado = function (interlineado) {
            Book.book.setStyle("line-height", interlineado);
            vm.preferenciasActualizadas.interlineado = interlineado;
        };

        vm.setDisenoPagina = function (singlepage) {
            Book.book.forceSingle(singlepage);
            vm.preferenciasActualizadas.singlepage = singlepage;
        };

        vm.guardarPreferencias = function () {
            vm.cerrarPreferencias();
            vm.preferenciasUsuario = angular.extend(vm.preferenciasUsuario, vm.preferenciasActualizadas);
            if (!esMuestra) {
                if (navigator.onLine) {
                    Preferencias.guardarPreferencias(vm.preferenciasActualizadas).$promise.then(function () {
                        console.log("Guardadas preferencias del usuario");
                    });
                }
                OfflineService.gardarPreferenciasOff(vm.preferenciasActualizadas);
            }
        };

        vm.cancelarPreferencias = function () {
            setPreferencias(vm.preferenciasUsuario, false);
            vm.preferenciasActualizadas = angular.extend(vm.preferenciasActualizadas, vm.preferenciasUsuario);
        };

        // ******************************** OTROS ********************************

        function initClickEvents() {
            // Ocultar índice al pulsar en un enlace del mismo
            $("#sidebar").find(".toc_link").on("click", function () {
                $("#slider").click();
            });
            // Ocultar al clickar en Ver nota.
            $("#notes").find("a.ver").on("click", function () {
                $("#slider").click();
            });
            // Ocultar al clickar en Añadir nota y mostrar el mensaje de ayuda
            $("#note-anchor").on("click", function () {
                $("#slider").click();
                AlertService.info($translate.instant("visor.epub.notas.help"), {
                    toast: true,
                    position: 'bottom right'
                });
            });
        }

        // TODO: La barra de progreso y el título estaría bien que desapareciesen como se hace en XCloud.
        // Reaparecerían al pasar el ratón por encima o al hacer click en la pantalla.
        // Este método es para eso
        // Book.book.on("renderer:click", function () {
        //     // ocultarBotones();
        // });
        function ocultarBotones() {
            console.log("Ocultando botones");
            vm.ocultar = !vm.ocultar;
            if (vm.ocultar) {
                angular.element("#titlebar").fadeOut("slow");
                angular.element("#progressbar").fadeOut("slow");
            } else {
                angular.element("#titlebar").fadeIn("slow");
                angular.element("#progressbar").fadeIn("slow");
            }
        }

        var resizeId;
        angular.element($window).resize(function () {
            // Desactivamos transiciones
            transiciones(false);

            clearTimeout(resizeId);
            // Volvemos a activar las transiciones
            resizeId = setTimeout(function () {
                transiciones(true);
            }, 1000);
        });

        function transiciones(activar) {
            console.log("Efectos:", activar);
            var renderer = Book.book.renderer;
            if (activar) {
                addTransiciones(renderer);
            } else {
                var transiciones = renderer.render.document.getElementById("transiciones");
                if (transiciones) {
                    transiciones.remove();
                }
            }
        }

        // ******************************** CONTROL ********************************

        vm.close = function () {
            Book.book.destroy();
            if (esMuestra) {
                salir();
            } else {
                avance(true);
            }
        };

        vm.adjustSidebar = function () {
            vm.sidebar = !vm.sidebar;
            if (vm.sidebar) {
                angular.element("#sidebar").fadeIn("fast");
            } else {
                angular.element("#sidebar").fadeOut("fast");
            }
        };

        function salir() {
            console.log($state.params);
            if($state.params.fromTareas) {
                history.back();
            }else if ($state.params.frommail) {
                $state.go("miespacio/tarea/details", {
                    proyectoId: $state.params.tarea.proyecto.id,
                    id: $state.params.tarea.id
                });
            } else if (!esMuestra) {
                history.back();
            } else {
                if (!vm.admin) {
                    // Página del libro
                    $state.go('libro', {tituloAbrev: vm.libro.tituloAbrev});
                } else {
                    // Página de administración
                    // $state.go('admin/libro/edit', {id: vm.libro.id});
                    $window.close();
                }
            }

        }

        function guardarAvance(exit) {
            Epub.guardarAvance({
                libroAdquirido: vm.libroAdquirido,
                tiempo: tiempo,
                numeroPalabras: palabras
            }).$promise.then(function (avance) {
                    console.log("Guardado avance del epub correctamente");
                    vm.libroAdquirido.notificadoValoracion = avance.notificadoValoracion;
                    OfflineService.actualizarAvanceEpub(vm.libroAdquirido.libro.tituloAbrev, vm.libroAdquirido.cfi, 0, 0, true);
                    startDate = new Date();
                    tiempo = 0;
                    palabras = 0;
                    if (exit) salir();
                },
                function (err) {
                    if (err.status === 401) {
                        $state.go('registro/info');
                    }
                })
        }

        function avance(exit) {
            if (esMuestra) return;

            tiempoAux = new Date() - startDate;
            velocidad = (palabrasPaginaAnterior / tiempoAux) * 60 * 1000; // palabras/min

            if (velocidad > MAX_VELOCIDAD_LECTORA) {
                // la velocidad es demasiado alta, por lo que se ignora
                tiempo += 0;
                palabras += 0;
            } else if (velocidad < MIN_VELOCIDAD_LECTORA) {
                // se está leyendo demasiado lento, por lo que el usuario seguramente haya dejado el epub abierto
                palabras += palabrasPaginaAnterior;
                var normalizacionTiempo = (palabrasPaginaAnterior * 60 * 1000) / MIN_VELOCIDAD_LECTORA;
                tiempo += normalizacionTiempo;
            } else {
                tiempo += tiempoAux;
                palabras += palabrasPaginaAnterior;
            }
            console.log("-----------------------");
            console.log("Capitulo anterior: ", capituloAnterior);
            console.log("Palabras en página leída: ", palabrasPaginaAnterior);
            console.log("Tiempo página leída: ", tiempoAux / 1000, "s");
            console.log("Velocidad página: ", velocidad, "palabras/min");
            console.log("Palabras a contabilizar: ", palabras);
            console.log("Tiempo a contabilizar: ", tiempo / 1000, "s");
            console.log("-----------------------");

            if (navigator.onLine) {
                guardarAvance(exit);
            } else {
                OfflineService.actualizarAvanceEpub(vm.libroAdquirido.libro.tituloAbrev,
                    vm.libroAdquirido.cfi, tiempo, palabras, false);
                console.log("Guardado avance en offline:", vm.libroAdquirido);
                if (exit) salir();
            }
        }

        $scope.$on('onBeforeUnload', function (e, confirmation) {
            confirmation.message = "All data will be lost.";
            tiempoAux = new Date() - startDate;
            if (tiempoAux >= MIN_TIEMPO_PAGINA) {
                tiempo += tiempoAux;
                palabras = palabrasPaginaAnterior;
            }
            console.log(vm.libroAdquirido.libro.tituloAbrev, vm.libroAdquirido.cfi, tiempo, palabras);
            // Mantenemos el estado de guardadoEnBdEpub que haya almacenado.
            OfflineService.actualizarAvanceEpub(vm.libroAdquirido.libro.tituloAbrev,
                vm.libroAdquirido.cfi, tiempo, palabras, null);
            e.preventDefault();
        });

        $scope.$on('onUnload', function (e) {
            console.log('leaving page'); // Use 'Preserve Log' option in Console
        });

        initEpub();
    }

})();
