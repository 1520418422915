(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
        // *** Visores ***
            .state('visor', {
                abstract: true,
                url: '/mi-espacio/mis-libros',
                data: {
                    pageTitle: 'global.menu.miespacio.title'
                    // Gestión de roles en el resolve
                },
                params: {
                    id: null
                },
                resolve: {
                    /* @ngInject */
                    libro: function (LibroAdquirido, $stateParams, $localStorage) {
                        if (navigator.onLine) {
                            return LibroAdquirido.get({id: $stateParams.id}).$promise.then(function (data) {
                                return data;
                            });
                        } else {
                            var result = {};
                            $localStorage.librosAdquiridos.forEach(function (libroAdquirido) {
                                if (libroAdquirido.id == $stateParams.id) {
                                    result = libroAdquirido;
                                }
                            });
                            console.log("Libro recuperado de Offline (resolve):", result);
                            return result;
                        }
                    },
                    /* @ngInject */
                    isVisor: function ($rootScope, Auth) {
                        if (navigator.onLine) {
                            Auth.authorize(false, "ROLE_AUTHENTICATED");
                        }

                        $rootScope.isVisor = true;
                        angular.element("html").addClass("full");
                        angular.element("body").addClass("full");
                    }
                }
            })
            .state('visor/pdf', {
                // TODO: Añadir título del libro a la URL
                url: '/visor-pdf/:id',
                parent: 'visor',
                views: {
                    'visor@': {
                        template: '<visor-pdf libro-adquirido="$resolve.libro"></visor-pdf>'
                    }
                }
            })
            .state('visor/epub', {
                // TODO: Añadir título del libro a la URL
                url: '/visor-epub/:id',
                parent: 'visor',
                params: {
                    frommail: false,
                    tarea: undefined
                },
                views: {
                    'visor@': {
                        template: '<visor-epub libro-adquirido="$resolve.libro" ' +
                            'preferencias-usuario="$resolve.preferencias" notas="$resolve.notas"></visor-epub>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    notas: function (NotaEpub, libro) {
                        if (navigator.onLine) {
                            return NotaEpub.findByLibroAdquirido({libroAdquiridoId: libro.id}).$promise.then(function (data) {
                                console.log("NOTAS:", data);
                                return data;
                            });
                        }
                    },
                    /* @ngInject */
                    preferencias: function (Preferencias, OfflineService) {
                        if (navigator.onLine) {
                            return Preferencias.obtenerPreferencias().$promise.then(function (data) {
                                OfflineService.gardarPreferenciasOff(data);
                                return data;
                            });
                        } else {
                            return OfflineService.recuperarPreferenciasOff();
                        }
                    }
                }
            })

            // *** Visores de Muestra ***

            .state('visor-muestra', {
                abstract: true,
                url: '/catalogo',
                data: {
                    pageTitle: 'visor.muestra'
                },
                params: {
                    id: null
                },
                resolve: {
                    /* @ngInject */
                    libro: function (Libro, $stateParams) {
                        return Libro.get({id: $stateParams.id}).$promise.then(function (data) {
                            console.log("Obteniendo muestra");
                            return data;
                        })
                    },
                    /* @ngInject */
                    isVisor: function ($rootScope) {
                        $rootScope.isVisor = true;
                        angular.element("html").addClass("full");
                        angular.element("body").addClass("full");
                    }
                }
            })
            .state('visor-muestra/pdf', {
                url: '/previsualizar-pdf/:id',
                parent: 'visor-muestra',
                views: {
                    'visor@': {
                        template: '<visor-pdf muestra="$resolve.libro"></visor-pdf>'
                    }
                }
            })
            .state('visor-muestra/epub', {
                url: '/previsualizar-epub/:id',
                parent: 'visor-muestra',
                views: {
                    'visor@': {
                        template: '<visor-epub muestra="$resolve.libro"></visor-epub>'
                    }
                }
            })
            .state('visor-muestra/tareas/epub', {
                url: '/tareas/previsualizar-epub/:id',
                parent: 'visor-muestra',
                params: {
                  fromTareas: true
                },
                views: {
                    'visor@': {
                        template: '<visor-epub muestra="$resolve.libro"></visor-epub>'
                    }
                }
            })

            // *** Previsualización en Administración ***

            .state('visor-admin', {
                abstract: true,
                url: '/admin/libro',
                data: {
                    pageTitle: 'visor.admin'
                },
                params: {
                    id: null
                },
                resolve: {
                    /* @ngInject */
                    libro: function (Libro, $stateParams) {
                        return Libro.get({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        })
                    },
                    /* @ngInject */
                    isVisor: function ($rootScope) {
                        $rootScope.isVisor = true;
                        angular.element("html").addClass("full");
                        angular.element("body").addClass("full");
                    }
                }
            })
            .state('visor-admin/pdf', {
                url: '/previsualizar-pdf/:id',
                parent: 'visor-admin',
                views: {
                    'visor@': {
                        template: '<visor-pdf muestra="$resolve.libro" admin="true"></visor-pdf>'
                    }
                }
            })
            .state('visor-admin/epub', {
                url: '/previsualizar-epub/:id',
                parent: 'visor-admin',
                views: {
                    'visor@': {
                        template: '<visor-epub muestra="$resolve.libro" admin="true"></visor-epub>'
                    }
                }
            })

    }
})();
