(function () {
    'use strict';

    angular.module('app')
        .controller('UsuariosController', UsuariosController);

    /* @ngInject */
    function UsuariosController($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS, ModalService,
                                User, EnumService) {
        var vm = this;

        var roles = ['ROLE_LECTOR', 'ROLE_PROFESOR', 'ROLE_CENTRO', 'ROLE_LIBRERIA'];

        vm.item = User;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            activado: true,
            eliminado: false,
            validado: true,
            rol: $stateParams.rol,
            roles: roles
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {fechaRegistro: 'desc'}
        }, NgTableHelper.settings(vm));

        // Eliminado lógico
        vm.showRemoveConfirmation = function (email) {
            ModalService.open({
                templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                controller: 'EntityDeleteModalController',
                controllerAs: 'deleteCtrl',
                resolve: {
                    item: function () {
                        return User.get({email: email}).$promise;
                    },
                    params: function () {
                        return {
                            title: 'admin.list-users.delete.title',
                            body: 'admin.list-users.delete.confirm',
                            property: 'email'
                        };
                    }
                }
            }).result.then(function () {
                vm.tableParams.reload();
            });
        };

        // Eliminado físico
        vm.showBajaConfirmation = function (email) {
            ModalService.open({
                    templateUrl: 'app/components/form/confirm/confirm.modal.html',
                    controller: 'ConfirmModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        item: function () {
                            return {email: email};
                        },
                        /* @ngInject */
                        tipo: function (User) {
                            return User;
                        },
                        funcion: function () {
                            return "bajaUsuario";
                        },
                        parametros: function () {
                            return {email: email};
                        },
                        params: function () {
                            return {
                                title: 'admin.list-users.gestion.baja.title',
                                body: 'admin.list-users.gestion.baja.confirm',
                                property: 'email'
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

        // Enumerados
        EnumService.get("rol").then(function (result) {
            vm.roles = result;
        });
    }
})();
