(function () {
    'use strict';

    angular
        .module('app')
        .component('catalogoMenu', {
            templateUrl: 'app/public/components/catalogo/catalogo-menu.html',
            controller: CatalogoMenu,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function CatalogoMenu(GrupoEdad, Coleccion) {
        var vm = this;

        Coleccion.findAll().$promise.then(function (result) {
            vm.colecciones = result.content;
        });

        GrupoEdad.findAll({sortProperty: 'orden', sortDirection: 'ASC'}).$promise.then(function (result) {
            vm.grupos = result.content;
        });
    }

})();
