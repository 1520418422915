(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('carro', {
            parent: 'public',
            url: '/carro',
            data: {
                pageTitle: 'global.menu.carro',
                authorities: ['ROLE_LECTOR', 'ROLE_PROFESOR', 'ROLE_LIBRERIA']
            },
            params: {
                tramitar: false
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/carro/carro.list.html'
                }
            },
            resolve: {
                /* @ngInject */
                carro: function (CarroHelper) {
                    return CarroHelper.get();
                },
                /* @ngInject */
                tramitar: function ($stateParams) {
                    console.log($stateParams);
                    return $stateParams.tramitar;
                }
            }
        });
    }
})();
