(function () {
    'use strict';

    angular.module('app')
        .factory('RutasService', RutasService);

    /* @ngInject */
    function RutasService($localStorage, $location, URL_PDF, URL_EPUB, URL_RECURSO, DEBUG_INFO_ENABLED) {
        var cacheUrl = "";

        return {
            getBaseUrl: getBaseUrl,
            getTokenParam: getTokenParam,
            getPdf: getPdf,
            getEpub: getEpub,
            getRecurso: getRecurso,
            getPortada: getPortada
        };

        function getBaseUrl() {
            // En desarrollo utilizamos la de pre porque si no hay problemas al compartir con Facebook
            if (!cacheUrl) {
                if (DEBUG_INFO_ENABLED) {
                    cacheUrl = "http://octo1601.cli.enxenio.net";
                } else {
                    cacheUrl = $location.protocol() + "://" + $location.host();
                    if ($location.port() !== 80) {
                        cacheUrl = cacheUrl + ":" + $location.port();
                    }
                }
            }
            return cacheUrl;
        }

        function getTokenParam() {
            return "?oh=xJhbGciO" + $localStorage.authenticationToken;
        }

        function getPdf(id, path, esMuestra) {
            var protegida = !esMuestra ? "protegida/" : "";
            return URL_PDF(id) + protegida + path + getTokenParam();
        }

        function getEpub(id, path, esMuestra) {
            var protegida = !esMuestra ? "protegida/" : "";
            return URL_EPUB(id) + protegida + path + getTokenParam();
        }

        function getRecurso(id, path) {
            return URL_RECURSO(id) + path;
        }

        function getPortada(libroId, path, full) {
            var ruta = "assets/images/portada.jpg";
            if (path) {
                ruta = "upload/libros/" + libroId + "/portada/";
                if (!full) {
                    ruta = ruta + "n_";
                }
                ruta = ruta + path;
            }
            return ruta;
        }

    }

})();
