(function () {
    'use strict';

    angular.module('app')
        .factory('Miembro', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = 'miembro';

        return ResourceHelper.getResource(entidad, {}, {
            'findByClub': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findByClub',
                isArray: false
            },
            'bloquear': {
                method: 'GET',
                url: 'api/public/' + entidad + '/bloquear'
            },
            'abandonar': {
                method: 'GET',
                url: 'api/public/' + entidad + '/abandonar'
            }
        }, true);
    }

})();
