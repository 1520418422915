(function (angular) {
    'use strict';

    angular.module('app', [
        'ui.bootstrap',
        'ui.router',
        'angular-loading-bar',
        'infinite-scroll',
        'ncy-angular-breadcrumb',
        'ngAnimate',
        'ngCacheBuster',
        'ngCookies',
        'ngFileUpload',
        'ngResource',
        'ngStorage',
        'ngTable',
        'ngTagsInput',
        'pascalprecht.translate',
        'rzModule',
        'textAngular',
        "uiCropper",
        '720kb.socialshare',
        'vcRecaptcha',
        'timer',
        '720kb.socialshare'
    ]).run(appRun);

    /* @ngInject */
    function appRun($rootScope, $translate, stateHandler) {

        stateHandler.initialize();

        $rootScope.changeLanguage = function (key) {
            $translate.use(key);
            $translate.refresh();
        };

    }
})
(angular);
