(function () {
    'use strict';

    angular.module('app')
        .factory('MateriaIbic', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        return ResourceHelper.getResource("materia-ibic");
    }

})();
