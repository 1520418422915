(function () {
    'use strict';

    angular.module('app')
        .factory('User', User);

    /* @ngInject */
    function User($resource, ParseLinks) {
        var urlAdmin = 'api/admin/user';
        var urlPublic = 'api/public/user';
        return $resource(urlAdmin, {email: '@account.email'}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    return {
                        links: ParseLinks.parse(headers('link')),
                        list: angular.fromJson(data)
                    };
                }
            },
            'get': {
                method: 'GET',
                params: {
                    email: '@email'
                }
            },
            'getById': {
                method: 'GET',
                url: urlAdmin + '/id',
                params: {
                    id: '@id'
                }
            },
            'getAlumno': {
                method: 'GET',
                url: urlPublic + '/id',
                params: {
                    id: '@id'
                }
            },
            'getProfesor': {
                method: 'GET',
                url: urlPublic + '/id',
                params: {
                    id: '@id'
                }
            },
            'findAll': {
                method: 'GET',
                url: urlAdmin + '/findAll'
            },
            'findProfesores': {
                method: 'GET',
                url: urlPublic + '/findProfesores'
            },
            'findProfesoresByAdmin': {
                method: 'GET',
                url: urlAdmin + '/findProfesores'
            },
            'email': {
                method: 'GET',
                url: urlAdmin + '/email',
                params: {
                    email: '@email'
                }
            },
            'activar': {
                method: 'POST',
                url: urlAdmin + '/activar',
                params: {
                    email: '@email',
                    activar: '@activar'
                }
            },
            'desvincularProfesor': {
                method: 'DELETE',
                url: urlAdmin + '/desvincular-profesor',
                params: {
                    email: '@email'
                }
            },
            'restaurar': {
                method: 'POST',
                url: urlAdmin + '/restaurar',
                params: {
                    id: '@id'
                }
            },
            'baja': {
                method: 'GET',
                url: urlPublic + '/baja'
            },
            'bajaUsuario': {
                method: 'GET',
                url: urlAdmin + '/bajaUsuario'
            },
            'cambioEmail': {
                method: 'PUT',
                url: urlPublic + '/solicitar-cambio-email'
            }
        });
    }
})();
