(function () {
    'use strict';

    angular.module('app')
        .factory('Invitacion', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = 'invitacion';

        return ResourceHelper.getResource(entidad, {}, {
            'invitar': {
                method: 'GET',
                url: 'api/public/' + entidad + '/invitar'
            },
            'findByClub': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findByClub',
                isArray: false
            },
            'checkPendientes': {
                method: 'GET',
                url: 'api/public/' + entidad + '/checkPendientes',
                transformResponse: function (data) {
                    return {data: data === 'true'};
                }
            },
            'responderInvitacion': {
                method: 'GET',
                url: 'api/public/' + entidad + '/responderInvitacion'
            }
        }, true);
    }

})();
