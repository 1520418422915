(function () {
    'use strict';

    angular.module('app')
        .controller('EstadisticasLibroController', EstadisticasLibroController);

    /* @ngInject */
    function EstadisticasLibroController($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
        Estadisticas, Centro) {
        var vm = this;

        vm.centro = undefined;

        Centro.findCentrosList().$promise.then(function (result) {
            vm.centros = result;
        });

        vm.item = Estadisticas;

        vm.function = "getEstadisticasLibros";
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            fechaInicio: undefined,
            fechaFin: undefined,
            titulo: undefined,
            centro: undefined
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {titulo: 'asc'}
        }, NgTableHelper.settings(vm));


        vm.updateFechaInicio = function (input) {
            vm.filter.fechaInicio = input;
        };

        vm.updateFechaFin = function (input) {
            vm.filter.fechaFin = input;
        };

        vm.updateCentro = function (item) {
            if (item !== null) {
                vm.filter.centro = item.id;
            } else {
                vm.filter.centro = "";
            }

        };
    }
})();
