(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('SuscripcionLibroRevisarController', Controller);

    /* @ngInject */
    function Controller($state, $window, $stateParams, Compra, AlertService) {
        var vm = this;

        vm.libro = $stateParams.libro;

        vm.confirmar = function(){
            Compra.crearSuscripcionLibro(vm.libro.id).$promise.then(function () {
                AlertService.success('catalogo.packs.confirmar.exito');
                $state.go('miespacio/packs/mispacks');
            });
        };

        vm.cancel = function () {
            $window.history.back();
        };

    }

})(angular);
