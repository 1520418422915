(function () {
    'use strict';

    // tarea: Tarea de la que se va a mostrar la información

    angular
        .module('app')
        .component('tareaExamen', {
            templateUrl: 'app/public/components/proyectos/tarea/tarea.examen.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tarea: '<'
            }
        });

    /* @ngInject */
    function Controller($timeout, Examen, Ejecucion, AlertService) {
        var vm = this;

        console.log("TareaExamen: ", vm.tarea);

        var alertInicio;
        vm.loaded = false;

        vm.today = (new Date()).getTime() / 1000;

        // FIXME: Está dando comienzo el examen futuro

        Examen.findExamenDeAlumno({tareaId: vm.tarea.id}).$promise.then(function (data) {
            vm.examen = data;
            if (!vm.examen.id) return;

            var alarm = (vm.examen.fechaInicio * 1000) - new Date();
            var duracion = vm.examen.duracion * 60 * 1000;

            console.log("Alarma de examen en " + alarm / 1000 + " segundos");
            console.log("Duración: " + duracion / 1000 + " segundos");

            if ((alarm > 0 || Math.abs(alarm) < duracion)) {
                if (alarm < 2147483647) {
                    $timeout(function () {
                        alertInicio = AlertService.info("miespacio.proyecto.examen.fecha.aviso", {}, 0);
                        vm.mostrar = true;
                    }, alarm);
                }
            } else {
                vm.pasado = true;
            }

            Ejecucion.comprobar({examenId: vm.examen.id}).$promise.then(function (result) {
                if (result.id) {
                    vm.ejecucion = result;
                    vm.pasado = true;
                    // Existe una ejecución finalizada
                    if (result.fechaFin) {
                        vm.mostrar = false;
                        vm.enCorreccion = !result.corregida;
                        if (alertInicio) {
                            AlertService.closeAlert(alertInicio.id);
                        }
                    } else {
                        // Existe una ejecución pero no ha sido finalizada
                        vm.continuar = true;
                    }
                }
                vm.loaded = true;
            });
        });
    }

})();
