(function () {
    'use strict';

    // club: Club del que se va a mostrar la información
    // comentarios: Comentarios asociados al club
    // libroAdquirido: Libro adquirido en caso de que el usuario lo tenga

    angular
        .module('app')
        .component('clubDetails', {
            templateUrl: 'app/public/components/clubes/club.details.html',
            bindings: {
                club: '=',
                comentarios: '<',
                libro: '<',
                administra: '<'
            },
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function () {
                var vm = this;
                vm.lectura = new Date(vm.club.fechaFin * 1000) < new Date();
            }
        });

})();
