(function () {
    'use strict';

    // Gestión de una suscripción
    angular
        .module('app')
        .component('suscripcion', {
            templateUrl: 'app/public/components/suscripciones/suscripcion.html',
            controller: suscripcionController,
            controllerAs: 'ctrl',
            bindings: {
                suscripcion: '<'
            }
        });

    /* @ngInject */
    function suscripcionController($translate, $state, Suscripcion, ModalService, AlertService) {
        var vm = this;

        vm.precioSuscripcion = obtenerPrecioSuscripcion(vm.suscripcion.centro.numAlumnos);
        vm.textoCategoriaCentro = obtenerTextoCategoriaCentro(vm.suscripcion.centro.numAlumnos);

        vm.toggleActiva = function (suscripcion) {

            if (!suscripcion.activa) {
                suscripcion.activa = !suscripcion.activa;

                Suscripcion.cambiarEstado(suscripcion).$promise.then(function () {
                    suscripcion.solicitudCancelacion = false;
                    suscripcion.fechaSolicitudCancelacion = null;
                }).catch(function () {
                    suscripcion.activa = !suscripcion.activa;
                });
            } else {

                vm.showCancelSuscripcion(suscripcion);
            }
        };

        vm.showCancelSuscripcion = function (suscripcion) {
            ModalService.open({
                templateUrl: 'app/public/components/suscripciones/suscripcion.delete.modal.html',
                controller: 'SuscripcionDeleteModalController',
                controllerAs: 'deleteCtrl',
                resolve: {
                    /* @ngInject */
                    item: function () {
                        return suscripcion;
                    },
                    params: function () {
                        return {
                            title: 'miespacio.suscripciones.cancel.title',
                            body: 'miespacio.suscripciones.cancel.body',
                            property: 'nombre'
                        };
                    }
                }
            }).result.then(function () {
                AlertService.success("Suscripción cancleada");
                suscripcion.fechaFin = Date.now();
            });
        };

        vm.verCatalogo = function (pack) {
            ModalService.open({
                templateUrl: 'app/public/components/packs/packs.catalogo.modal.html',
                /* @ngInject */
                controller: function (ModalService) {
                    var vm = this;
                    vm.close = function () {
                        ModalService.close();
                    };
                    },
                    controllerAs: 'ctrl',
                    size: 'lg',
                    resolve: {
                        pack: function () {
                            return pack;
                        }
                    },
                    windowClass: 'modalLibros'
                }
            );
        };

        // Eliminar
        vm.showRemoveConfirmation = function (suscripcion) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    resolve: {
                        /* @ngInject */
                        item: function () {
                            return suscripcion;
                        },
                        params: function () {
                            return {
                                title: 'admin.suscripciones.delete.title',
                                body: 'admin.suscripciones.delete.confirm',
                                property: 'pack.nombre'
                            };
                        }
                    }
                }
            ).result.then(function () {
                $state.reload();
            });
        };

        function obtenerTextoCategoriaCentro(categoriaCentro) {
            var split = categoriaCentro.split("_");
            var numero = split[split.length - 1];

            if (categoriaCentro != 'MAS_DE_1000') {
                return $translate.instant('catalogo.packs.alumnoshasta', {numero: numero});
            } else {
                return $translate.instant('catalogo.packs.alumnosmasde', {numero: numero});
            }
        }

        function obtenerPrecioSuscripcion(categoriaCentro) {
            var precioBase;
            if (categoriaCentro != 'MAS_DE_1000') {
                var numero = categoriaCentro.split("_")[1];
                precioBase = vm.suscripcion.pack['precioBase' + numero];
            } else {
                precioBase = vm.suscripcion.pack['precioBaseMas1000'];
            }

            var precioConIva = precioBase + precioBase * (vm.suscripcion.pack.iva.valor / 100);
            return precioConIva.toFixed(2) * 1;
        }

    }

})();
