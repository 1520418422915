(function () {
    'use strict';

    angular
        .module('app')
        .component('biblioteca', {
            templateUrl: 'app/public/components/biblioteca/biblioteca.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                libros: '<'
            }
        });

    /* @ngInject */
    function Controller($state, LibroAdquirido) {
        var vm = this;

        vm.cogerPrestado = function (libro) {
            LibroAdquirido.cogerPrestado({libroId: libro.id}).$promise.then(function (data) {
                $state.go('miespacio/mislibros');
            });
        };

    }

})();
