(function (angular) {
    'use strict';
    angular
        .module('app')
        .controller('RecursoFormAdminController', RecursoFormAdminController);

    /* @ngInject */
    function RecursoFormAdminController($timeout, $state, $stateParams, itemData,
                                        categoriaRecursos, previousParams, URL_RECURSO, AlertService) {
        var vm = this;

        vm.titulo = $stateParams.titulo;
        vm.categoriaRecursos = categoriaRecursos;
        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        $timeout(function () {
            angular.element('.form-group>input:eq(0)').focus();
        });

        function initForm() {
            vm.urlBaseRecurso = URL_RECURSO($stateParams.libroId);
            if (!vm.item.libro) {
                vm.item.libro = {id: $stateParams.libroId};
            }
        }

        // Guardar formulario
        vm.save = function () {
        	if (vm.item.archivo == null) {
        		AlertService.error("admin.libro.recurso.error.empty");
        	} else {
        		vm.item.$save().then(function () {
                    var obligatorios = angular.element("text-angular.ng-invalid-required");
                    if (obligatorios) {
                        $state.go('admin/libro/recurso/list', previousParams);
                    }
                });
        	}
        };

        initForm();
    }
})(angular);
