(function (angular) {
    'use strict';

    // Componente para incluir la portada de un libro

    // libroId: ID del libro
    // portada: Path a la portada (nombre del archivo)
    // full: (boolean) Si es falso, se añade el prefijo 'n_' a la imagen. Si es true, se coge la original.
    // class: Clases de la imagen

    angular
        .module('app')
        .component('portada', {
            bindings: {
                libroId: "<",
                portada: "<",
                full: '<',
                class: '@'
            },
            template: '<img ng-src="{{ctrl.ruta}}" class="{{ctrl.class}}"/>',
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function (RutasService) {
                var vm = this;
                vm.ruta = RutasService.getPortada(vm.libroId, vm.portada, vm.full);
            }
        });
})(angular);
