(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:captcha
     * @description
     * Componente para la verificación de los usuarios mediante un captcha de Google (reCAPTCHA).
     *
     * Hay que definir en `apis.recaptcha` (en el archivo `config.js`) la clave de Google reCAPTCHA.
     * __Si no se define la clave__, este componente no se mostrará.
     *
     * Nota: Si no se quiere mostrar el mensaje de "Campo obligatorio", se puede deshabilitar el botón de submit en el formulario:
     * `ng-disabled="ctrl.form.$invalid"`.
     *
     * Referencia:
     *
     * https://github.com/VividCortex/angular-recaptcha
     *
     * https://www.google.com/recaptcha
     *
     *
     */
    angular
        .module('app')
        .component('captcha', {
            templateUrl: 'app/components/form/captcha/captcha.html',
            bindings: {
                onSuccess: '&'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller(RECAPTCHA_API_KEY) {
        var vm = this;

        vm.apiKey = RECAPTCHA_API_KEY;

        vm.success = function (response) {
            vm.onSuccess({response: response});
        }
    }

})();
