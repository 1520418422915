(function () {
    'use strict';

    angular.module('app')
        .factory('Suscripcion', Suscripcion);

    /* @ngInject */
    function Suscripcion(ResourceHelper) {
        var entidad = 'suscripcion';

        return ResourceHelper.getResource(entidad, {}, {
            'findAllByCentro': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/findAllByCentro',
                isArray: true
            },
            'cambiarEstado': {
                method: 'POST',
                url: 'api/admin/' + entidad + '/cambiarEstado'
            },
            'saveAMedida': {
                method: 'POST',
                url: 'api/admin/' + entidad + '/saveAMedida'
            },
            'cancelar': {
                method: 'GET',
                url: 'api/public/' + entidad + '/cancelar'
            }
        }, false);
    }

})();
