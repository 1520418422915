(function () {
    'use strict';

    angular.module('app')
        .factory('Examen', Examen);

    /* @ngInject */
    function Examen(ResourceHelper) {
        return ResourceHelper.getResource("examen", {}, {
            'findExamenDeAlumno': {
                method: 'GET',
                url: 'api/public/examen/findExamenDeAlumno',
                isArray: false
            },
            'findPreguntasParaExamen': {
                method: 'GET',
                url: 'api/public/examen/findPreguntasParaExamen',
                isArray: true
            },
            'findPreguntasPorBloque': {
                method: 'GET',
                url: 'api/public/examen/findPreguntasPorBloque',
                isArray: false
            }
        });
    }

})();
