(function () {
    'use strict';

    angular
        .module('app')
        .component('libroElement', {
            templateUrl: 'app/public/components/libro/libro.element.html',
            controller: LibroController,
            controllerAs: 'ctrl',
            bindings: {
                libro: '<',
                lectura: '<'
            }
        });

    /* @ngInject */
    function LibroController($state, Principal, CarroHelper, LibroAdquirido) {
        var vm = this;

        Principal.identity().then(function (data) {
            if (data && data.rol === 'ROLE_CENTRO') {
                vm.esCentro = true;
            }
            if (data && data.rol === 'ROLE_PROFESOR') {
                vm.esProfesor = true;
            }
        });

        vm.addToCarro = function () {
            CarroHelper.addProducto(vm.libro);
        };

        vm.cogerPrestado = function () {
            LibroAdquirido.cogerPrestado({libroId: vm.libro.id}).$promise.then(function (data) {
                $state.go('miespacio/mislibros');
            });
        };

    }

})();
