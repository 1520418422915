(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('RegistroController', RegistroController);

    /* @ngInject */
    function RegistroController($timeout, $stateParams, Usuario, EnumService, AlertService, packs, $scope, Compra) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group>input:eq(0)').focus();
        });

        vm.mode = 'create';
        vm.packsSeleccionados = [];

        function initForm() {
            vm.item = new Usuario();
            vm.item.type = vm.tipo = $stateParams.tipo;
            vm.confirmPassword = "";
            vm.confirmEmail = "";
            vm.showPassword = false;
            vm.showPasswordRepeat = false;

            vm.packs = packs;

            initImagen();
        }

        initForm();

        // Guardar formulario
        vm.save = function () {
            if (vm.item.centro) {
                vm.item.centro.type = 'centro';
            }

            if (vm.item.email !== vm.confirmEmail ) {
                AlertService.error("admin.register.error.emaildontmatch");
            } else if (vm.item.contrasenaClaro !== vm.confirmPassword) {
                AlertService.error("admin.register.error.dontmatch");
            } else if (vm.item.type === 'profesor' && !vm.item.independiente && !vm.item.centro) {
                AlertService.error("registro.error.profesor");
            } else if (!vm.acepto) {
                AlertService.error("registro.error.acepto");
            } else {
                vm.item.$register().then(function (data) {
                    if (vm.item.type === 'centro' && vm.packsSeleccionados.length)
                        Compra.crearSuscripcionCentro({centroId: data.id, packs: vm.packsSeleccionados});

                    vm.success = true;
                    initForm();
                });
            }
        };

        // Foto de perfil
        function initImagen() {
            if (!vm.item.imagen) {
                vm.item.imagen = {};
            }
            if (vm.item.foto) {
                vm.item.imagen.path = vm.item.foto;
            }
        }

        vm.onRemoveImage = function () {
            vm.item.imagen = {eliminar: true};
        };

        vm.packSeleccionado = function (pack) {
            pack = pack.pack;
            pack.seleccionado = !pack.seleccionado;
            if (pack.seleccionado) {
                vm.packsSeleccionados.push(pack.id);
            } else {
                vm.packsSeleccionados.splice(vm.packsSeleccionados.indexOf(pack.id), 1);
            }
        };

        $scope.$watch(function () {
            return vm.item.numAlumnos;
        }, function (valorNuevo, valorViejo) {
            if (valorNuevo !== undefined) {
                vm.packs.forEach(function (pack) {
                    pack.numAlumnos = valorNuevo;

                    var numero;
                    if (pack.numAlumnos === 'MAS_DE_1000') {
                        numero = 'Mas1000';
                    } else {
                        var split = pack.numAlumnos.split("_");
                        numero = split[split.length - 1];
                    }

                    var precioBase = 'precioBase' + numero;

                    // TODO AISLAR EN MODULO APARTE
                    pack.precioConIva = pack[precioBase] + pack[precioBase] * (pack.iva.valor / 100);
                    pack.precioConIva = pack.precioConIva.toFixed(2) * 1;
                });
            }
        });


        // Enumerados

        EnumService.get("sexo").then(function (data) {
            vm.sexos = data;
        });

        EnumService.get("modalidad").then(function (data) {
            vm.modalidades = data;
        });

        EnumService.get("numAlumnos").then(function (data) {
            vm.categoriasCentro = data;
        });

        vm.usuarioService = Usuario;

    }
})(angular);
