(function () {
    'use strict';

    // TareaId: ID de la tarea
    // ProyectoId: ID del proyecto

    // TODO: Filtrar por grupo mediante pestañas

    angular
        .module('app')
        .component('alumnosTarea', {
            templateUrl: 'app/public/components/proyectos/alumno/alumnos-tarea.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tarea: '<'
            }
        });

    /* @ngInject */
    function Controller($state, $scope, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper, ModalService,
                        Tarea, Ejecucion, ExamenHelper) {
        var vm = this;

        console.log("Alumnos tarea");

        // FIXME: Se están realizando dos peticiones seguidas para obtener los alumnos.

        vm.ahora = new Date();

        vm.item = Tarea;
        vm.function = 'findAlumnos';
        vm.pagina = 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS_PUBLIC.size;
        vm.filter = {
            key: undefined,
            id: vm.tarea.id,
            proyectoId: vm.tarea.proyecto.id,
            grupoId: vm.tarea.proyecto.tieneGrupos > 0 ? vm.tarea.proyecto.grupos[0].id : ''
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {nombre: 'asc'}
        }, NgTableHelper.settings(vm));

        // Abrimos la ventana de información
        vm.asignarAlumnos = function () {
            ModalService.open({
                templateUrl: 'app/public/components/proyectos/tarea/tarea.alumnos.form.modal.html',
                controller: 'TareaAlumnosFormModalController',
                controllerAs: 'ctrl',
                resolve: {
                    /* @ngInject */
                    alumnosProyecto: function (Proyecto) {
                        return Proyecto.findAlumnos({
                            id: vm.tarea.proyecto.id,
                            activos: true
                        }).$promise.then(function (data) {
                            return data.content;
                        });
                    },
                    tarea: function () {
                        return vm.tarea;
                    },
                    alumnosTarea: function () {
                        return vm.tarea.alumnos;
                    }
                }
            }).result.then(function (data) {
                vm.tarea.alumnos = data;
                vm.tableParams.reload();
            });
        };

        vm.verEjecucion = function (alumno) {
            ExamenHelper.verEjecucion(vm.tarea.id, alumno.examenid, alumno.ejecucionid).result.then(function (data) {
                // Actualizamos las notas del usuario
                alumno.notabloque3a = data.notaBloque3A;
                alumno.notabloque3b = data.notaBloque3B;
                alumno.nota = data.nota;
            });
        };

        vm.publicarNotas = function (examen) {
            Ejecucion.publicarNotas({examenId: examen.id}).$promise.then(function () {
                $state.go($state.current, {}, {reload: true});
            });
        };

        $scope.$watch(function () {
            return vm.tableParams.data;
        }, function () {
            console.log("Actualizada data:", vm.tableParams.data);
            if (vm.tableParams.data) {
                vm.totalAccesos = vm.tableParams.data.reduce(function (a, b) {
                    if (b.accesos) {
                        return a + b.accesos;
                    } else {
                        return a;
                    }
                }, null);
                vm.totalTiempo = vm.tableParams.data.reduce(function (a, b) {
                    if (b.tiempo) {
                        return a + b.tiempo;
                    } else {
                        return a;
                    }
                }, null);
                vm.mediaNrt = vm.tableParams.data.reduce(function (a, b) {
                    if (b.nota) {
                        if (a) {
                            return (a + b.nota) / 2;
                        } else {
                            return a + b.nota;
                        }
                    } else {
                        return a;
                    }
                }, null);
            }
        });

        $scope.$watch(function () {
            return vm.cols;
        }, function () {
            if (vm.cols) {
                // Eliminamos la columna de nombre y la de acciones para que no se puedan ocultar
                vm.cols.splice(0, 1);
                vm.cols.splice(vm.cols.length - 1, 1);
            }
        });

    }

})();
