(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('DeseosController', DeseosController);

    /* @ngInject */
    function DeseosController(lista, DeseoHelper, CarroHelper) {
        var vm = this;

        vm.lista = lista;

        vm.removeDeseo = function (deseo) {
            DeseoHelper.removeDeseo({id: deseo.id}).$promise.then(function () {
                vm.lista.splice(vm.lista.indexOf(deseo), 1);
            });
        };

        vm.addToCarro = function (libro) {
            CarroHelper.addProducto(libro);
        };

    }

})(angular);
