(function () {
    'use strict';

    angular.module('app')
        .factory('Factura', Factura);

    /* @ngInject */
    function Factura($http, DescargaUtil) {
        return {
            get: get,
            getDatosFacturacion: getDatosFacturacion,
            saveDatosFacturacion: saveDatosFacturacion,
            descargar: descargar,
            descargarCliente: descargarCliente
        };

        function get(compraId) {
            return $http({
                method: 'GET',
                url: 'api/public/factura/get',
                params: {compraId: compraId}
            }).then(function (result) {
                return result.data;
            });
        }

        function getDatosFacturacion() {
            return $http({
                method: 'GET',
                url: 'api/public/factura/getDatosFacturacion'
            }).then(function (result) {
                return result.data;
            });
        }

        function saveDatosFacturacion(factura) {
            return $http.post('api/public/factura/saveDatosFacturacion', factura);
        }

        function descargar(compraId) {
            descargarAux(compraId, 'api/public/factura/descargar');
        }

        function descargarCliente(compraId) {
            descargarAux(compraId, 'api/public/factura/descargarCliente');
        }

        function descargarAux(compraId, url) {
            DescargaUtil.descargar(url, {compraId: compraId}, 'applicaton/pdf');
        }
    }

})();
