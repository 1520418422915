(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('factura', {
                parent: 'miespacio/miscompras',
                url: '/mis-compras/:id/generar-factura',
                data: {
                    pageTitle: 'global.menu.miespacio.title'
                },
                views: {
                    'contentEspacio@miespacio': {
                        templateUrl: 'app/public/cuenta/miespacio/miscompras/factura/factura.form.html',
                        controller: 'FacturaFormController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    itemData: function ($stateParams, Factura) {
                        return Factura.get($stateParams.id);
                    }
                }
            });
    }
})();
