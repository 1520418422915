(function () {
    'use strict';

    angular.module('app')
        .controller('EstadisticasUsuarioController', EstadisticasUsuarioController);

    /* @ngInject */
    function EstadisticasUsuarioController($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
                                           Estadisticas, EnumService) {
        var vm = this;

        var roles = ['ROLE_LECTOR', 'ROLE_PROFESOR', 'ROLE_CENTRO', 'ROLE_LIBRERIA'];

        vm.item = Estadisticas;
        vm.function = "getEstadisticasUsuarios";
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            activado: true,
            validado: true,
            eliminado: false,
            roles: roles
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {fechaUltimoAcceso: 'desc'}
        }, NgTableHelper.settings(vm));

        // Enumerados

        EnumService.get("rol").then(function (result) {
            vm.roles = result;
        });


    }
})();
