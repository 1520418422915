(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('VerificarController', VerificarController);

    /* @ngInject */
    function VerificarController($stateParams, Auth) {
        var vm = this;

        Auth.verifyAccount($stateParams.key).then(function () {
            vm.error = null;
            vm.success = true;
        }).catch(function (err) {
            vm.error = err.status;
        });
    }

})(angular);
