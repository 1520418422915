(function () {
    'use strict';
    angular
        .module('app')
        .constant('URL_EPUB', function (id) {
            return '/upload/libros/' + id + '/epub/';
        })
        .constant('URL_PDF', function (id) {
            return '/upload/libros/' + id + '/pdf/';
        })
        .constant('URL_RECURSO', function (id) {
            return '/upload/libros/' + id + '/recursos/';
        })
    ;
})();
