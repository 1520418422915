(function () {
    'use strict';

    angular.module('app')
        .factory('Contacto', Contacto);

    /* @ngInject */
    function Contacto(ResourceHelper) {
        return ResourceHelper.getResource("contacto");
    }

})();
