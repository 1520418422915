(function () {
    'use strict';

    // item: Proyecto del que se muestra información
    // params: JSON de la forma {title: '', body: '', property: ''}

    angular.module('app')
        .controller('AlumnosProyectoModalController', Controller);

    /* @ngInject */
    function Controller(item, params, ModalService) {
        var vm = this;

        vm.dismiss = ModalService.dismiss;
        vm.item = item;
        vm.params = params;

        vm.confirm = function () {
            ModalService.close(vm.item);
        };
    }
})();
