(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'public',
            url: '/',
            data: {
                pageTitle: 'global.title'
            },
            params: {
                // Código promocional en caso de que el usuario no estuviese autenticado
                codigo: "",
                '#': null
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'ctrl'
                }
            },
            resolve: {
                /* @ngInject */
                carro: function (CarroHelper) {
                    return CarroHelper.get();
                },
                /* @ngInject */
                codigo: function ($stateParams) {
                    return $stateParams.codigo;
                }
            }
        });
    }
})();
