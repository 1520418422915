(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/configuracion";
        var params = {
            base: base,
            baseUrl: "/" + base,
            templateUrlBase: "app/admin/configuracion/configuracion/configuracion",
            controllerForm: 'ConfiguracionFormAdminController',
            translateBase: "admin.configuracion.configuracion.",
            onlyEdit: true
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN']
            }
        });

        // Editar
        angular.merge(state.edit, {
            resolve: {
                /* @ngInject */
                itemData: function ($stateParams, Configuracion) {
                    return Configuracion.get({id: $stateParams.id}).$promise;
                }
            }
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.edit.name, state.edit);
    }
})();
