(function () {
    'use strict';

    // Proyecto: Proyecto
    // Bloqueados: booleano para saber si los alumnos están bloqueados o no
    // editable: (boolean) Para saber si la información del proyecto todavía se puede editar.

    angular
        .module('app')
        .component('alumnosProyecto', {
            templateUrl: 'app/public/components/proyectos/alumno/alumnos-proyecto.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                proyecto: '<',
                bloqueados: '<',
                editable: '<'
            }
        });

    /* @ngInject */
    function Controller($stateParams, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper, Proyecto) {
        var vm = this;

        console.log("Alumnos proyecto");

        vm.item = Proyecto;
        vm.function = 'findAlumnosConNotas';
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS_PUBLIC.size;
        vm.filter = {
            key: undefined,
            id: vm.proyecto.id,
            activos: !vm.bloqueados
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {'nombre': 'asc'}
        }, NgTableHelper.settings(vm));

        vm.bloquear = function (proyectoAlumno) {
            Proyecto.bloquearAlumno({
                proyectoAlumnoId: proyectoAlumno.id,
                bloquear: !proyectoAlumno.bloqueado
            }).$promise.then(function () {
                vm.tableParams.reload();
            });
        }

    }

})();
