(function () {
    'use strict';

    angular.module('app')
        .factory('beforeUnload', beforeUnload)
        .run(function (beforeUnload) {
            // Must invoke the service at least once
        });

    /* @ngInject */
    function beforeUnload($rootScope, $window) {
        $window.onbeforeunload = function (e) {
            var confirmation = {};
            var event = $rootScope.$broadcast('onBeforeUnload', confirmation);
            if (event.defaultPrevented) {
                return confirmation.message;
            }
        };

        $window.onunload = function () {
            $rootScope.$broadcast('onUnload');
        };

        return {};
    }

})();
