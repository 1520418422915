(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/tarea', {
                parent: 'miespacio/proyecto',
                url: '/:proyectoId/tarea'
            })
            .state('miespacio/tarea/create', {
                parent: 'miespacio/tarea',
                url: '/crear',
                data: {
                    pageTitle: 'miespacio.proyecto.create.title',
                    mode: 'create'
                },
                params: {
                    libroModal: null
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<tarea-form tarea="$resolve.tarea" mode="create"></tarea-form>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    tarea: function ($stateParams, Tarea, Proyecto) {
                        var tarea = new Tarea();
                        console.log(tarea);
                        // Obtenemos el proyecto para mostrar los diferentes grupos
                        /*return Proyecto.get({id: $stateParams.proyectoId}).$promise.then(function (data) {
                            //tarea.proyecto = data;
                            return tarea;
                        });*/
                    }
                }
            })
            .state('miespacio/tarea/details', {
                parent: 'miespacio/tarea',
                url: '/:id',
                data: {
                    pageTitle: 'miespacio.proyecto.edit.title',
                    mode: 'edit'
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<tarea tarea="$resolve.tarea" proyecto="$resolve.proyecto" comentarios="$resolve.comentarios"></tarea>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    tarea: function (Tarea, $stateParams) {
                        return Tarea.get({id: $stateParams.id}).$promise;
                    },
                    /* @ngInject */
                    proyecto : function (Proyecto, $stateParams) {
                      return Proyecto.get({id: $stateParams.proyectoId}).$promise;
                    },
                    /* @ngInject */
                    comentarios: function (Comentario, tarea) {
                        return Comentario.findAllByTarea({id: tarea.id}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            })
            .state('miespacio/tarea/empezar-tarea', {
                parent: 'miespacio/tarea',
                url: '/:id/empezar-tarea',
                views: {
                    'content@': {
                        template: '<empezar-tarea tarea="$resolve.tarea"></empezar-tarea>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    tarea: function (Tarea, $stateParams) {
                        return Tarea.get({id: $stateParams.id}).$promise;
                    }
                }
            });
    }
})();
