(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('LibroFormAdminController', LibroFormAdminController);

    /* @ngInject */
    function LibroFormAdminController($timeout, $state, itemData, generos, ivas, colecciones, grupos, editoriales,
                                      previousParams,
                                      Principal, Idioma, Autor, MateriaIbic, TipoInteligencia, ValorSocial,
                                      ModalService, Libro,
                                      URL_EPUB, URL_PDF, LibroUtilService) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group>input:eq(0)').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        vm.canEdit = true;
        vm.generos = generos;
        vm.ivas = ivas.content;
        vm.colecciones = colecciones.content;
        vm.grupos = grupos.content;
        vm.editoriales = editoriales.content;

        if (vm.mode === 'edit') {
            Libro.checkEliminable({id: vm.item.id}).$promise.then(function (result) {
                vm.eliminable = JSON.parse(result.data);
            });
        }

        // Comprobar autorización
        if (vm.mode === 'edit') {
            Principal.identity().then(function (data) {
                vm.canEdit = data.rol === "ROLE_ADMIN" || data.email === vm.item.responsable.email;
            });
        }

        function initForm() {
            if (!vm.item.autores) {
                vm.item.autores = [];
            }
            if (!vm.item.tiposInteligencia) {
                vm.item.tiposInteligencia = [];
            }
            if (!vm.item.valoresSociales) {
                vm.item.valoresSociales = [];
            }
            if (!vm.item.materiasIbic) {
                vm.item.materiasIbic = [];
            }
            if (!vm.item.iva) {
                vm.item.iva = vm.ivas[0];
            }
            if (!vm.item.precioBase) {
                vm.item.precioBase = 0;
                vm.precioConIva = 0;
            } else {
                vm.precioConIva = vm.item.precioBase + vm.item.precioBase * (vm.item.iva.valor / 100);
                vm.precioConIva = vm.precioConIva.toFixed(2) * 1;
            }
            if (!vm.item.precioBaseAlumno) {
                vm.item.precioBaseAlumno = 0;
                vm.precioAlumnoConIva = 0;
            } else {
                vm.precioAlumnoConIva = vm.item.precioBaseAlumno + vm.item.precioBaseAlumno * (vm.item.iva.valor / 100);
                vm.precioAlumnoConIva = vm.precioAlumnoConIva.toFixed(2) * 1;
            }
            if (!vm.item.epub) {
                vm.item.epub = {};
            }
            if (!vm.item.pdf) {
                vm.item.pdf = {};
            }

            vm.urlBaseEpub = URL_EPUB(vm.item.id);
            vm.urlBasePdf = URL_PDF(vm.item.id);

            initImagen();
        }

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                $state.go('admin/libro/list', previousParams);
            });
        };

        // Cambiar estado visible
        vm.changeVisible = function () {
            if (!vm.item.visible) {
                vm.item.destacado = false;
                vm.item.enVenta = false;
                vm.item.soloTest = false;
            }
        };

        // Cambiar estado de solo test
        vm.changeTest = function () {
            if (vm.item.soloTest) {
                vm.item.enVenta = false;
            }
        };

        // Portada
        function initImagen() {
            if (!vm.item.imagen) {
                vm.item.imagen = {};
            }
            if (vm.item.portada) {
                vm.item.imagen.path = vm.item.portada;
            }
        }

        vm.onRemoveImage = function () {
            vm.item.imagen = {eliminar: true};
        };

        // Precio
        vm.updatePrecio = function (iva) {
            if (iva) {
                vm.item.precioBase = vm.precioConIva / (1 + (iva.valor / 100));
            }
        };

        vm.updatePrecioAlumno = function (iva) {
            if (iva) {
                vm.item.precioBaseAlumno = vm.precioAlumnoConIva / (1 + (iva.valor / 100));
            }
        };

        vm.updateIva = function (iva) {
            vm.updatePrecio(iva);
            vm.updatePrecioAlumno(iva);
        };

        // Eliminar
        vm.showRemoveConfirmation = function () {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    resolve: {
                        item: function () {
                            return vm.item;
                        },
                        params: function () {
                            return {
                                title: 'admin.libro.libro.delete.title',
                                body: 'admin.libro.libro.delete.confirm',
                                property: 'titulo'
                            };
                        }
                    }
                }
            ).result.then(function () {
                $state.go("admin/libro");
            });
        };

        // Históricos
        vm.openHistoricosModal = function () {
            ModalService.open({
                templateUrl: 'app/admin/libro/libro/historico.modal.html',
                controller: 'HistoricoModalController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    libroId: function () {
                        return vm.item ? vm.item.id : null;
                    }
                }
            });
        };

        // Comprobar ISBN
        vm.checkISBN = function () {
            if (LibroUtilService.checkISBN(vm.item.isbn)) {
                angular.element('#isbn').removeClass('ng-invalid');
            } else {
                angular.element('#isbn').addClass('ng-invalid');
            }
        };

        // Servicios
        vm.idiomaService = Idioma;
        vm.autorService = Autor;
        vm.valorSocialService = ValorSocial;
        vm.tipoInteligenciaService = TipoInteligencia;
        vm.materiaIbicService = MateriaIbic;

        initForm();
    }

})(angular);
