(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('verificar', {
                parent: 'public',
                url: '/verificar?key',
                views: {
                    'content@': {
                        templateUrl: 'app/public/cuenta/verificar/verificar.html',
                        controller: 'VerificarController',
                        controllerAs: 'ctrl'
                    }
                }
            });
    }
})();
