(function () {
    'use strict';

    angular.module('app')
        .component('estadisticasUsuarioList', {
            templateUrl: 'app/public/components/estadisticas/estadisticas.usuario.list.html',
            bindings: {
                estadisticas: '<',
                estadisticasLectura: '<',
                usuario: '<'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS, Estadisticas) {
        var vm = this;

        vm.item = Estadisticas;
        vm.function = "getEstadisticasAlumno";
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            centroEmail: vm.usuario ? vm.usuario.email : undefined
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {nombre: 'asc'}
        }, NgTableHelper.settings(vm));

    }
})();
