(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/packs', {
                parent: 'miespacio',
                url: '/packs-premium',
                redirectTo: 'miespacio/packs/catalogo',
                data: {
                    authorities: ['ROLE_CENTRO'],
                    titulo: 'catalogo.packs.title',
                    tituloImg: 'assets/images/iconos/packs_premium.png',
                    clase: 'packState'
                },
                views: {
                    'contentEspacio': {
                        templateUrl: 'app/public/cuenta/miespacio/centros/packs.html',
                        controllerAs: 'ctrl'
                    }
                }
            })
            .state('miespacio/packs/catalogo', {
                parent: 'miespacio/packs',
                url: '/catalogo',
                views: {
                    'contentPacks': {
                        template: '<packs packs="$resolve.packs" propios="false"></packs>',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    packs: function (Pack) {
                        return Pack.filter({sortProperty: 'nombre', propios: false}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            })
            .state('miespacio/packs/mispacks', {
                parent: 'miespacio/packs',
                url: '/mis-packs',
                views: {
                    'contentPacks': {
                        template: '<packs packs="$resolve.packs" propios="true"></packs>',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    packs: function (Pack) {
                        return Pack.filter({sortProperty: 'nombre', propios: true}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            });
    }
})();
