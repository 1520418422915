(function (angular) {
    'use strict';

    // Angular-Socialshare: https://github.com/720kb/angular-socialshare

    // URL: URL relativa a donde redirigirá la publicación.
    // mini: Booleano para saber si mostrar el botón de compartir como un btn-block o sólo como un icono
    // twitter, facebook: Botones que se van a mostrar
    // fbTitulo: Título de la publicación de Facebook
    // fbDescripcion: Descripción de la publicación de Facebook
    // fbImagen: Imagen de la publicación de Facebook (URL relativa)
    // tweet: Contenido del Tweet

    angular
        .module('app')
        .component('share', {
            bindings: {
                url: '@',
                mini: '<',
                twitter: '<',
                facebook: '<',
                fbTitulo: '@',
                fbDescripcion: '<',
                fbImagen: '@',
                tweet: '@',
                btnClass: '@'
            },
            templateUrl: 'app/components/share/share.html',
            /* @ngInject */
            controller: function ($scope, $location, $translate, RutasService, FacebookSdkService) {
                var vm = this;
                if (cookieConsent.isEnabledCookieType('third_party_cookies')) {
                    FacebookSdkService.config();

                    vm.titulo = $translate.instant("share.title") + vm.fbTitulo;

                    $scope.$watch(function () {
                        return vm.fbDescripcion;
                    }, function () {
                        if (vm.fbDescripcion) {
                            vm.descripcion = vm.fbDescripcion.replace(/<[^>]*>/g, '');
                        }
                    });

                    vm.imagen = vm.fbImagen;
                    if (!vm.imagen) {
                        vm.imagen = RutasService.getBaseUrl() + "/assets/images/logo_img.png";
                    } else {
                        vm.imagen = RutasService.getBaseUrl() + vm.imagen;
                    }

                    if (vm.url.indexOf("/") === 0) {
                        vm.fullUrl = RutasService.getBaseUrl() + vm.url;
                    } else {
                        vm.fullUrl = vm.url;
                    }

                    vm.compartirEnFacebook = function () {

                        var datos = {
                            'og:title': vm.titulo,
                            'og:url': vm.fullUrl
                        };
                        if (vm.descripcion) {
                            if (/<[a-z][\s\S]*>/i.test(vm.descripcion)) {
                                datos["og:description"] = jQuery(vm.descripcion).text();
                            } else {
                                datos["og:description"] = vm.descripcion;
                            }
                        }
                        // FIXME: Tamaño de la imagen. Se muestra sólo un trozo en caso de ser alargada.
                        if (vm.imagen) {
                            datos["og:image"] = vm.imagen;
                        }

                        FB.ui({
                            method: 'share_open_graph',
                            action_type: 'og.shares',
                            action_properties: JSON.stringify({
                                object: datos
                            })
                        }, function (response) {
                            console.log("Facebook response: ", response);
                        });
                    }
                }

                vm.isEnabledCookieType = function (ctype) {
                    return cookieConsent.isEnabledCookieType(ctype);
                }

            },
            controllerAs: 'ctrl'
        });

})(angular);
