(function () {
    'use strict';

    angular.module('app')
        .service('ModalService', ModalService);

    /* @ngInject */
    function ModalService($uibModal, AlertService) {
        var opened = false;
        var modal = null;

        return {
            opened: function () {
                return opened;
            },
            open: open,
            openImagen: openImagen,
            close: close,
            dismiss: dismiss
        };

        function open(params) {
            if (modal) {
                modal.close();
            }

            opened = true;
            modal = $uibModal.open(params);

            // En caso de que se pulse fuera de la modal, hacemos un dismiss
            modal.result.catch(function (data) {
                dismiss(data);
            });

            AlertService.clear();
            return modal;
        }

        function openImagen(path, params) {
            if (!params) params = {};

            return open(angular.extend({}, {
                template: '<a ng-click="ctrl.close()"><img src="' + path + '" /></a>',
                windowClass: 'imagen-modal',
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: function (ModalService) {
                    this.close = function () {
                        ModalService.close();
                    };
                }
            }, params));
        }

        function close(data) {
            opened = false;
            modal.close(data);
        }

        function dismiss(data) {
            opened = false;
            modal.dismiss(data);
        }
    }

})(angular);
