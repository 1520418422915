(function () {
    'use strict';

    angular
        .module('app')
        .component('carro', {
            templateUrl: 'app/public/components/carro/carro.html',
            controller: CarroController,
            controllerAs: 'ctrl',
            bindings: {
                carro: '=',
                edit: '<',
                tramitar: '='
            }
        });

    /* @ngInject */
    function CarroController($state, CarroHelper, Auth, Principal, Configuracion, AlertService, $q) {
        var vm = this;

        var descuento = 0;
        Principal.identity().then(function (data) {
            vm.esLibreria = data.type === 'libreria';

            if (vm.esLibreria) {
                Configuracion.get({id: 1}).$promise.then(function (data) {
                    descuento = data.descuentoLibrerias;
                    vm.calcularTotalLibreria();
                });
            }
        });

        vm.calcularTotal = function () {
            var total = 0.0;
            if (vm.carro) {
                vm.carro.productos.forEach(function (p) {
                    if (p.precioConIva) {
                        total += p.precioConIva;
                    }
                });
            }
            return total;
        };

        vm.calcularTotalLibreria = function () {
            // Precio que se le va a cobrar al usuario final en caso de que esté comprando una librería
            if (vm.esLibreria && descuento) {
                var total = 0.0;
                if (vm.carro) {
                    vm.carro.productos.forEach(function (p) {
                        if (p.libro) {
                            total += p.precioConIva * (1 - (descuento / 100));
                        }
                    });
                }
                return total;
            }
        };

        vm.calcularTotal();

        vm.removeFromCarro = function (producto) {
            CarroHelper.removeProducto(producto);
            vm.carro = CarroHelper.get();
            vm.calcularTotal();
            vm.calcularTotalLibreria();
        };

        vm.tramitarCompra = function () {
            $state.params.tramitar = true;

            Auth.authorize(false, "ROLE_AUTHENTICATED");

            validar().then(function (valido) {
                if (valido) {
                    $state.go('compra/crear');
                }
            });
        };

        function validar() {
            return $q(function (resolve) {
                if (Principal.isAuthenticated()) {
                    CarroHelper.validate().then(function (valid) {
                        if (!valid) {
                            vm.invalid = true;
                            AlertService.warning("compra.carro.invalido", {}, 0);
                            vm.carro = CarroHelper.get();
                        }

                        resolve(valid);
                    });
                }
            })
        }

        if (vm.tramitar) {
            // Se intenta validar y proceder a la compra si venimos del login
            vm.tramitarCompra();
        } else if (vm.edit) {
            // Cada vez que se accede al carro se validan las lineas (cuando el carro no es de solo lectura)
            validar();
        }
    }

})();
