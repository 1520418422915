(function () {
    'use strict';

    // Tarea: Tarea a la que pertenece el examen.
    // Ejecución del examen: Ejecución ya existen del examen, o una nueva en caso de estar iniciando el examen.
    // Preguntas: Lista de preguntas que se mostrarán.

    angular
        .module('app')
        .component('examenForm', {
            templateUrl: 'app/public/components/proyectos/examen/examen.form.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tarea: '<',
                ejecucion: '<',
                preguntas: '<'
            }
        });

    /* @ngInject */
    function Controller($state, Ejecucion, $timeout, ExamenHelper) {
        var vm = this;

        vm.item = vm.ejecucion;
        vm.mostrarIndice = false;

        var alarmPromise;

        function initForm() {
            if (!vm.item.fechaInicio) {
                vm.item.fechaInicio = new Date();
            }

            if (!vm.item.respuestas) {
                vm.item.respuestas = [];
            }

            vm.totalPreguntas = vm.preguntas.length;

            // Desordenamos las preguntas
            vm.preguntas = _.shuffle(vm.preguntas);

            // Ponemos de últimas las preguntas de respuesta corta
            vm.preguntas.sort(function (a, b) {
                if (a.tipo === 'ABIERTA') return 1;
                if (b.tipo === 'ABIERTA') return -1;
                return 0;
            });

            // Inicializamos las respuestas asignándolas a las preguntas
            angular.forEach(vm.preguntas, function (pregunta) {
                var respuesta = vm.item.respuestas.find(function (respuesta) {
                    return respuesta.pregunta.id === pregunta.id;
                });
                if (respuesta) {
                    pregunta.respuesta = respuesta;
                }

                if (pregunta.tipo === 'TEST') {
                    pregunta.respuestas = _.shuffle(pregunta.respuestas);
                }
            });
        }

        function initAlarmaFin() {
            // milisegundos
            var duracion = vm.ejecucion.examen.duracion * 60 * 1000;
            var fin = (vm.ejecucion.examen.fechaInicio * 1000) + duracion;
            var alarm = fin - new Date();
            console.log("La evaluación finalizará en: ", alarm / 1000 + " segundos");

            alarmPromise = $timeout(function () {
                // FIXME: Si el usuario está escribiendo la respuesta de la pregunta corta, no se pierde el foco por lo que no se llama a responderCorta, y se pierde lo que haya escrito
                // Añadiendo el blur se hace la petición para guardar la pregunta, pero al mismo tiempo la de finalizar, y se guarda dos veces.
                // $(':focus').blur();
                vm.save(true);
            }, alarm);
        }

        vm.numPreguntasRespondidas = function () {
            // FIXME: Optimizar. Se realizan muchas llamadas a este método
            return vm.preguntas.filter(function (pregunta) {
                return pregunta.respuesta && (pregunta.respuesta.respuestaCorta || pregunta.respuesta.respuestaTest);
            }).length;
        };

        function responder(respuesta, pregunta) {
            angular.extend(respuesta, {
                pregunta: {
                    id: pregunta.id,
                    tipo: pregunta.tipo
                }
            }, {ejecucionId: vm.item.id});
            Ejecucion.responder(respuesta).$promise.then(function (data) {
                angular.merge(pregunta.respuesta, data);
            });
        }

        vm.responderTest = function (respuesta, respuestaTest, pregunta) {
            if (!respuesta) {
                respuesta = {respuestaTest: respuestaTest};
            }
            responder(respuesta, pregunta)
        };

        vm.responderCorta = function (respuesta, pregunta) {
            if (respuesta.respuestaCorta !== undefined) {
                responder(respuesta, pregunta);
            }
        };

        function normalizarRespuesta(pregunta, respuesta) {
            if (respuesta) {
                var tipo = 'ABIERTA';
                if (respuesta.respuestaTest) {
                    tipo = 'TEST';
                }
                respuesta.pregunta = {id: pregunta.id, tipo: tipo};
            }
            return respuesta;
        }

        // Guardar formulario
        vm.save = function (timeout) {
            $timeout.cancel(alarmPromise);

            var respuestas = [];
            angular.forEach(vm.preguntas, function (pregunta) {
                respuestas.push(normalizarRespuesta(pregunta, pregunta.respuesta));
            });
            vm.item.respuestas = respuestas;

            Ejecucion.finalizar(vm.item).$promise.then(function () {
                $state.go("miespacio/tarea/details", {proyectoId: vm.tarea.proyecto.id, id: vm.tarea.id});
                ExamenHelper.finalizar(timeout);
            });
        };

        initForm();
        initAlarmaFin();
    }

})();
