(function () {
    'use strict';

    angular.module('app')
        .factory('UtilService', UtilService);

    function UtilService($http) {
        return {
            forceFinDeCurso: forceFinDeCurso,
            resetearContrasenas: resetearContrasenas
        };

        function forceFinDeCurso() {
            return $http({
                method: 'GET',
                url: 'api/admin/fin-curso',
                isArray: false
            });
        }

        function resetearContrasenas() {
            return $http({
                method: 'GET',
                url: 'api/admin/resetear-contrasenas',
                isArray: false
            });
        }

    }
})();
