(function () {
    'use strict';

    angular.module('app')
        .factory('CategoriaRecurso', CategoriaRecurso);

    /* @ngInject */
    function CategoriaRecurso(ResourceHelper) {
        return ResourceHelper.getResource("categoria-recurso");
    }

})();
