(function () {
    'use strict';

    angular
        .module('app')
        .factory('versionInterceptor', versionInterceptor);

    versionInterceptor.$inject = ['$localStorage', '$sessionStorage', 'APP_VERSION'];

    function versionInterceptor($localStorage, $sessionStorage, APP_VERSION) {
        return {
            request: request
        };

        function request(config) {
            if (config.url.endsWith(".json") > 0) {
                config.params = config.params || {};
                config.params.version = APP_VERSION;
            }
            return config;
        }
    }
})();
