(function () {
    'use strict';

    angular.module('app')
        .controller('AdminFormController', AdminFormController);

    /* @ngInject */
    function AdminFormController($timeout, $state, itemData, roles, previousParams) {
        var vm = this;

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        vm.canEdit = true;
        vm.roles = roles;

        $timeout(function () {
            angular.element('#email').focus();
        });

        vm.register = function () {
            vm.item.$save().then(function () {
                $state.go('admin/admin/list', previousParams);
            });
        };
    }
})();
