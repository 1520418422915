(function () {
    'use strict';

    /**
     * ta = título abreviado (código único del libro)
     */

    angular.module('app')
        .factory('OfflineService', OfflineService);

    /* @ngInject */
    function OfflineService(BibliotecaService, RutasService, $http, $localStorage) {

        BibliotecaService.init('biblioteca', 'libros');

        return {
            getLibrosOff: getLibrosOff,
            getLibroOff: getLibroOff,
            getPosicionOff: getPosicionOff,
            existeLibroOff: existeLibroOff,
            existePdfOff: existePdfOff,
            existeEpubOff: existeEpubOff,
            gardarLibrosOff: gardarLibrosOff,
            actualizarUltimaLecturaLibro: actualizarUltimaLecturaLibro,
            actualizarPorcentaxeLibro: actualizarPorcentaxeLibro,
            gardarComponenteOff: gardarComponenteOff,
            borrarComponentesOff: borrarComponentesOff,
            getComponenteOff: getComponenteOff,
            recuperarPreferenciasOff: recuperarPreferenciasOff,
            gardarPreferenciasOff: gardarPreferenciasOff,
            actualizarAvancePdf: actualizarAvancePdf,
            actualizarAvanceEpub: actualizarAvanceEpub,
            recuperarAvance: recuperarAvance,
            descargarComponentesPdf: descargarComponentesPdf,
            descargarComponentesEpub: descargarComponentesEpub,
            gardarMetadatosPdf: gardarMetadatosPdf,
            gardarMetadatosEpub: gardarMetadatosEpub,
            actualizarMisLibros: actualizarMisLibros,
            getLibroOffMisLibros: getLibroOffMisLibros
        };

        function getLibrosOff() {
            try {
                var biblioteca = localStorage.getItem("biblioteca");
                var libros = JSON.parse(biblioteca);
                if (libros == null) {
                    throw "null";
                }
                return libros;
            } catch (ex) {
                return [];
            }
        }

        function getLibroOff(ta) {
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    return libros[i];
                }
            }
        }

        function getPosicionOff(ta) {
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    return {
                        capitulo: libros[i].capitulo,
                        porcentaxeCapitulo: libros[i].porcentaxeCapitulo,
                        porcentaxe: libros[i].porcentaxe,
                        ultimaLectura: libros[i].ultimaLectura
                    };
                }
            }
        }

        function existeLibroOff(ta) {
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    return true;
                }
            }
            return false;
        }

        function existePdfOff(ta) {
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if ((libros[i].tituloAbreviado === ta) && (libros[i].pdfDescargado)) {
                    return true;
                }
            }
            return false;
        }

        function existeEpubOff(ta) {
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if ((libros[i].tituloAbreviado === ta) && (libros[i].epubDescargado)) {
                    return true;
                }
            }
            return false;
        }

        function gardarLibrosOff(libros) {
            localStorage.setItem("biblioteca", JSON.stringify(libros));
        }

        function actualizarUltimaLecturaLibro(ta, d) {
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    libros[i].ultimaLectura = d;
                }
            }
            gardarLibrosOff(libros);
        }

        function actualizarPorcentaxeLibro(ta, p, pc, ci) {
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    libros[i].porcentaxe = p;
                    libros[i].porcentaxeCapitulo = pc;
                    libros[i].capitulo = ci;
                }
            }
            gardarLibrosOff(libros);
        }

        function getClave(ta, ficheiro) {
            return ta + "_" + ficheiro;
        }

        function gardarComponenteOff(data, ta, ficheiro, tipo, onsuccess, onerror) {
            var clave = getClave(ta, ficheiro);

            try {
                BibliotecaService.abrir((function () {
                    var fileReader = new FileReader();
                    fileReader.onload = function (evt) {
                        var result = evt.target.result;
                        BibliotecaService.gardar(clave, result, onsuccess, onerror);
                    };
                    fileReader.readAsDataURL(data);

                }).bind(this));
            } catch (e) {
                try {
                    var fileReader = new FileReader();
                    fileReader.onload = function (evt) {
                        try {
                            var result = evt.target.result;
                            localStorage.setItem(clave, result);

                            if (onsuccess)
                                onsuccess();
                        } catch (e) {
                            if (onerror)
                                onerror();
                        }
                    };
                    fileReader.readAsDataURL(data);
                } catch (e) {
                    console.log("LocalStorage error: " + e);

                    if (onerror)
                        onerror();

                    throw e;
                }
            }
        }

        function borrarComponentesOff(ta, onsuccess) {
            var oncomplete = (function () {
                // eliminar da biblioteca
                var biblioteca = localStorage.getItem("biblioteca");
                var libros = JSON.parse(biblioteca);

                for (var i = libros.length - 1; i >= 0; i--) {
                    if (libros[i].tituloAbreviado == ta) {
                        libros.splice(i, 1);
                        break;
                    }
                }

                localStorage.setItem("biblioteca", JSON.stringify(libros));

                if (onsuccess)
                    onsuccess();
            }).bind(this);

            try {
                BibliotecaService.abrir((function () {
                    BibliotecaService.eliminarVarios((function (key) {
                        return (key.slice(0, ta.length + 1) == ta + "_");
                    }).bind(this), null, oncomplete, ta);
                }).bind(this));
            } catch (e) {
                Object.keys(localStorage).forEach(function (key) {
                    if (key.slice(0, ta.length + 1) == ta + "_") {
                        localStorage.removeItem(key);
                    }
                });
                oncomplete();
            }
        }

        function getComponenteOff(ta, ficheiro, onsuccess, onerror) {
            console.log("getComponenteOff");
            var clave = getClave(ta, ficheiro);
            var _onsuccess = (function (contido) {
                if (contido != null) {
                    try {
                        if (contido.data)
                            contido = $.parseXML(contido.data);
                        else
                            contido = $.parseXML(contido);
                    } catch (e) {	// invalid XML
                        if (contido.data)
                            contido = contido.data;
                    }
                }

                if (onsuccess) {
                    onsuccess(contido);
                }
            }).bind(this);

            try {
                BibliotecaService.abrir((function () {
                    BibliotecaService.obter(clave, _onsuccess, onerror);
                }).bind(this));
            } catch (e) {
                _onsuccess(localStorage.getItem(clave));
            }
        }

        function gardarPreferenciasOff(preferenciasActualizadas) {
            localStorage.setItem("preferencias", JSON.stringify(preferenciasActualizadas));
        }

        function recuperarPreferenciasOff() {
            try {
                var preferencias = localStorage.getItem("preferencias");
                return JSON.parse(preferencias);
            } catch (ex) {
                return null;
            }
        }

        function actualizarAvancePdf(ta, paginaPdf, tiempoPdf, arrayPdfId, arrayPdfPaginaAnterior, guardadoEnBd) {
            console.log("actualizarAvancePdf");
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    libros[i].paginaPdf = paginaPdf;
                    libros[i].tiempoPdf = tiempoPdf;
                    libros[i].arrayPdfId = arrayPdfId;
                    libros[i].arrayPdfPaginaAnterior = arrayPdfPaginaAnterior;
                    libros[i].pdfDescargado = true;
                    libros[i].guardadoEnBd = guardadoEnBd;
                }
            }
            gardarLibrosOff(libros);
        }

        function actualizarAvanceEpub(ta, cfiEpub, tiempoEpub, palabrasEpub, guardadoEnBdEpub) {
            console.log("actualizarAvanceEpub");
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    libros[i].cfiEpub = cfiEpub;
                    libros[i].tiempoEpub = tiempoEpub;
                    libros[i].palabrasEpub = palabrasEpub;
                    libros[i].epubDescargado = true;
                    libros[i].guardadoEnBdEpub = guardadoEnBdEpub != null ? guardadoEnBdEpub : libros[i].guardadoEnBdEpub;
                }
            }
            gardarLibrosOff(libros);
        }

        function recuperarAvance(ta) {
            console.log("recuperarAvance");
            var libros = getLibrosOff();
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].tituloAbreviado === ta) {
                    return libros[i];
                }
            }
        }

        function gardarMetadatosPdf(libro) {
            var libros = getLibrosOff();
            libros.push({tituloAbreviado: libro.tituloAbrev, pdfDescargado: true});
            gardarLibrosOff(libros);
        }

        function gardarMetadatosEpub(libro) {
            var libros = getLibrosOff();
            libros.push({tituloAbreviado: libro.tituloAbrev, epubDescargado: true});
            gardarLibrosOff(libros);
        }

        function descargarComponentesPdf(adquirido, callback) {
            var libro = adquirido.libro;
            var PDF_SOURCE = null;
            var ruta = RutasService.getPdf(libro.id, libro.pdf.archivo, false);
            var ficheiro = libro.pdf.archivo;
            var idLibroStr = ruta.toString().split("/");

            $http({
                method: 'GET',
                url: 'api/public/pdf/descargar',
                params: {
                    id: Number(idLibroStr[3]),
                    ruta: ruta
                },
                responseType: "arraybuffer"
            }).then(function (result) {
                var file = new Blob([result.data], {type: 'application/pdf'});
                gardarComponenteOff(file, libro.tituloAbrev, ficheiro, 'PDF', (function () {
                    getComponenteOff(libro.tituloAbrev, ficheiro, (function (data) {
                        PDF_SOURCE = data;
                        if (!existePdfOff(libro.tituloAbrev)) {
                            console.log("No existe el libro -> Se guarda el del metodo PDF");
                            gardarMetadatosPdf(libro);
                            actualizarLibro(adquirido);
                        }
                        callback();
                    }).bind(this));
                }).bind(this), (function () {
                    var fileReader = new FileReader();
                    fileReader.onload = function (evt) {
                        PDF_SOURCE = evt.target.result;
                        PDFViewerApplication.onload = (function () {
                            $("#viewerLoading").hide();
                            $("#viewer").show();
                        }).bind(this);
                        callback();
                    };
                    fileReader.readAsDataURL(file);
                }).bind(this));
            });
        }

        function descargarComponentesEpub(adquirido) {
            var libro = adquirido.libro;
            var EPUB_SOURCE = null;
            var ruta = RutasService.getEpub(libro.id, libro.epub.archivo, false);
            var ficheiro = libro.epub.archivo;
            var idLibroStr = ruta.toString().split("/");

            $http({
                method: 'GET',
                url: 'api/public/epub/descargar',
                params: {
                    id: Number(idLibroStr[3]),
                    ruta: ruta
                },
                responseType: "arraybuffer"
            }).then(function (result) {
                var file = new Blob([result.data], {type: 'application/epub+zip'});
                gardarComponenteOff(file, libro.tituloAbrev, ficheiro, 'EPUB', (function () {
                    getComponenteOff(libro.tituloAbrev, ficheiro, (function (data) {
                        EPUB_SOURCE = data;

                        if (!existeEpubOff(libro.tituloAbrev)) {
                            console.log("No existe el libro -> Se guarda el del metodo Epub");
                            gardarMetadatosEpub(libro);
                            actualizarLibro(adquirido);
                        }

                    }).bind(this));
                }).bind(this), (function () {
                    var fileReader = new FileReader();
                    fileReader.onload = function (evt) {
                        EPUB_SOURCE = evt.target.result;
                    };
                    fileReader.readAsDataURL(file);
                }).bind(this));
            });
        }

        function getLibroOffMisLibros(ta) {
            var libros = $localStorage.librosAdquiridos;
            if (typeof libros == 'undefined') {
                return null;
            }
            for (var i = 0; i < libros.length; i++) {
                if (libros[i].libro.tituloAbrev === ta) {
                    return libros[i];
                }
            }
        }

        function imagenToBase64(url, i, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(i, reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        // Método para eliminar las propiedades que no interesen tener en localStorage
        function limpiarLibroAdquirido(adquirido) {
            delete(adquirido.usuario);
            delete(adquirido.libro.autores);
            delete(adquirido.libro.coleccion);
            delete(adquirido.libro.fechaCreacion);
            delete(adquirido.libro.fechaUltimaModificacion);
            delete(adquirido.libro.genero);
            delete(adquirido.libro.grupoEdad);
            delete(adquirido.libro.idiomas);
            delete(adquirido.libro.iva);
            delete(adquirido.libro.uuid);
            delete(adquirido.libro.responsable);
            delete(adquirido.libro.resumen);
            delete(adquirido.libro.tiposInteligencia);
            delete(adquirido.libro.url);
            delete(adquirido.libro.valoresEducativos);
            delete(adquirido.libro.visitas);
        }

        function actualizarLibro(libroAdquiridoBD) {
            if (!$localStorage.librosAdquiridos) {
                $localStorage.librosAdquiridos = [];
            }
            limpiarLibroAdquirido(libroAdquiridoBD);

            // Si el pdf o el epub esta descargado
            if (existePdfOff(libroAdquiridoBD.libro.tituloAbrev) || existeEpubOff(libroAdquiridoBD.libro.tituloAbrev)) {
                var libroAdquirido = getLibroOffMisLibros(libroAdquiridoBD.libro.tituloAbrev);
                var rutaPortada = RutasService.getPortada(libroAdquiridoBD.libro.id, libroAdquiridoBD.libro.portada, false);
                // Si encuentra el libro adquirido en localstorage
                if (typeof libroAdquirido != 'undefined' && libroAdquirido != null) {
                    // Si el libro adquirido tiene portada y es distinta a la almacenada en offline -> se actualiza
                    if (libroAdquiridoBD.libro.portada != null && libroAdquirido.libro.portada !== libroAdquiridoBD.libro.portada) {
                        console.log("Portada:" + libroAdquiridoBD.libro.portada);
                        imagenToBase64(rutaPortada, i, function (i, base64Img) {
                            libroAdquiridoBD.libro.portadaBase64 = base64Img;
                            for (var j = 0; j < $localStorage.librosAdquiridos.length; j++) {
                                if ($localStorage.librosAdquiridos[j].libro.id === libroAdquiridoBD.libro.id) {
                                    angular.merge($localStorage.librosAdquiridos[j], libroAdquiridoBD);
                                }
                            }
                        });
                        // Si el libro adquirido no tiene portada o es la misma que en offline -> se actualiza la info del libro adquirido
                    } else {
                        for (var j = 0; j < $localStorage.librosAdquiridos.length; j++) {
                            if ($localStorage.librosAdquiridos[j].libro.id === libroAdquiridoBD.libro.id) {
                                angular.merge($localStorage.librosAdquiridos[j], libroAdquiridoBD);
                            }
                        }
                    }
                    // Si no encuentra el libro adquirido en localstorage
                } else {
                    // Si el libro adquirido tiene portada -> Se almacena
                    if (libroAdquiridoBD.libro.portada != null) {
                        console.log("Portada:" + libroAdquiridoBD.libro.portada);
                        imagenToBase64(rutaPortada, i, function (i, base64Img) {
                            libroAdquiridoBD.libro.portadaBase64 = base64Img;
                            $localStorage.librosAdquiridos.push(libroAdquiridoBD);
                        });
                        // Si el libro adquiridono tiene portada -> se almacena
                    } else if (getLibroOff(libroAdquiridoBD.libro.tituloAbrev) != null) {
                        $localStorage.librosAdquiridos.push(libroAdquiridoBD);
                    }
                }
            }
        }

        function actualizarMisLibros(data) {
            if (typeof $localStorage.librosAdquiridos == 'undefined') {
                $localStorage.librosAdquiridos = [];
            }
            for (var i = 0, len = data.length; i < len; i++) {
                actualizarLibro(data[i]);
            }
            return data;
        }

    }

})();
