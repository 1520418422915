(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('CategoriaRecursoFormAdminController', CategoriaRecursoFormAdminController);

    /* @ngInject */
    function CategoriaRecursoFormAdminController($timeout, $state, itemData, previousParams) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                $state.go('admin/categoriarecurso/list', previousParams);
            });
        };
    }

})(angular);
