(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PromocionAdminController', Controller);

    /* @ngInject */
    function Controller($timeout, $stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                        ModalService, Promocion) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = Promocion;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {'p.fechaCreacion': 'desc'}
        }, NgTableHelper.settings(vm));

    }

})(angular);
