(function () {
    'use strict';

    angular
        .module('app')
        .config(alertServiceConfig);

    alertServiceConfig.$inject = ['AlertServiceProvider', 'MediaServiceProvider'];

    function alertServiceConfig(AlertServiceProvider, MediaServiceProvider) {
        // set below to true to make alerts look like toast
        AlertServiceProvider.showAsToast(MediaServiceProvider.$get().esMovil());
    }
})();
