(function () {
    'use strict';
    angular.module('app')
        .component('estadisticasGrafica', {
            templateUrl: 'app/public/components/estadisticas/estadisticas.grafica.html',
            bindings: {
                estadisticas: '<',
                datePickersDisabled: '<',
                fechaInicioInput: '<',
                fechaFinInput: '<'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
                        Estadisticas, $translate, EstadisticasUtil) {
        var vm = this;
        vm.tipoFecha = "mes";
        var diasMaximosEntreFechas = 61;
        var rellenadoDataset = true;
        var lineaTension = 0;

        // *** Gráfica

        this.$onChanges = function (changes) {
            if (this.datePickersDisabled && changes.fechaInicioInput) {
                vm.updateFechaInicio(this.fechaInicioInput);
            }

            if (this.datePickersDisabled && changes.fechaFinInput) {
                vm.updateFechaFin(this.fechaFinInput);
            }
        };

        function diasEntreFechas(inicio, fin, desdeOtraFuncion) {
            if (inicio && fin && Math.ceil(Math.abs(fin.getTime() - inicio.getTime()) / (1000 * 3600 * 24)) >= diasMaximosEntreFechas) {
                angular.element('#radioDias').addClass('disabled').css('pointer-events', 'none');
                if (vm.tipoFecha === "dia") {
                    vm.tipoFecha = "mes";
                    if (!desdeOtraFuncion) {
                        vm.updateTipoFecha(vm.fechaInicio, vm.fechaFin);
                    }
                }
            } else {
                angular.element('#radioDias').removeClass('disabled').css('pointer-events', 'all');
            }
        }

        vm.updateFechaInicio = function (fechaInicio) {
            vm.fechaInicio = fechaInicio;
            diasEntreFechas(vm.fechaInicio, vm.fechaFin, true);
            myChart.options.scales.xAxes[0].time.min = vm.fechaInicio;
            vm.updateTipoFecha(vm.fechaInicio, vm.fechaFin);
        };

        vm.updateFechaFin = function (fechaFin) {
            vm.fechaFin = fechaFin;
            diasEntreFechas(vm.fechaInicio, vm.fechaFin, true);
            myChart.options.scales.xAxes[0].time.max = vm.fechaFin;
            vm.updateTipoFecha(vm.fechaInicio, vm.fechaFin);
        };

        vm.updateTipoFecha = function (fechaInicio, fechaFin) {
            EstadisticasUtil.updateFechas(fechaInicio, fechaFin, myChart, vm.estadisticas, vm.tipoFecha);
        };

        var myChart = new Chart(angular.element("#graficaEstadisticas"), {
            type: 'line',
            data: {
                labels: EstadisticasUtil.getEstadisticas(vm.estadisticas, "fecha"),
                datasets: [{
                    label: $translate.instant("estadisticas.grafica.tiempo"),
                    data: EstadisticasUtil.getEstadisticas(vm.estadisticas, "tiempo"),
                    fill: rellenadoDataset,
                    borderColor: 'rgba(255, 0, 4, 1)',
                    pointBackgroundColor: 'rgba(255, 0, 4, 1)',
                    backgroundColor: 'rgba(255, 0, 4, 0.1)',
                    borderWidth: 1,
                    lineTension: lineaTension
                }, {
                    label: $translate.instant("estadisticas.grafica.palabras"),
                    data: EstadisticasUtil.getEstadisticas(vm.estadisticas, "palabras"),
                    fill: rellenadoDataset,
                    borderColor: 'rgba(0, 0, 255, 1)',
                    pointBackgroundColor: 'rgba(0, 0, 255, 1)',
                    backgroundColor: 'rgba(0, 0, 255, 0.1)',
                    borderWidth: 1,
                    lineTension: lineaTension,
                    hidden: !this.datePickersDisabled
                }, {
                    label: $translate.instant("estadisticas.grafica.velocidad"),
                    data: EstadisticasUtil.getEstadisticas(vm.estadisticas, "velocidad"),
                    fill: rellenadoDataset,
                    borderColor: 'rgba(0, 255, 0, 1)',
                    pointBackgroundColor: 'rgba(0, 255, 0, 1)',
                    backgroundColor: 'rgba(0, 255, 0, 0.1)',
                    borderWidth: 1,
                    lineTension: lineaTension,
                    hidden: !this.datePickersDisabled
                }]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'month',
                            min: vm.fechaInicio,
                            max: vm.fechaFin,
                            displayFormats: {
                                'day': 'DD-MM',
                                'month': 'MM-YYYY',
                                'year': 'YYYY'
                            }
                        }
                    }]
                }
            }
        });

        if (vm.estadisticas.length) {
            vm.minFechaFiltrable = vm.fechaInicio;  //Variable para acotar el filtro por fecha

            if (this.datePickersDisabled) {
                vm.fechaInicio = this.fechaInicioInput;
                vm.fechaFin = this.fechaFinInput;
            } else {
                vm.minFechaFiltrable = new Date(vm.estadisticas[vm.estadisticas.length - 1].fecha * 1000);

                vm.fechaFin = new Date(Date.now());  //Fecha actual

                if (vm.fechaFin.getMonth() < 8) {    //Se calcula el inicio de curso a partir de la fecha actural
                    vm.fechaInicio = new Date(vm.fechaFin.getFullYear() - 1, 8);
                } else {
                    vm.fechaInicio = new Date(vm.fechaFin.getFullYear(), 8);
                }

                if (vm.minFechaFiltrable > vm.fechaInicio)
                    vm.minFechaFiltrable = vm.fechaInicio;

                vm.updateTipoFecha(vm.fechaInicio, vm.fechaFin);
            }
            diasEntreFechas(vm.fechaInicio, vm.fechaFin, false);
        }

    }
})();
