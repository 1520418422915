(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/info', {
                parent: 'public',
                url: '/mi-espacio',
                data: {
                    pageTitle: 'global.menu.miespacio.title'
                },
                views: {
                    'content@': {
                        /* @ngInject */
                        controller: function ($state, Principal) {
                            if (Principal.isAuthenticated() || !navigator.onLine) {
                                // Se redirige siempre a MisLibros y ahí se hace la redirección (mis-libros.state.js)
                                $state.go('miespacio');
                            } else {
                                $state.go('registro/info');
                            }
                        },
                        controllerAs: 'ctrl'
                    }
                }
            })
            .state('miespacio', {
                parent: 'public',
                redirectTo: 'miespacio/mislibros',
                url: '/mi-espacio',
                data: {
                    authorities: ['ROLE_AUTHENTICATED'],
                    pageTitle: 'global.menu.miespacio.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/public/cuenta/miespacio/mi-espacio.template.html',
                        /* @ngInject */
                        controller: function ($window, $timeout, $state, menuData, Principal) {
                            var vm = this;
                            vm.menu = menuData;
                            vm.navCollapsed = $window.innerWidth < 992;

                            vm.estado = $state;

                            $timeout(function () {
                                Principal.identity().then(function (data) {
                                    // Si es un profesor no activado, eliminamos la sección Mis alumnos
                                    if (data && data.rol === 'ROLE_PROFESOR' && (data.centro == null || !data.activado)) {
                                        // TODO: Optimizar este borrado. Se nota en la vista que desaparece esta sección
                                        angular.element(".menu.lateral [ui-sref='miespacio/misalumnos']").parent().remove();
                                    }
                                });
                            }, 500);
                        },
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    menuData: function (Menu) {
                        return Menu.getMenu('menu-miespacio');
                    }
                }
            });
    }
})();
