(function () {
    'use strict';

    angular
        .module('app')
        .component('login', {
            templateUrl: 'app/public/components/login/login.html',
            controller: LoginController,
            bindings: {
                libro: '<',
                foco: '<'
            }
        });

    /* @ngInject */
    function LoginController($state, $timeout, Auth, AlertService, CarroHelper, ADMIN_PRINCIPAL) {
        var vm = this;

        vm.user = {};
        vm.showPassword = false;
        vm.rememberMe = false;

        if (vm.foco) {
            $timeout(function () {
                angular.element('.form-group>input#username').focus();
            });
        }

        vm.login = function () {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function (data) {
                vm.authenticationError = false;
                if (data.rol === 'ROLE_ADMIN' || data.rol === 'ROLE_EDITOR_CONTENIDO') {
                    $state.go(ADMIN_PRINCIPAL, {}, {reload: true});
                } else {
                    // Sólo se sincroniza el carro cuando el usuario se autentica
                    CarroHelper.sync().then(function () {
                        $state.go('miespacio', {}, {reload: true});
                    });
                }
            }).catch(function () {
                AlertService.error("login.form.error.authentication");
            });
        };
    }

})();
