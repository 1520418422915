(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('CatalogoController', CatalogoController);

    /* @ngInject */
    function CatalogoController($scope, $window, ALFABETO, $stateParams, MediaService, $anchorScroll, $timeout, $location) {
        var vm = this;

        vm.letras = ALFABETO;
        vm.isCollapsed = false;

        // TODO: Mostrar de alguna manera el filtro activo... Tal vez pueda devolverse un objeto desde el servidor cuando se hace la operación de consulta.
        vm.valorBusqueda = $stateParams.valor;

        var searchParams = $location.search();

        // En la vista móvil, al hacer una búsqueda se hace scroll a los libros
        // También al clicar en el link "Todos"
        if (MediaService.esMovil() && searchParams.scroll){
            $timeout(function(){
                $anchorScroll('menu-catalogo');
            }, 100);
        }

        function resize() {
            vm.isCollapsed = MediaService.esMovil();
        }

        angular.element($window).on('resize', function () {
            resize();
            $scope.$apply();
        });

        resize();
    }

})(angular);
