(function () {
    'use strict';

    angular
        .module('app')
        .component('invitaciones', {
            templateUrl: 'app/public/components/clubes/invitaciones/invitaciones.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                clubId: '@'
            }
        });

    /* @ngInject */
    function Controller($stateParams, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper, Invitacion, Usuario) {
        var vm = this;

        vm.item = Invitacion;
        vm.function = 'findByClub';
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS_PUBLIC.size;
        vm.filter = {
            key: undefined,
            id: vm.clubId
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {'destinatario': 'asc'}
        }, NgTableHelper.settings(vm));

        vm.eliminarInvitacion = function (invitacion) {
            Invitacion.delete(invitacion).$promise.then(function () {
                vm.tableParams.reload();
            });
        };

        // Formulario

        function invitar(destinatario, esLogin) {
            Invitacion.invitar({clubId: vm.clubId, destinatario: destinatario}).$promise.then(function () {
                if (esLogin) {
                    vm.destinatarioLogin = null;
                } else {
                    vm.destinatarioEmail = null;
                }
                vm.tableParams.reload();
            });
        }

        vm.invitarLogin = function () {
            if (vm.destinatarioLogin) {
                invitar(vm.destinatarioLogin.login, true);
            }
        };

        vm.invitarEmail = function () {
            if (vm.destinatarioEmail) {
                invitar(vm.destinatarioEmail, false);
            }
        };

        vm.usuarioService = Usuario;

    }
})();
