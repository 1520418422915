(function () {
    'use strict';

    angular.module('app')
        .factory('Categoria', Categoria);

    /* @ngInject */
    function Categoria(ResourceHelper) {
        return ResourceHelper.getResource("categoria");
    }

})();
