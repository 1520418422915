(function () {
    'use strict';

    angular.module('app')
        .factory('Libro', Libro);

    /* @ngInject */
    function Libro(ResourceHelper) {
        return ResourceHelper.getResource("libro", {}, {
            'checkEliminable': {
                method: 'GET',
                url: 'api/admin/libro/checkEliminable',
                transformResponse: function (data) {
                    return {data: data};
                }
            },
            'getLight': {
                method: 'GET',
                url: 'api/public/libro/getLight'
            },
            'findByUrl': {
                method: 'GET',
                url: 'api/public/libro/findByUrl',
                params: {
                    tituloAbrev: '@tituloAbrev'
                },
                isArray: false
            },
            'findByCriterio': {
                method: 'GET',
                url: 'api/public/libro/findByCriterio',
                params: {
                    criterio: '@criterio',
                    value: '@value'
                },
                isArray: false
            },
            'filter': {
                method: 'GET',
                url: 'api/public/libro/filter',
                isArray: false
            },
            'findConEstado': {
                method: 'GET',
                url: 'api/public/libro/findConEstado',
                isArray: false
            },
            'findLibrosConEstado': {
                method: 'GET',
                url: 'api/public/libro/findLibrosConEstado',
                isArray: true
            },
            'findRelacionados': {
                method: 'GET',
                url: 'api/public/libro/findRelacionados',
                isArray: true
            },
            'findMasLeidos': {
                method: 'GET',
                url: 'api/public/libro/findMasLeidos',
                isArray: true
            },
            'findMasComentados': {
                method: 'GET',
                url: 'api/public/libro/findMasComentados',
                isArray: true
            },
            'findProximamente': {
                method: 'GET',
                url: 'api/public/libro/findProximamente',
                isArray: true
            },
            'findNovedades': {
                method: 'GET',
                url: 'api/public/libro/findNovedades',
                isArray: false
            },
            'findAllForPack': {
                method: 'GET',
                url: 'api/public/libro/findAllForPack'
            },
            'findAllForPackPersonalizado': {
                method: 'GET',
                url: 'api/public/libro/findAllForPackPersonalizado'
            },
            'findBiblioteca': {
                method: 'GET',
                url: 'api/public/libro/findBiblioteca',
                isArray: true
            },
            'updateCountMuestra': {
                method: 'POST',
                url: 'api/public/libro/updateContadorMuestra',
                params: {
                    idLibro: '@idLibro'
                }
            }
        });
    }

})();
