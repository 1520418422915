(function () {
    'use strict';

    angular.module('app')
        .service('MediaService', MediaService);

    /* @ngInject */
    function MediaService($window) {
        this.esMovil = function(){
            return $window.innerWidth < 1170;
        };
    }
})();
