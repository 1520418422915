(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('SlideFormAdminController', SlideFormAdminController);

    /* @ngInject */
    function SlideFormAdminController($timeout, $state, itemData, previousParams) {
        var vm = this;

        vm.urlRegex = /[0-9]/;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        initImagen();
        // Guardar formulario
        vm.save = function () {
            function onSave() {
                $state.go('admin/slider', previousParams);
            }
            if (vm.mode === 'create') {
                vm.item.$save().then(onSave);
            } else {
                vm.item.$update(vm.item.id).then(onSave);
            }
        };

        vm.onRemoveImage = function () {
            vm.item.imagen = {eliminar: true};
        };

        function initImagen() {
            if (!vm.item.imagen) {
                vm.item.imagen = {};
            }
            if (vm.item.nombreImagen) {
                vm.item.imagen.path = vm.item.nombreImagen;
            }
        }

    }



})(angular);
