(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('cuentaAtras', {
            bindings: {
                fecha: '<'
            },
            templateUrl: 'app/public/components/cuentaatras/cuentaatras.html',
            controllerAs: 'ctrl',
            controller: function () {
                var vm = this;

                // Ocultamos la cuenta atrás si falta más de un mes
                var diff = (vm.fecha * 1000) - new Date();
                vm.ocultar = diff > (30 * 24 * 60 * 60 * 1000);
            }
        });

})(angular);
