(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/examen', {
                parent: 'miespacio/proyecto',
                url: '/:proyectoId/tarea/:tareaId/examen'
            })
            .state('miespacio/examen/create', {
                parent: 'miespacio/examen',
                url: '/:examenId',
                data: {
                    pageTitle: 'miespacio.proyecto.examen.title',
                    clase: 'examenState'
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<examen-form tarea="$resolve.tarea" ejecucion="$resolve.ejecucion" preguntas="$resolve.preguntas"></examen-form>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    tarea: function ($stateParams, Tarea) {
                        return Tarea.getLight({id: $stateParams.tareaId}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    ejecucion: function ($stateParams, Ejecucion) {
                        return Ejecucion.iniciar({tareaId: $stateParams.tareaId}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    preguntas: function ($stateParams, Examen) {
                        return Examen.findPreguntasParaExamen({examenId: $stateParams.examenId}).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            }); // Los detalles del examen se encuentran en una modal en ExamenHelper
    }
})();
