(function () {
    'use strict';

    angular
        .module('app')
        .component('codigo', {
            templateUrl: 'app/public/components/codigo/codigo.html',
            controller: CodigoController,
            controllerAs: 'ctrl',
            // FIXME: Al mover al menú, ya no se le pasa el código en el $resolve como se hacía cuando estaba en home
            bindings: {
                codigo: '<'
            }
        });

    /* @ngInject */
    function CodigoController($state, Auth, Principal, CodigoHelper) {
        var vm = this;

        vm.submit = function () {
            $state.params.codigo = vm.nuevoCodigo;

            if (!vm.nuevoCodigo) {
                return;
            }

            Auth.authorize(false, "ROLE_AUTHENTICATED");

            if (Principal.isAuthenticated()) {
                CodigoHelper.usarCodigo(vm.nuevoCodigo);
            }
        };

        if (vm.codigo) {
            vm.nuevoCodigo = vm.codigo;
            vm.submit();
        }
    }

})();
