(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/pack";
        var params = {
            base: base,
            baseUrl: "/" + base,
            templateUrlBase: "app/admin/pack/pack",
            controller: 'PackAdminController',
            controllerForm: 'PackFormAdminController',
            translateBase: "admin.pack."
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR_CONTENIDO']
            }
        });
        // Listar
        angular.merge(state.list, {});

        var genericResolves = {
            /* @ngInject */
            libros: function (Libro) {
                return Libro.findAllForPack().$promise;
            },
            /* @ngInject */
            ivas: function (Iva) {
                return Iva.findAll().$promise;
            },
            /* @ngInject */
            colecciones: function (Coleccion) {
                return Coleccion.findAll({sortProperty: 'nombre'}).$promise;
            },
            /* @ngInject */
            grupos: function (GrupoEdad) {
                return GrupoEdad.findAll({sortProperty: 'orden'}).$promise;
            }
        };

        // Crear
        angular.merge(state.create, {
            resolve: angular.merge(genericResolves, {
                /* @ngInject */
                itemData: function (Pack) {
                    return new Pack();
                }
            })
        });
        // Editar
        angular.merge(state.edit, {
            resolve: angular.merge(genericResolves, {
                /* @ngInject */
                itemData: function (Pack, $stateParams) {
                    return Pack.get({id: $stateParams.id}).$promise;
                }
            })
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
