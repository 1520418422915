(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PackFormAdminController', PackFormAdminController);

    /* @ngInject */
    function PackFormAdminController($timeout, $state, itemData, colecciones, grupos, ivas, libros,
                                     previousParams, AlertService) {
        var vm = this;

        // Número de grupos de 100 alumnos en los que se clasifican los centros
        // (hasta 100 alumnos, hasta 200 alumnos, etc).
        var NUM_SEGMENTOS_CENTRO = 10;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = itemData;
        vm.colecciones = colecciones.content;
        vm.grupos = grupos.content;
        vm.ivas = ivas.content;
        vm.libros = libros.content;

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.preciosConIva = [];
        vm.precioKeys = [];

        inicializarPrecioMensual();

        function initForm() {
            if (!vm.item.iva) {
                vm.item.iva = vm.ivas[0];
            }
            if (!vm.item.precioBase) {
                vm.item.precioBase = 0;
                vm.precioConIva = 0;
            } else {
                vm.precioConIva = vm.item.precioBase + vm.item.precioBase * (vm.item.iva.valor / 100);
                vm.precioConIva = vm.precioConIva.toFixed(2) * 1;
            }

            if (!vm.item.id){
                // Los precios y precios con IVA se inicializan a 0
                vm.item.preciosPack = [];
                for (var i=0; i<NUM_SEGMENTOS_CENTRO; i++){
                    vm.item[vm.precioKeys[i]] = 0;
                    vm.preciosConIva[i] = 0;
                }

                // La categoría de más de 1000
                vm.item['precioBaseMas1000'] = 0;
                vm.preciosConIva[vm.preciosConIva.length] = 0;
            } else {
                // Se inicializan los precios con IVA
                for (var j=0; j<NUM_SEGMENTOS_CENTRO; j++) {
                    vm.preciosConIva[j] = vm.item[vm.precioKeys[j]] + vm.item[vm.precioKeys[j]] * (vm.item.iva.valor / 100);
                    vm.preciosConIva[j] = vm.preciosConIva[j].toFixed(2) * 1;
                }

                // La categoria de más de 1000
                var ultimaPosicion = vm.preciosConIva.length;
                vm.preciosConIva[ultimaPosicion] = vm.item['precioBaseMas1000'] + vm.item['precioBaseMas1000'] * (vm.item.iva.valor / 100);
                vm.preciosConIva[ultimaPosicion] = vm.preciosConIva[j].toFixed(2) * 1;
            }

            if (vm.item.libros) {
                vm.item.libros.forEach(function (packLibro) {
                    var libro = vm.libros.find(function (libro) {
                        return packLibro.id === libro.libro.id;
                    });
                    if (libro) {
                        libro.checked = true;
                    }
                });
            }

            vm.updatePrecio();
        }

        // Precio
        vm.updatePrecio = function (item, precioKey, precioConIva, iva) {
            if (iva) {
                item[precioKey] = precioConIva / (1 + (iva.valor / 100));
            }
        };

        // Selección de libros

        vm.selectAll = function (check) {
            vm.libros.forEach(function (libro) {
                libro.checked = check;
            });
            vm.colecciones.forEach(function (coleccion) {
                coleccion.checked = true;
            });

            vm.grupos.forEach(function (grupo) {
                grupo.checked = true;
            });
        };

        vm.selectColeccion = function (coleccion) {
            if (!coleccion.checked) vm.all = false;

            vm.libros.forEach(function (libro) {
                if (libro.coleccion && libro.coleccion.id === coleccion.id) {
                    libro.checked = coleccion.checked;
                }
            });
        };

        vm.selectGrupo = function (grupo) {
            if (!grupo.checked) vm.all = false;

            vm.libros.forEach(function (libro) {
                if (libro.grupoEdad && libro.grupoEdad.id === grupo.id) {
                    libro.checked = grupo.checked;
                }
            });
        };

        vm.selectLibro = function (libro) {
            // Si desmarcamos el libro, desmarcamos su categoría y su grupo
            if (!libro.checked) {
                if (libro.coleccion) {
                    vm.colecciones.find(function (coleccion) {
                        return coleccion.id === libro.coleccion.id;
                    }).checked = false;
                }
                if (libro.grupoEdad) {
                    vm.grupos.find(function (grupo) {
                        return grupo.id === libro.grupoEdad.id;
                    }).checked = false;
                }
            }
        };

        vm.countLibros = function () {
            return {
                num: vm.libros.filter(function (libro) {
                    return libro.checked;
                }).length
            }
        };

        // Guardar formulario
        vm.save = function () {
            // Añadimos los libros al Pack
            vm.item.libros = [];
            vm.libros.forEach(function (libro) {
                if (libro.checked) {
                    vm.item.libros.push(libro.libro);
                }
            });

            if (vm.item.libros.length === 0) {
                AlertService.error("admin.pack.error.emptylibros");
                return;
            }

            vm.item.$save().then(function () {
                $state.go('admin/pack/list', previousParams);
            });
        };

        function inicializarPrecioMensual() {
            for (var i = 0; i < NUM_SEGMENTOS_CENTRO; i++) {
                var key = 'precioBase' + (i + 1) * 100;
                vm.precioKeys.push(key);
            }
        }

        initForm();
    }

})(angular);
