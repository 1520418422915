(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PromocionFormAdminController', PromocionFormAdminController);

    /* @ngInject */
    function PromocionFormAdminController($timeout, $state, AlertService, Promocion, DescargaUtil,
                                          libros, categorias, contactos, ivas, tiposPromocion, previousParams) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.tiposPromocion = tiposPromocion;

        vm.item = {};
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.libros = libros;
        vm.categorias = categorias;
        vm.contactos = contactos;
        vm.item.tipoPromocion = vm.tiposPromocion[0];
        vm.ivas = ivas;

        function initForm() {
            if (!vm.item.iva) {
                vm.item.iva = vm.ivas[0];
            }
            if (!vm.item.precioBase) {
                vm.item.precioBase = 0;
                vm.precioConIva = 0;
            }
        }

        vm.selectLibro = function (libro) {
            if (libro.precioConIva) {
                vm.precioConIva = libro.precioConIva;
                vm.updatePrecio(vm.item.iva);
            } else {
                vm.item.precioBase = 0;
                vm.precioConIva = 0;
            }

        };

        // Destinatarios

        vm.selectAll = function (check) {
            vm.categorias.forEach(function (categoria) {
                categoria.checked = check;
                vm.selectCategoria(categoria);
            });
        };

        vm.selectCategoria = function (categoria) {
            if (!categoria.checked) vm.all = false;

            vm.contactos.forEach(function (contacto) {
                if (contacto.categoria.id === categoria.id) {
                    contacto.checked = categoria.checked;
                }
            });
        };

        vm.selectContacto = function (contacto) {
            if (!contacto.checked) {
                vm.categorias.find(function (categoria) {
                    return categoria.id === contacto.categoria.id;
                }).checked = false;
            }
        };

        vm.countPersonas = function () {
            return {
                num: vm.contactos.filter(function (contacto) {
                    return contacto.checked;
                }).length
            }
        };

        // Precio
        vm.updatePrecio = function (iva) {
            if (iva) {
                vm.item.precioBase = vm.precioConIva / (1 + (iva.valor / 100));
            }
        };

        vm.updateIva = function (iva) {
            vm.updatePrecio(iva);
        };

        // Guardar formulario
        vm.save = function () {
            // Añadimos a la promoción la lista de contactos seleccionados
            if (vm.item.tipoPromocion === "CODIGOS" ) {
                vm.item.asuntoEmail = null;
                vm.item.textoEmail = null;
                vm.item.numUsos = 1;
            } else if (vm.item.tipoPromocion === "EMAIL") {
                vm.item.numCodigos = null;
                vm.item.numUsos = 1;
                vm.item.contactos = vm.contactos.filter(function (contacto) {
                    return contacto.checked;
                });
                if (vm.item.contactos.length === 0) {
                    AlertService.error("admin.promocion.promocion.error.emptycontacts");
                    return;
                }
            }  else if (vm.item.tipoPromocion === "CODIGO_GRUPAL"){
                vm.item.asuntoEmail = null;
                vm.item.textoEmail = null;
                vm.item.numCodigos = null;
            }

            Promocion.save(vm.item).$promise.then(function (result) {
                $state.go('admin/promocion/create', {});
                if (vm.item.tipoPromocion === 'CODIGOS' || vm.item.tipoPromocion === 'CODIGO_GRUPAL') {
                    DescargaUtil.descargar('api/admin/promocion/descargar-codigos', {promocionId: result.id}, 'applicaton/txt');
                }
               $state.go('admin/promocion/list', previousParams);
            });
        };

        initForm();

    }

})(angular);
