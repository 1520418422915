(function () {
    'use strict';

    angular.module('app')
        .factory('Tarea', Tarea);

    /* @ngInject */
    function Tarea(ResourceHelper) {
        var entidad = 'tarea';

        return ResourceHelper.getResource(entidad, {}, {
            'getLight': {
                method: 'GET',
                url: 'api/public/' + entidad + '/getLight'
            },
            'findByProyecto': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findByProyecto',
                isArray: false
            },
            'findAlumnos': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findAlumnos',
                isArray: true
            },
            'asignar': {
                method: 'PUT',
                url: 'api/public/' + entidad + '/asignar'
            },
            'findByUsuarioActivo': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findByUsuarioActivo',
                isArray: false
            },
            'findByProfesorYLibro': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findByProfesorYLibro',
                isArray: true
            },
            'cerrar': {
                method: 'GET',
                url: 'api/public/' + entidad + '/cerrar'
            },
            'reabrir': {
                method: 'GET',
                url: 'api/public/' + entidad + '/reabrir'
            },
            'updateAulas': {
                method: 'GET',
                url: 'api/public/' + entidad + '/updateAulas'
            }
        }, true);
    }

})();
