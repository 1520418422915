(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('HistoricoModalController', HistoricoModalController);

    /* @ngInject */
    function HistoricoModalController($timeout, $stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                                      ModalService, Historico, libroId) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = Historico;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: libroId
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {fecha: 'desc'}
        }, NgTableHelper.settings(vm));

        vm.clear = function () {
            ModalService.close();
        }

    }

})(angular);
