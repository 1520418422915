(function () {
    'use strict';

    angular.module('app')
        .controller('EstadisticasCentroController', EstadisticasCentroController);

    /* @ngInject */
    function EstadisticasCentroController($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
                                           Estadisticas, Configuracion, DescargaUtil) {
        var vm = this;

        vm.item = Estadisticas;
        vm.function = "getEstadisticasCentros";
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;

        Configuracion.getFechasCurso().$promise.then(function(data) {
            vm.filter = {
                key: null,
                fechaInicio: new Date(data[0][0], data[0][1] - 1, data[0][2]), // -1 bc 0-based months
                fechaFin: new Date(data[1][0], data[1][1] - 1, data[1][2]), // -1 bc 0-based months
                id: undefined
            };

            vm.tableParams = new NgTableParams({
                count: vm.elementosPorPagina,
                page: vm.pagina,
                filter: vm.filter,
                sorting: {centro: 'asc'}
            }, NgTableHelper.settings(vm));

            vm.generarPdf = function() {
                var sortProperty = vm.tableParams.orderBy().toString().substr(1);
                var sortDirection = vm.tableParams.sorting()[sortProperty];
                var customFilter = {
                    fechaInicio: vm.filter.fechaInicio,
                    fechaFin: vm.filter.fechaFin,
                    id: undefined,
                    page: 0,
                    sortProperty: sortProperty,
                    sortDirection: sortDirection.toUpperCase()
                };
                return DescargaUtil.descargar('api/public/estadisticas/descargarEstadisticas', customFilter, 'applicaton/pdf');
            };

            vm.generarPdfLibros = function($event, id) {
                $event.preventDefault();
                var customFilter = {
                    fechaInicio: vm.filter.fechaInicio,
                    fechaFin: vm.filter.fechaFin,
                    id: id
                };
                return DescargaUtil.descargar('api/public/estadisticas/descargarEstadisticasLibros', customFilter, 'application/pdf');
            }
        });

    }
})();
