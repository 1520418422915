(function () {
    'use strict';

    angular.module('app')
        .factory('Carro', Carro);

    /* @ngInject */
    function Carro(ResourceHelper) {
        return ResourceHelper.getResource("carro", {}, {
            'get': {
                method: 'GET',
                url: 'api/public/carro/get',
                isArray: false
            },
            'sync': {
                method: 'POST',
                url: 'api/public/carro/sync',
                isArray: false
            },
            'addProducto': {
                method: 'POST',
                url: 'api/public/carro/addProducto',
                isArray: false
            },
            'removeProducto': {
                method: 'POST',
                url: 'api/public/carro/removeProducto',
                isArray: false
            },
            'validate': {
                method: 'GET',
                url: 'api/public/carro/validate',
                isArray: false
            }
        });
    }

})();
