(function () {
    'use strict';
    angular
        .module('app')
        .constant('ALFABETO', 'abcdefghijklmnñopqrstuvwxyz')
        // Nº de elementos por página en las tablas
        .constant('NG_TABLE_DEFAULT_PARAMS_PUBLIC', {size: 20})
        // Nº de elementos por página en las listas
        .constant('NG_LIST_DEFAULT_PARAMS_PUBLIC', {size: 20})
        // Nº de milisegundos para actualizar el contador de notificaciones
        .constant('NOTIFICACIONES_TIME', 90000)
        // Nº de últimas notificaciones que se muestran
        .constant('NOTIFICACIONES_COUNT', 10)
        // Tiempo mínimo de lectura de una página para actualizar el avance (ms)
        .constant('MIN_TIEMPO_PAGINA', 10000)
        // Velocidad lectora maxima a tener en cuenta en estadisticas (palabras/minuto)
        .constant('MAX_VELOCIDAD_LECTORA', 300)
        // Velocidad lectora mínima a tener en cuenta en estadisticas (palabras/minuto)
        .constant('MIN_VELOCIDAD_LECTORA', 50)
        // Fechas
        .constant('DIA_EN_MILIS', 86400000)
        // Visor ePub
        .constant('TAMANO_FUENTES', ["14px", "18px", "22px", "28px", "40px"])
        .constant('COLORES_FONDO', ["white", "#fbf0d9", "black"])
        .constant('TIPOS_FUENTE', ["Arial, sans-serif", "Times, serif", "Trebuchet MS", "Verdana, sans-serif"])
        .constant('INTERLINEADOS', ["1em", "1.25em", "1.5em", "1.8em"])
        // Facebook
        // Facebook ID (clientID). Coincide con la que se indique en application-prod.yml
        .constant('FACEBOOK_ID', '294397101020791')
        .constant('FACEBOOK_API_VERSION', 'v2.9')
        // reCaptcha. Clave del lado cliente. En application-x.yml está la del lado servidor.
        // Clave facilitada por el cliente
        .constant('RECAPTCHA_API_KEY', "6Led_UoUAAAAAO1Z6ubdHkjAgBXIsaN-M5dZVpoP");
})();
