(function (angular) {
    'use strict';
    angular
        .module('app')
        .controller('RecursoAdminController', RecursoAdminController);
    /* @ngInject */
    function RecursoAdminController($timeout, $state, $stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper, ModalService, Recurso, URL_RECURSO) {
        var vm = this;

        vm.titulo = $stateParams.titulo;
        vm.urlRecurso = function (id) {
            return URL_RECURSO(id);
        };

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = Recurso;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            id: $stateParams.libroId
        };
        vm.function = "findAllByLibro";
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {nombre: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    resolve: {
                        item: function () {
                            return Recurso.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.libro.recurso.delete.title',
                                body: 'admin.libro.recurso.delete.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                }
            ).result.then(function () {
                $state.reload();
            });
        };
    }
})(angular);
