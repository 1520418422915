(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('GrupoEdadAdminController', GrupoEdadAdminController);

    /* @ngInject */
    function GrupoEdadAdminController($timeout, $stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                                      ModalService, GrupoEdad) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = GrupoEdad;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {orden: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    resolve: {
                        item: function () {
                            return GrupoEdad.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.libro.grupoedad.delete.title',
                                body: 'admin.libro.grupoedad.delete.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

        vm.subir = function (id) {
            GrupoEdad.subir({id: id}).$promise.then(function (data) {
                console.log(data);
                vm.tableParams.reload();

            })
        };

        vm.bajar = function (id) {
            GrupoEdad.bajar({id: id}).$promise.then(function (data) {
                console.log(data);
                vm.tableParams.reload();

            })
        };

    }

})(angular);
