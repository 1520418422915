(function () {
    'use strict';

    // Operaciones CRUD para la gestión de elementos en la administración.
    angular.module('app')
        .constant('STATE_HELPER', function (params) {
            var states = {
                parent: {
                    name: params.base,
                    parent: params.parent ? params.parent : 'admin',
                    url: params.baseUrl,
                    redirectTo: params.base + '/list',
                    ncyBreadcrumb: {
                        skip: true
                    }
                    // Extend: data {authorities}
                },
                list: {
                    name: params.base + "/list",
                    parent: params.base,
                    url: '/listar/:page',
                    params: {
                        page: {value: '1', dynamic: true}
                    },
                    views: {},
                    data: {
                        pageTitle: params.translateBase + "list.title"
                    },
                    ncyBreadcrumb: {
                        label: params.translateBase + "list.title"
                    }
                },
                create: {
                    name: params.base + "/create",
                    parent: params.base,
                    url: '/crear',
                    data: {
                        pageTitle: params.translateBase + 'create.title',
                        mode: 'create'
                    },
                    views: {},
                    ncyBreadcrumb: {
                        label: params.translateBase + "create.title",
                        parent: params.base + "/list"
                    },
                    // Extend: resolve
                    resolve: {
                        /* @ngInject */
                        previousParams: function ($state) {
                            return $state.params;
                        }
                    }
                },
                edit: {
                    name: params.base + "/edit",
                    parent: params.base,
                    url: '/:id/editar',
                    data: {
                        pageTitle: params.translateBase + 'edit.title',
                        mode: 'edit'
                    },
                    views: {},
                    ncyBreadcrumb: {
                        label: params.translateBase + "edit.title",
                        parent: params.onlyEdit ? null : params.base + "/list"
                    },
                    // Extend: resolve
                    resolve: {
                        /* @ngInject */
                        previousParams: function ($state) {
                            return $state.params;
                        }
                    }
                }
            };

            if (!params.view) {
                params.view = 'content@';
            }

            // Listar
            states.list.views[params.view] = {
                templateUrl: params.templateUrlBase + '.list.html',
                controller: params.controller,
                controllerAs: 'ctrl'
            };
            // Crear y editar
            states.create.views[params.view] = {
                templateUrl: params.templateUrlBase + '.form.html',
                controller: params.controllerForm,
                controllerAs: 'ctrl'
            };
            states.edit.views[params.view] = angular.copy(states.create.views[params.view]);

            return states;
        });
})();
