(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/gestionaltas', {
                parent: 'admin',
                url: '/admin/usuarios/gestion-altas/:page',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'admin.list-users.title'
                },
                params: {
                    page: {value: '1', dynamic: true}
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/user/gestionaltas/gestion-altas.html',
                        controller: 'GestionAltasController',
                        controllerAs: 'ctrl'
                    }
                },
                ncyBreadcrumb: {
                    label: "global.menu.gestionaltas"
                }
            });
    }
})();
