(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/slider";
        var params = {
            base: base,
            baseUrl: "/" + base,
            templateUrlBase: "app/admin/configuracion/slide/slide",
            controller: 'SlideAdminController',
            controllerForm: 'SlideFormAdminController',
            translateBase: "admin.configuracion.slide."
        };

        var state = STATE_HELPER(params);
        // Parent State
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN']
            }
        });
        // List State
        angular.merge(state.list, {
            url: base + '/imagenes', params: {}
        });
        // Create State
        angular.merge(state.create, {
            resolve: {
                /* @ngInject */
                itemData: function (Slide, $state) {
                    return new Slide();
                }
            }
        });
        // Edit State
        angular.merge(state.edit, {
            resolve: {
                /* @ngInject */
                itemData: function (Slide, $stateParams) {
                    return Slide.get({slideId: $stateParams.id}).$promise;
                }
            }
        });

        // Define the states
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
