(function () {
    'use strict';

    angular
        .module('app')
        .component('buscadorSimple', {
            templateUrl: 'app/public/components/buscador/buscador.simple.html',
            controller: BuscadorController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function BuscadorController($state) {
        var vm = this;

        vm.search = function () {
            $state.go("catalogo", {query: vm.query});
        };

    }

})();
