(function (angular) {
    'use strict';

    // comentario: Comentario que se va a elevar.
    // miComentario: Comentario hecho por el usuario en el que puntua el libro.
    // puntuacionMedia: Puntuación media del libro.
    // numComentarios: Número total de comentarios en el libro.
    // onAdd: Función de callback para cuando se añade un comentario.
    // elevar: (Boolean) True si se está elevando un comentario.

    angular
        .module('app')
        .component('comentarioPuntuar', {
            bindings: {
                comentario: '<',
                miComentario: '<',
                puntuacionMedia: '<',
                numComentarios: '<',
                onAdd: '<',
                elevar: '<'
            },
            templateUrl: 'app/components/comentario/comentario.puntuar.html',
            /* @ngInject */
            controller: function ($state, Comentario, ModalService) {
                var vm = this;

                vm.puntuable = (!vm.comentario.tarea && !vm.comentario.club) || vm.elevar;

                if (vm.elevar) {
                    var comentarioOriginal = vm.comentario;
                    vm.comentario = angular.copy(vm.comentario);

                    Comentario.getPuntuacionMedia({libroId: vm.comentario.libro.id}).$promise.then(function (result) {
                        vm.puntuacionMedia = result.data;
                    });
                }

                vm.save = function () {
                    if (!vm.elevar) {
                        Comentario.save(vm.comentario).$promise.then(function (data) {
                            vm.comentario.texto = '';
                            vm.comentario.puntuacion = null;
                            vm.onAdd(data);
                        });
                    } else {
                        Comentario.elevar(vm.comentario).$promise.then(function () {
                            comentarioOriginal.elevado = true;
                            ModalService.close();
                            angular.element("body").animate({scrollTop: 0}, "slow");
                        });
                    }
                };

            },
            controllerAs: 'ctrl'
        });
})(angular);
