(function () {
    'use strict';

    angular.module('app')
        .factory('ContactoForm', Service);

    /* @ngInject */
    function Service($resource) {
        var entidad = 'contacta';
        return $resource('api/public/' + entidad);
    }
})();
