(function () {
    'use strict';

    angular.module('app')
        .factory('CarroHelper', CarroHelper);

    /* @ngInject */
    function CarroHelper($localStorage, $q, Carro, Principal, ModalService, LibroAdquirido, AlertService) {

        return {
            clear: clear,
            get: get,
            addProducto: addProducto,
            addRegalo: addRegalo,
            sync: sync,
            removeProducto: removeProducto,
            validate: validateCarro
        };

        function initCarro() {
            if (!$localStorage.carro) {
                $localStorage.carro = {};
            }
            $localStorage.carro.productos = [];
        }

        function clear() {
            initCarro();
        }

        function get() {
            if (!$localStorage.carro) {
                initCarro();
            }
            return $localStorage.carro;
        }

        function addProducto(libro, email) {
            if (!$localStorage.carro) {
                clear();
            }

            // Comprobamos si existen elementos en el carro repetidos
            var aux = $localStorage.carro.productos.filter(function (p) {
                return p.libro && p.libro.id == libro.id && p.emailDestinatario == email;
            });

            if (!aux || !aux.length) {
                var producto = {
                    clientId: new Date() / 1,
                    libro: libro,
                    precioConIva: libro.precioConIva,
                    emailDestinatario: email,
                    fechaAdicion: new Date()
                };
                $localStorage.carro.productos.push(producto);

                if (Principal.isAuthenticated()) {
                    Carro.addProducto(producto).$promise.then(function (result) {
                        if (result.id) {
                            producto.id = result.id;
                        } else {
                            // Si no se ha añadido en el servidor, lo eliminamos del lado cliente
                            $localStorage.carro.productos.splice($localStorage.carro.productos.indexOf(producto), 1)
                        }
                    });
                }
            }

            ModalService.open({
                templateUrl: 'app/public/carro/carro.modal.html',
                /* @ngInject */
                controller: function ($state, itemData, ModalService) {
                    var vm = this;
                    vm.libro = itemData;
                    vm.close = function () {
                        ModalService.close();
                    };
                    vm.checkout = function () {
                        ModalService.close();
                        $state.go('carro');
                    };
                },
                controllerAs: 'ctrl',
                resolve: {
                    itemData: function () {
                        return libro;
                    }
                }
            });
        }

        function addRegalo(libro) {
            ModalService.open({
                templateUrl: 'app/public/carro/carro.regalo.modal.html',
                /* @ngInject */
                controller: function ($state, itemData, ModalService, Usuario, Principal) {
                    var vm = this;
                    vm.libro = itemData;
                    vm.email = {};
                    vm.usuarioService = Usuario;

                    vm.close = function () {
                        ModalService.close();
                    };
                    vm.checkout = function () {
                        if (Principal.isAuthenticated()) {
                            // Si está autenticado se hace la petición de comprobación
                            // de si el destinatario ya posee el libro
                            LibroAdquirido.getByLibroAndUsuario({libroId: vm.libro.id, email: vm.email.email})
                                .$promise.then(function (libroAdquirido) {
                                if (libroAdquirido.id) {
                                    AlertService.warning('compra.carro.regalo.modal.yatienelibro', {}, 0);
                                } else {
                                    finalizarCheckout(vm.libro, vm.email.email);
                                }
                            });
                        } else {
                            finalizarCheckout(vm.libro, vm.email.email);
                        }
                    };

                    function finalizarCheckout(libro, email){
                        ModalService.close();
                        addProducto(libro, email);
                    }
                },
                controllerAs: 'ctrl',
                resolve: {
                    itemData: function () {
                        return libro;
                    }
                }
            });
        }

        function sync(force) {
            var defered = $q.defer();
            var promise = defered.promise;

            if (Principal.isAuthenticated()) {
                if (!$localStorage.carro || force) {
                    console.log("Forzando actualización del carro");

                    // Obtenemos el carro almacenado en servidor
                    Carro.get().$promise.then(function (result) {
                        clear();
                        $localStorage.carro.productos = result.productos;
                        defered.resolve();
                    });
                } else {

                    // Enviamos la información del cliente al servidor
                    Carro.sync($localStorage.carro.productos).$promise.then(function (result) {
                        $localStorage.carro.productos = result.productos;
                        defered.resolve();
                    });
                }
            } else {
                defered.resolve();
            }
            return promise;
        }

        function removeProducto(producto) {
            angular.forEach($localStorage.carro.productos, function (p, i) {
                if (p.clientId == producto.clientId) {
                    $localStorage.carro.productos.splice(i, 1);

                    if (Principal.isAuthenticated() && producto.id) {
                        Carro.removeProducto(p);
                    }
                }
            });
        }

        // Devuelve true si todos los elementos son válidos.
        function checkCarro() {
            var productosInvalidos = $localStorage.carro.productos.filter(function (producto) {
                return !producto.valido;
            });
            console.log("checking carro");
            return productosInvalidos.length == 0;
        }

        function validateCarro() {
            var defered = $q.defer();
            var promise = defered.promise;

            console.log("validate carro");

            if (Principal.isAuthenticated()) {
                Carro.validate().$promise.then(function (result) {
                    clear();
                    $localStorage.carro.productos = result.productos;
                    defered.resolve(checkCarro());
                });
            } else {
                defered.resolve(false);
            }
            return promise;
        }

    }

})();
