(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/mislibros', {
                parent: 'miespacio',
                url: '/mis-libros',
                redirectTo: 'miespacio/mislibros/estado',
                data: {
                    // Gestión de roles en el resolve
                    authorities: [],
                    pageTitle: 'miespacio.mislibros.title',
                    titulo: 'miespacio.mislibros.title',
                    tituloImg: 'assets/images/iconos/libros.png',
                    clase: 'misLibrosState'
                },
                views: {
                    'contentEspacio': {
                        templateUrl: 'app/public/cuenta/miespacio/mislibros/mis-libros.html',
                        controllerAs: 'ctrl'
                    }
                },
                /* ngInject */
                onEnter: function ($state, Principal) {
                    // Control de acceso según el rol
                    if (navigator.onLine) {
                        if (Principal.hasAnyAuthority(["ROLE_CENTRO"])) {
                            // $state.go('miespacio/packs/mispacks', {}, {reload: true});
                        } else if (Principal.hasAnyAuthority(["ROLE_LIBRERIA"])) {
                            $state.go('miespacio/miscompras');
                        }
                    }
                },
                resolve: {
                    /* ngInject */
                    tieneCentros: function (AlumnoCentro) {
                        if (navigator.onLine) {
                            return AlumnoCentro.tieneCentros().then(function (result) {
                                return result.data;
                            });
                        } else {
                            return false;
                        }
                    }
                }
            })
            .state('miespacio/mislibros/biblioteca', {
                parent: 'miespacio/mislibros',
                url: '/BIBLIOTECA',
                data: {
                    authorities: ['ROLE_AUTHENTICATED']
                },
                views: {
                    'contentMislibros': {
                        template: '<biblioteca libros="$resolve.libros"></biblioteca>'
                    }
                },
                resolve: {
                    /* ngInject */
                    libros: function (Libro) {
                        return Libro.findBiblioteca().$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            })
            .state('miespacio/mislibros/estado', {
                parent: 'miespacio/mislibros',
                url: '/:estado',
                params: {
                    estado: 'TODOS'
                },
                views: {
                    'contentMislibros': {
                        template: '<mis-libros libros="$resolve.librosAdquiridos" archivados="$resolve.archivados"></mis-libros>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    librosAdquiridos: function ($stateParams, $localStorage, LibroAdquirido, Auth, Principal, OfflineService) {
                        if (navigator.onLine) {
                            // FIXME: Cuando se recarga la página estando en Mis Libros, se hacen dos llamadas a account/account
                            return Auth.authorize(false, "ROLE_AUTHENTICATED").then(function () {
                                if (Principal.isAuthenticated()) {
                                    return LibroAdquirido.filter({estado: $stateParams.estado}).$promise.then(function (data) {
                                        return OfflineService.actualizarMisLibros(data);
                                    });
                                }
                            });
                        } else {
                            return $localStorage.librosAdquiridos;
                        }
                    },
                    /* @ngInject */
                    archivados: function ($stateParams) {
                        return $stateParams.estado === 'OCULTOS';
                    }
                }
            });
    }
})();
