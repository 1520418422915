(function () {
    'use strict';

    angular
        .module('app')
        .component('tareasByLibro', {
            templateUrl: 'app/public/components/proyectos/tarea/tareas.by.libro.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tareas: '<'
            }
        });

    /* @ngInject */
    function Controller($state) {
        var vm = this;

        function init() {
            vm.filter = {
                sortProperty: 'fechaCreacion',
                sortDirection: 'DESC'
            };

            // sort tareas
            vm.tareas.sort(comparisonOrder);
        }

        function comparisonOrder(a, b) {
            // DESC order
            return b.fechaCreacion - a.fechaCreacion;
        }

        vm.ordenarRecientes = function () {
            if (vm.filter.sortDirection === 'DESC') {
                vm.filter.sortDirection = 'ASC';
                vm.tareas = vm.tareas.reverse();
            } else {
                vm.filter.sortDirection = 'DESC';
                vm.tareas.sort(comparisonOrder);
            }
        };

        //Ver detalles tarea
        vm.showDetails = function (tarea) {
            $state.go('miespacio/tarea/details', {proyectoId: tarea.proyectoId, id: tarea.id});
        };

        init();
    }

})();
