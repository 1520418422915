(function (angular) {
    'use strict';

    // comentarios: Lista de comentarios.
    // libro: Libro al que va dirigido el comentario.
    // tarea: Tarea a la que va dirigida el comentario.
    // club: Club de lectura al  que va dirigido el comentario.
    // centros: Lista de centros a los que pertenece el usuario
    // misComentarios: (boolean) Booleano para saber si el componente se muestra como en Mis Comentarios.
    // lectura: (boolean) Booleano para saber si el componente es de solo lectura o también escritura.
    // administra: (boolean) Booleano para indicar si el usuario tiene permisos de administrador (eliminar comentarios).

    angular
        .module('app')
        .component('comentarioList', {
            bindings: {
                comentarios: '<',
                libroId: '<',
                tareaId: '<',
                clubId: '<',
                centros: '<',
                misComentarios: '=',
                lectura: '<',
                administra: '<'
            },
            templateUrl: 'app/components/comentario/comentario.list.html',
            /* @ngInject */
            controller: function ($state, Comentario, Principal) {
                var vm = this;


                function calcularPuntuacionMedia() {
                    if (vm.lectura) {
                        Comentario.getPuntuacionMedia({libroId: vm.libroId}).$promise.then(function (result) {
                            vm.puntuacionMedia = result.data;
                        });
                    }
                }

                function buscarMiComentario() {
                    vm.miComentario = vm.comentarios.find(function (comentario) {
                        if (!comentario.padreId && comentario.usuario.id === vm.usuarioId) {
                            return comentario;
                        }
                    });
                }

                function contarComentarios() {
                    var i = vm.comentarios.length;
                    vm.comentarios.forEach(function (comentario) {
                        if (comentario.comentarios) {
                            i = i + comentario.comentarios.length;
                        }
                    });
                    vm.numComentarios = i;
                }

                function init() {
                    if (vm.centros && vm.centros.length > 0) {
                        vm.centro = vm.centros.find(function (centro) {
                            return centro.id.toString() === $state.params.centroId;
                        });
                        if (!vm.centro) {
                            vm.centro = vm.centros[0];
                        }
                    }

                    vm.comentarioEnFicha = !vm.tareaId && !vm.clubId;

                    if (vm.comentarioEnFicha) {
                        Principal.identity().then(function (data) {
                            vm.usuarioId = data.id;
                            buscarMiComentario();
                        });
                    }

                    calcularPuntuacionMedia();
                    vm.contarComentarios();

                    if (!vm.comentario) {
                        vm.comentario = {};
                    }

                    if (!vm.comentario.libro && vm.libroId) {
                        vm.comentario.libro = {id: vm.libroId};
                    }
                    if (!vm.comentario.tarea && vm.tareaId) {
                        vm.comentario.tarea = {id: vm.tareaId};
                    }
                    if (!vm.comentario.centro && vm.centro) {
                        vm.comentario.centro = {id: vm.centro.id, type: 'centro'};
                    }
                    if (!vm.comentario.club && vm.clubId) {
                        vm.comentario.club = {id: vm.clubId};
                    }
                }

                vm.contarComentarios = contarComentarios;

                vm.onAddComentario = function (data) {
                    vm.comentarios.unshift(data);

                    if (vm.comentarioEnFicha) {
                        vm.miComentario = data;
                        calcularPuntuacionMedia();
                        buscarMiComentario();
                    }
                    ++vm.numComentarios;
                };

                vm.delete = function (comentario) {
                    vm.comentarios.splice(vm.comentarios.indexOf(comentario), 1);
                    calcularPuntuacionMedia();
                    buscarMiComentario();
                    vm.contarComentarios();
                };

                vm.changeCentro = function (centro) {
                    $state.params.centroId = centro.id;
                    $state.go($state.current, $state.params, {reload: true});
                };

                init();
            },
            controllerAs: 'ctrl'
        });
})(angular);
