(function () {
    'use strict';

    angular.module('app')
        .factory('Preferencias', Preferencias);

    /* @ngInject */
    function Preferencias(ResourceHelper) {
        return ResourceHelper.getResource("preferencias", {}, {
            'obtenerPreferencias': {
                method: 'GET',
                url: 'api/public/preferencias/obtenerPreferencias',
                params: {
                    id: '@id'
                },
                isArray: false
            },
            'guardarPreferencias': {
                method: 'POST',
                url: 'api/public/preferencias/guardarPreferencias',
                isArray: false
            }
        });
    }

})();
