(function () {
    'use strict';

    angular
        .module('app')
        .component('libroList', {
            templateUrl: 'app/public/components/libro/libro.list.html',
            controller: 'LibroController',
            controllerAs: 'ctrl',
            bindings: {
                libros: '<',
                lectura: '<'
            }
        }).controller('LibroController', LibroController);

    /* @ngInject */
    function LibroController() {

    }

})();
