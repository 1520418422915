(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('site', {
            abstract: true,
            data: {
                authorities: []
            },
            resolve: {
                /* @ngInject */
                authorize: function (Auth) {
                    return Auth.authorize();
                },
                /* @ngInject */
                isVisor: function ($rootScope) {
                    $rootScope.isVisor = false;
                    angular.element("html").removeClass("full");
                    angular.element("body").removeClass("full");
                },
                /* @ngInject */
                isOffline: function ($rootScope) {
                    $rootScope.isOffline = !navigator.onLine;
                },
                /* @ngInject */
                isMovil: function ($rootScope, $window) {
                    $rootScope.isMovil = $window.innerWidth < 992;
                },
                /* @ngInject */
                isHorizontalScroll: function ($rootScope, $window) {
                    $rootScope.isHorizontalScroll = $window.innerWidth < 1150;
                },
                /* @ngInject */
                isDebugMode: function ($rootScope, DEBUG_INFO_ENABLED) {
                    $rootScope.isDebugMode = DEBUG_INFO_ENABLED;
                }
            }
        });
    }
})();
