(function () {
    'use strict';

    angular.module('app')
        .factory('Pregunta', Pregunta);

    /* @ngInject */
    function Pregunta(ResourceHelper) {
        return ResourceHelper.getResource("pregunta", {}, {
            'findByLibro': {
                method: 'GET',
                url: 'api/public/pregunta/findByLibro',
                isArray: false
            },
            'eliminarTodas': {
                method: 'GET',
                url: 'api/admin/pregunta/eliminarTodas'
            },
            'importarExcel': {
                method: 'POST',
                url: 'api/admin/pregunta/importarExcel',
                params: {
                    archivoTemporal: '@archivoTemporal',
                    archivoOriginal: '@archivoOriginal',
                    libroId: '@libroId'
                }
            }
        });
    }

})();
