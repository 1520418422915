(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PromocionDetailsAdminController', Controller);

    /* @ngInject */
    function Controller($stateParams, $timeout, $state, promocion, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                        AlertService, Promocion) {
        var vm = this;

        vm.item = Promocion;
        vm.function = 'findCodigos';
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            id: promocion.id
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {'c.codigo': 'asc'}
        }, NgTableHelper.settings(vm));

        vm.isEmpty = function () {
            return vm.tableParams.data.length === 0;
        }
    }

})(angular);
