(function (angular) {
    'use strict';

    // Representa una barra de progreso con el punto actual en el que se encuentra la lectura
    // así como los botones de descarga y visualización.

    // libro: El objeto Libro.
    // adquirido: El objeto LibroAdquirido que representa la adquisición del libro anterior.

    angular
        .module('app')
        .component('botonesLectura', {
            bindings: {
                libro: '<',
                adquirido: '<',
                archivado: '<'
            },
            templateUrl: 'app/components/botoneslectura/botoneslectura.html',
            controllerAs: 'ctrl',

            /* @ngInject */
            controller: function(OfflineService){
                var vm = this;

                vm.checkConnectionEpubOff = function (ta) {
                    return !!(navigator.onLine || OfflineService.existeEpubOff(ta));
                };

                vm.descargarPdfOffline = function () {
                    OfflineService.descargarComponentesPdf(vm.adquirido, function () {
                    });
                };

                vm.existeEpubOffline = function (ta) {
                    return OfflineService.existeEpubOff(ta);
                };

                vm.descargarEpubOffline = function () {
                    OfflineService.descargarComponentesEpub(vm.adquirido, function () {
                    });
                };

                vm.checkConnectionPdfOff = function (ta) {
                    return !!(navigator.onLine || OfflineService.existePdfOff(ta));
                };

                vm.existePdfOffline = function (ta) {
                    return OfflineService.existePdfOff(ta);
                };
            }
        });
})(angular);
