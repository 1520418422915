(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/miscomentarios/tipo', {
                parent: 'miespacio/miscomentarios',
                url: '/tipo/:tipo',
                params: {
                    tipo: 'general',
                    page: 1
                },
                data: {
                    titulo: 'miespacio.miscomentarios.title',
                    tituloImg: 'assets/images/iconos/comentarios.png',
                    clase: 'comentariosState'
                },
                views: {
                    'contentMisComentarios': {
                        template: '<comentario-list comentarios="$resolve.comentarios.content" mis-comentarios="true"></comentario-list> ' +
                        '<pagination current-page="$resolve.stateParams.page" total-elements="$resolve.comentarios.totalElements"></pagination>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    updateState: function ($stateParams, misComentariosTabs) {
                        if ($stateParams.tipo === 'general' && misComentariosTabs.general) return;
                        if ($stateParams.tipo === 'centro' && misComentariosTabs.centros) return;
                        if ($stateParams.tipo === 'tarea' && misComentariosTabs.tareas) return;
                        if ($stateParams.tipo === 'club' && misComentariosTabs.clubs) return;
                        if (misComentariosTabs.general) {
                            $stateParams.tipo = 'general';
                        } else if (misComentariosTabs.centros) {
                            $stateParams.tipo = 'centro';
                        } else if (misComentariosTabs.tareas) {
                            $stateParams.tipo = 'tarea';
                        } else if (misComentariosTabs.clubs) {
                            $stateParams.tipo = 'club';
                        }
                        return $stateParams.tipo;
                    },
                    /* @ngInject */
                    comentarios: function (Comentario, $stateParams, NG_LIST_DEFAULT_PARAMS_PUBLIC) {
                        var page = $stateParams.page ? $stateParams.page : 1;
                        var filter = {
                            page: page - 1,
                            size: NG_LIST_DEFAULT_PARAMS_PUBLIC.size
                        };
                        switch ($stateParams.tipo) {
                            case 'centro':
                                filter.centro = true;
                                break;
                            case 'tarea':
                                filter.tarea = true;
                                break;
                            case 'club':
                                filter.club = true;
                                break;
                        }
                        return Comentario.filterByTypeAndUsuarioActivo(filter).$promise.then(function (data) {
                            return data;
                        });
                    },
                    stateParams: function ($stateParams) {
                        return $stateParams;
                    }

                }
            })
            .state('miespacio/miscomentarios', {
                parent: 'miespacio',
                url: '/mis-comentarios',
                redirectTo: 'miespacio/miscomentarios/tipo',
                views: {
                    'contentEspacio': {
                        templateUrl: 'app/public/cuenta/miespacio/miscomentarios/mis-comentarios.list.html',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    misComentariosTabs: function (Comentario) {
                        return Comentario.getActivatedTypesByUsuarioActivo().$promise.then(function (data) {
                            return data;
                        })
                    }
                }
            });
    }
})();
