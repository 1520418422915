(function () {
    'use strict';

    // proyectoId: ID del proyecto
    // editable: (boolean) Para saber si la información del proyecto todavía se puede editar.

    angular
        .module('app')
        .component('tareas', {
            templateUrl: 'app/public/components/proyectos/tarea/tareas.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                proyectoId: '<',
                editable: '<'
            }
        });

    /* @ngInject */
    function Controller($state, $stateParams, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper,
                        Tarea, LibroAdquirido, ModalService, Principal) {
        var vm = this;

        function initTable() {
            vm.item = Tarea;
            vm.function = 'findByProyecto';
            vm.pagina = $stateParams.page ? $stateParams.page : 1;
            vm.filter = {
                key: undefined,
                id: vm.proyectoId,
                alumnoId: vm.alumnoId
            };
            vm.tableParams = new NgTableParams({
                page: vm.pagina,
                filter: vm.filter,
                sorting: {'libro.titulo': 'asc'}
            }, NgTableHelper.settings(vm));

            vm.today = (new Date()).getTime() / 1000;

            // Eliminar
            vm.showRemoveConfirmation = function (id) {
                ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    resolve: {
                        /* @ngInject */
                        item: function (Tarea) {
                            return Tarea.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'miespacio.proyecto.tarea.delete.title',
                                body: 'miespacio.proyecto.tarea.delete.confirm',
                                property: 'id'
                            };
                        }
                    }
                }).result.then(function () {
                    vm.tableParams.reload();
                });
            }
        }

        Principal.identity().then(function (data) {
            if (data.rol == 'ROLE_LECTOR') {
                vm.alumnoId = data.id;
                vm.esAlumno = true;
            }
            vm.profesorActivado = data && data.activado && data.centro != null;
            initTable();
        });

        vm.abrirLibro = function (libro) {
            // Si se tiene, se accede a la lectura del libro. Si no, a la ficha pública.
            LibroAdquirido.getByLibroAndUsuarioActivo({libroId: libro.id}).$promise.then(function (libroAdquirido) {
                if (libroAdquirido.id) {
                    $state.go("visor/epub", {id: libroAdquirido.id});
                } else {
                    $state.go("libro", {tituloAbrev: libro.tituloAbrev});
                }
            });
        }

    }

})();
