(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/miscompras', {
                parent: 'miespacio',
                redirectTo: 'miespacio/miscompras/list',
                data: {
                    pageTitle: 'global.menu.miespacio.miscompras',
                    titulo: 'miespacio.miscompras.list.title',
                    tituloImg: 'assets/images/iconos/compras.png',
                    clase: 'comprasState',
                    authorities: ['ROLE_LECTOR', 'ROLE_PROFESOR', 'ROLE_LIBRERIA']
                }
            })
            .state('miespacio/miscompras/list', {
                parent: 'miespacio/miscompras',
                url: '/mis-compras/:page',
                params: {
                    page: {value: '1', dynamic: true}
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<compras></compras>'
                    }
                }
            })
            .state('miespacio/micompra', {
                parent: 'miespacio/miscompras',
                url: '/mi-compra/:id',
                views: {
                    'contentEspacio@miespacio': {
                        template: '<compras-details factura="$resolve.factura"></compras-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    factura: function (Factura, $stateParams) {
                        return Factura.get($stateParams.id);
                    }
                }
            });
    }
})();
