(function (angular) {
    'use strict';

    // comentario: Comentario a mostrar
    // padre: (boolean) true si es comentario raíz, false si es una respuesta
    // onDelete: función llamada al eliminar un comentario.
    // onChange: función llamada al eliminar o guardar respuestas.
    // misComentarios: (boolean) Booleano para saber si el componente se muestra como en Mis Comentarios.
    // lectura: (boolean) Booleano para saber si el componente es de solo lectura o también escritura.
    // administra: (boolean) Booleano para indicar si el usuario tiene permisos de administrador (eliminar comentarios).

    angular
        .module('app')
        .component('comentario', {
            bindings: {
                comentario: '<',
                padre: '<',
                onDelete: '<',
                onChange: '<',
                misComentarios: '=',
                lectura: '<',
                administra: '<'
            },
            templateUrl: 'app/components/comentario/comentario.html',
            /* @ngInject */
            controller: function ($state, Comentario, Principal, ModalService) {
                var vm = this;
                vm.respuestaForm = false;
                vm.respuesta = {};

                vm.responder = function (respuestaForm) {
                    vm.respuestaForm = !respuestaForm;
                };

                vm.remove = function () {
                    Comentario.remove({id: vm.comentario.id}).$promise.then(function () {
                        vm.onDelete(vm.comentario);
                    });
                };

                vm.deleteRespuesta = function (respuesta) {
                    vm.comentario.comentarios.splice(vm.comentario.comentarios.indexOf(respuesta), 1);
                    vm.onChange();
                };

                vm.elevar = function () {
                    ModalService.open({
                        templateUrl: 'app/components/comentario/comentario.puntuar.modal.html',
                        /* @ngInject */
                        controller: function ($state, comentario, ModalService) {
                            var vm = this;
                            vm.close = function () {
                                ModalService.close();
                            };
                        },
                        size: 'lg',
                        controllerAs: 'ctrl',
                        resolve: {
                            comentario: function () {
                                return vm.comentario;
                            }
                        }
                    });
                };

                vm.save = function () {
                    vm.respuesta.libro = {};
                    if (vm.comentario.libro) {
                        vm.respuesta.libro = {id: vm.comentario.libro.id};
                    }
                    if (vm.comentario.tarea) {
                        vm.respuesta.tarea = {id: vm.comentario.tarea.id};
                    }
                    if (vm.comentario.centro) {
                        vm.respuesta.centro = {id: vm.comentario.centro.id, type: "centro"};
                    }
                    vm.respuesta.padreId = vm.comentario.id;

                    Comentario.save(vm.respuesta).$promise.then(function (data) {
                        vm.respuesta.texto = '';
                        vm.comentario.comentarios.unshift(data);
                        vm.onChange();
                    });
                };

                Principal.identity().then(function (data) {
                    vm.mismoUsuario = data.id === vm.comentario.usuario.id;

                    var general = !vm.comentario.centro && !vm.comentario.tarea && !vm.comentario.club;
                    vm.seComparte = vm.mismoUsuario && general;
                    vm.elevable = vm.mismoUsuario && !general && !vm.lectura && !vm.comentario.elevado;
                });

            },
            controllerAs: 'ctrl'
        });

})(angular);
