(function () {
    'use strict';

    // proyecto: Proyecto a crear/editar/leer
    // mode: create|edit|read

    angular
        .module('app')
        .component('proyectoForm', {
            templateUrl: 'app/public/components/proyectos/proyecto/proyecto.form.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                proyecto: '=',
                mode: '@',
                fechaFin: '<',
                grupoNombre: '<'
            }
        });

    /* @ngInject */
    function Controller($timeout, $state, $rootScope, Proyecto) {
        var vm = this;

        vm.libroModal = $state.params.libroModal;

        $timeout(function () {
            angular.element('.form-group>input#nombre').focus();
        });

        vm.item = angular.copy(vm.proyecto);

        function initForm() {
            if (!vm.item.grupos) {
                vm.item.grupos = [];
                vm.item.grupos.push({});
                vm.item.numGrupos = 1;
            }

            if (vm.mode === 'create') {
                vm.item.fechaInicio = new Date();
                //vm.item.fechaFin por defecto sera el fin de curso
            }

            vm.limiteFechas = {
                minDate: 'today',
                maxDate: vm.fechaFin * 1000,
                datepickerMode: 'day'
            }
        }

        function checkCanSave() {
            vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        }

        vm.editMode = function () {
            vm.mode = 'edit';
            checkCanSave();
            initForm();
        };

        vm.cancel = function () {
            if (vm.mode === 'create') {
                $rootScope.back();
            } else {
                angular.extend(vm.item, vm.proyecto);
                vm.mode = 'read';
                checkCanSave();
            }
        };

        vm.actualizarGrupos = function () {
            if (vm.item.numGrupos > vm.item.grupos.length) {
                while (vm.item.numGrupos > vm.item.grupos.length) {
                    vm.item.grupos.push({});
                }
            } else {
                vm.item.grupos = vm.item.grupos.splice(0, vm.item.numGrupos);
            }
        };

        vm.duplicarProyecto = function () {
            Proyecto.duplicar({id: vm.item.id}).$promise.then(function (data) {
                $state.go('miespacio/proyecto/details', {id: data.msg});
            });
        };

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function successCallback(response) {
                if (vm.mode === 'create' && vm.libroModal === null) {
                    $state.go('miespacio/proyecto/list');
                } else if (vm.libroModal !== null && vm.mode === 'create') {
                    $state.go('miespacio/tarea/create', {proyectoId: response.id, libroModal: vm.libroModal});
                } else {
                    vm.mode = 'read';
                    vm.proyecto = angular.copy(vm.item);
                }
            });
        };

        checkCanSave();
        initForm();
    }

})();
