(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('IvaFormAdminController', IvaFormAdminController);

    /* @ngInject */
    function IvaFormAdminController($timeout, $state, itemData, previousParams, EnumService) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'edit';

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                $state.go('admin/iva/list', previousParams);
            });
        };

        EnumService.get('tipo-iva').then(function (result) {
            vm.tiposIva = result;
        });

    }

})(angular);
