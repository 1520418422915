(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    // Tipos de registro: lector (también válido para alumno), profesor, centro-educativo, libreria

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('registro/info', {
                parent: 'public',
                url: '/registro',
                data: {
                    authorities: ['ROLE_ANONYMOUS'],
                    pageTitle: 'global.menu.registro'
                },
                params: {
                    '#': null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/public/cuenta/registro/registro.info.html',
                        controllerAs: 'ctrl',
                        /* @ngInject */
                        controller: function ($anchorScroll, $location) {
                            var vm = this;
                            vm.foco = true;
                            var anchor = $location.hash();
                            console.log("Ancla registro:", anchor);
                            if (anchor) {
                                vm.foco = false;
                                $anchorScroll.yOffset = 30;
                                $anchorScroll(anchor);
                            }
                        }
                    }
                }
            })
            .state('registro', {
                parent: 'public',
                url: '/registro/:tipo',
                data: {
                    pageTitle: 'global.menu.registro'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/public/cuenta/registro/registro.html',
                        controller: 'RegistroController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    packs: function ($stateParams, Pack) {
                        // Se muestra la lista de packs a los Centros educativos para puedan comprar uno
                        if ($stateParams.tipo === 'centro') {
                            return Pack.findAll().$promise.then(function (data) {
                                return data.content;
                            });
                        } else return null;
                    }
                }
            });
    }
})();
