(function () {
    'use strict';

    // club: Club a crear/editar/leer
    // mode: create|edit|read
    // administra: Booleano para saber si es administrador o no

    angular
        .module('app')
        .component('clubForm', {
            templateUrl: 'app/public/components/clubes/club.form.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                club: '=',
                mode: '@',
                administra: '<'
            }
        });

    /* @ngInject */
    function Controller($timeout, $state, $rootScope, Libro, RutasService) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group>input#nombre').focus();
        });

        vm.item = angular.copy(vm.club);

        if (vm.item && vm.item.libro) {
            vm.rutaPortadaFull = RutasService.getPortada(vm.item.libro.id, vm.item.libro.portada, true);
        }

        function initForm() {
            if (vm.mode === 'create') {
                vm.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                vm.item.fechaCreacion = new Date();
                if ($state.params.libroModal !== null) {
                    vm.item.libro = $state.params.libroModal;
                }
            } else if (vm.mode === 'edit') {
                vm.maxDate = new Date(new Date(vm.item.fechaCreacion * 1000).setFullYear(new Date(vm.item.fechaCreacion * 1000).getFullYear() + 1));
            } else {
                vm.editable = new Date() < new Date(vm.item.fechaFin * 1000);
            }
        }

        function checkCanSave() {
            vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        }

        vm.editMode = function () {
            vm.mode = 'edit';
            checkCanSave();
            initForm();
        };

        vm.cancel = function () {
            if (vm.mode === 'create') {
                $rootScope.back();
            } else {
                angular.extend(vm.item, vm.club);
                vm.mode = 'read';
                checkCanSave();
            }
        };

        // Guardar formulario
        vm.save = function () {
            // FIXME: Apaño temporal para la edición de fechas...
            if (angular.isNumber(vm.item.fechaFin)) {
                vm.item.fechaFin = vm.item.fechaFin / 1000;
            }

            vm.item.$save().then(function (data) {
                if (vm.mode === 'create') {
                    $state.go('miespacio/club/details', {id: data.id});
                } else {
                    vm.mode = 'read';
                    vm.club = angular.copy(vm.item);
                }
            });
        };

        checkCanSave();
        initForm();

        vm.libroService = Libro;
    }

})();
