(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('libro', {
            parent: 'public',
            url: '/libro/:tituloAbrev',
            redirectTo: 'libro/comentarios/general',
            data: {
                pageTitle: 'global.menu.catalogo'
            },
            params: {
                deseo: false
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/catalogo/libro.html'
                }
            },
            resolve: {
                /* @ngInject */
                libro: function (Libro, $stateParams) {
                    return Libro.findByUrl({tituloAbrev: $stateParams.tituloAbrev}).$promise.then(function (data) {
                        return data;
                    });
                },
                /* @ngInject */
                relacionados: function (Libro, libro) {
                    return Libro.findRelacionados({id: libro.id, count: 5}).$promise.then(function (data) {
                        return data;
                    });
                },
                /* @ngInject */
                tieneCentros: function (AlumnoCentro, Principal) {
                    return Principal.identity().then(function (data) {
                        if (data && data.rol === 'ROLE_CENTRO') return true;
                        if (Principal.isAuthenticated()) {
                            return AlumnoCentro.tieneCentros().then(function (result) {
                                return result.data;
                            });
                        } else {
                            return false;
                        }
                    });
                }
            }
        }).state('libro/comentarios/general', {
            parent: 'libro',
            views: {
                'contentLibro': {
                    template: '<comentario-list comentarios="$resolve.comentarios" libro-id="$resolve.libro.id"></comentario-list>'
                }
            },
            resolve: {
                /* @ngInject */
                comentarios: function (Comentario, libro) {
                    return Comentario.findAllByLibro({id: libro.id}).$promise.then(function (data) {
                        return data.content;
                    });
                }
            }
        }).state('libro/comentarios/centro', {
            parent: 'libro',
            url: '/centro/:centroId',
            data: {
                authorities: ['ROLE_AUTHENTICATED']
            },
            views: {
                'contentLibro': {
                    template: '<comentario-list comentarios="$resolve.comentarios" libro-id="$resolve.libro.id" ' +
                    'centros="$resolve.centros"></comentario-list>'
                }
            },
            resolve: {
                /* @ngInject */
                comentarios: function ($stateParams, Comentario, libro, centros) {
                    if (centros && centros.length) {
                        if (!$stateParams.centroId) {
                            $stateParams.centroId = centros[0].id;
                        }
                        return Comentario.findAllByLibroAndCentro({
                            libroId: libro.id,
                            centroId: $stateParams.centroId
                        }).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                },
                /* @ngInject */
                centros: function (AlumnoCentro) {
                    return AlumnoCentro.getCentros().then(function (result) {
                        return result.data;
                    });
                }
            }
        });
    }
})();
