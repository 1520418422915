(function () {
    'use strict';

    angular.module('app')
        .factory('Epub', Epub);

    /* @ngInject */
    function Epub(ResourceHelper) {
        return ResourceHelper.getResource("epub", {}, {
            'obtenerCfi': {
                method: 'GET',
                url: 'api/public/epub/obtenerCfi',
                params: {
                    id: '@id'
                },
                isArray: false
            },
            'guardarAvance': {
                method: 'POST',
                params: {
                    libroAdquirido: ""
                },
                url: 'api/public/epub/guardarAvance',
                isArray: false
            }
        });
    }

})();
