(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/categoria";
        var params = {
            base: base,
            baseUrl: "/" + base,
            templateUrlBase: "app/admin/promocion/categoria/categoria",
            controller: 'CategoriaAdminController',
            controllerForm: 'CategoriaFormAdminController',
            translateBase: "admin.promocion.categoria."
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR_CONTENIDO']
            }
        });
        // Listar
        angular.merge(state.list, {});
        // Crear
        angular.merge(state.create, {
            resolve: {
                /* @ngInject */
                itemData: function (Categoria) {
                    return new Categoria();
                }
            }
        });
        // Editar
        angular.merge(state.edit, {
            resolve: {
                /* @ngInject */
                itemData: function (Categoria, $stateParams) {
                    return Categoria.get({id: $stateParams.id}).$promise;
                }
            }
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
