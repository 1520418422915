(function () {
    'use strict';

    angular
        .module('app')
        .component('carroSimple', {
            templateUrl: 'app/public/components/carro/carro.simple.html',
            controller: CarroController,
            controllerAs: 'ctrl',
            bindings: {
                carro: '=',
                edit: '<'
            }
        });

    /* @ngInject */
    function CarroController($state, CarroHelper, Auth) {
        var vm = this;

        if (vm.carro) {
            vm.hayLibros = vm.carro.productos.find(function (producto) {
                if (producto.libro) {
                    return true;
                }
            });
        }

        vm.calcularTotal = function () {
            var total = 0.0;
            if (vm.carro) {
                vm.carro.productos.forEach(function (p) {
                    if (p.libro) {
                        total += p.libro.precioConIva;
                    }
                });
            }
            return total;
        };

        vm.removeFromCarro = function (producto) {
            CarroHelper.removeProducto(producto);
            vm.carro = CarroHelper.get();
            vm.calcularTotal();
        };

        vm.tramitarCompra = function () {
            $state.params.tramitar = true;

            Auth.authorize(false, "ROLE_AUTHENTICATED");

            CarroHelper.validate().then(function (valid) {
                if (valid) {
                    $state.go('compra/crear');
                } else {
                    vm.invalid = true;
                    vm.carro = CarroHelper.get();
                }
            });
        };
    }

})();
