(function () {
    'use strict';

    angular
        .module('app')
        .component('miembroList', {
            templateUrl: 'app/public/components/clubes/miembros/miembro.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                administra: '<',
                clubId: '@'
            }
        });

    /* @ngInject */
    function Controller($stateParams, NG_TABLE_DEFAULT_PARAMS_PUBLIC, NgTableParams, NgTableHelper, Miembro, Principal) {
        var vm = this;

        vm.item = Miembro;
        vm.bloqueados = false;
        vm.function = 'findByClub';
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS_PUBLIC.size;
        vm.filter = {
            key: undefined,
            id: vm.clubId,
            bloqueados: vm.bloqueados
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {'usuario.login': 'asc'}
        }, NgTableHelper.settings(vm));

        Principal.identity().then(function (data) {
            vm.usuarioLogin = data.login;
        });

        vm.bloquearMiembro = function (miembro) {
            Miembro.bloquear({miembroId: miembro.miembroId, bloquear: !miembro.bloqueado}).$promise.then(function () {
                miembro.bloqueado = !miembro.bloqueado;
            });
        }

    }

})();
