(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/misalumnos', {
                parent: 'miespacio',
                url: '/mis-alumnos',
                redirectTo: 'miespacio/misalumnos/list',
                data: {
                    authorities: ['ROLE_CENTRO', 'ROLE_PROFESOR'],
                    pageTitle: 'global.menu.miespacio.title',
                    titulo: 'global.menu.miespacio.misalumnos',
                    tituloImg: 'assets/images/iconos/PROFESORES_alumnos.png',
                }
            })
            .state('miespacio/misalumnos/list', {
                parent: 'miespacio/misalumnos',
                views: {
                    'contentEspacio@miespacio': {
                        template:
                        '<estadisticas-usuario-list></estadisticas-usuario-list>'
                    }
                }
            })
            .state('miespacio/misalumnos/details', {
                parent: 'miespacio/misalumnos',
                url: '/:id',
                views: {
                    'contentEspacio@miespacio': {
                        template: '<datos-usuario usuario="$resolve.usuario"></datos-usuario>' +
                        '<estadisticas-usuario-details estadisticas="$resolve.estadisticas" ' +
                        'estadisticas-lectura="$resolve.estadisticasLectura"></estadisticas-usuario-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    usuario: function (User, $stateParams) {
                        return User.getAlumno({id: $stateParams.id});
                    },
                    /* @ngInject */
                    estadisticas: function ($stateParams, Estadisticas) {
                        return Estadisticas.getEstadisticasUsuario({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    estadisticasLectura: function ($stateParams, Estadisticas) {
                        return Estadisticas.getEstadisticasLectura({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            });
    }
})();
