(function () {
    'use strict';

    angular.module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                parent: 'site',
                views: {
                    'navbar@': {
                        template: '<mi-menu menu="$ctrl.menu" nav-collapsed="$ctrl.navCollapsed" modal="$ctrl.modal(id)"></mi-menu>',
                        controller: ['menuData', '$window', 'StaticModal', function (menuData, $window) {
                            this.menu = menuData;
                            this.navCollapsed = $window.innerWidth < 992;
                        }],
                        controllerAs: '$ctrl',
                        resolve: {
                            menuData: ['Menu',
                                function (Menu) {
                                    return Menu.getMenu('menu-admin');
                                }
                            ]
                        }
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.admin.html',
                        controller: ['APP_VERSION', function (APP_VERSION) {
                            this.version = APP_VERSION;
                        }],
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    isAdmin: function ($rootScope) {
                        $rootScope.isAdmin = true;
                    }
                }
            })
            .state('adminLogin', {
                url: '/admin',
                parent: 'admin',
                redirectTo: 'login'
            })
            // *** Métodos de utilidad ***
            .state('forzarFinCurso', {
                url: '/admin/forzar-fin-curso',
                parent: 'admin',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                resolve: {
                    /* @ngInject */
                    forceFinDeCurso: function (UtilService) {
                        console.log("Comenzando proceso de fin de curso");
                        UtilService.forceFinDeCurso().then(function () {
                            alert("Ejecutado proceso de fin de curso manualmente");
                        });
                    }
                }
            })
            .state('resetearContrasenas', {
                url: '/admin/resetear-contrasenas-para-dev',
                parent: 'admin',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                resolve: {
                    /* @ngInject */
                    resetearContrasenas: function (UtilService) {
                        console.log("Comenzando a resetear contraseñas");
                        UtilService.resetearContrasenas().then(function () {
                            console.log("RESETEADAS");
                            alert("Contraseñas reestablecidas");
                        });
                    }
                }
            })
            .state('migrarDatos', {
                url: '/admin/migrar-datos',
                parent: 'admin',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                resolve: {
                    /* @ngInject */
                    migrarDatos: function (Tarea) {
                        console.log("Comenzando migración de datos");
                        Tarea.updateAulas().$promise.then(function () {
                            alert("Migración realizada correctamente");
                        })
                    }
                }
            })
    }
})();
