(function () {
    'use strict';

    // packs: Lista de packs

    angular
        .module('app')
        .component('packs', {
            templateUrl: 'app/public/components/packs/packs.list.html',
            controller: PacksController,
            controllerAs: 'ctrl',
            bindings: {
                packs: '<',
                propios: '<',
                modoRegistro: '<',
                onPackSeleccionado: '&'
            }
        });

    /* @ngInject */
    function PacksController($state, ModalService) {
        var vm = this;

        vm.verCatalogo = function (pack) {
            ModalService.open({
                    templateUrl: 'app/public/components/packs/packs.catalogo.modal.html',
                    /* @ngInject */
                    controller: function (ModalService) {
                        var vm = this;
                        vm.close = function () {
                            ModalService.close();
                        }
                    },
                    controllerAs: 'ctrl',
                    size: 'lg',
                    resolve: {
                        pack: function () {
                            return pack;
                        }
                    },
                windowClass: 'modalLibros'
                }
            );
        };

        // Eliminar
        vm.showRemoveConfirmation = function (pack) {
            ModalService.open({
                    templateUrl: 'app/components/form/confirm/confirm.modal.html',
                    controller: 'ConfirmModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        item: function () {
                            return pack;
                        },
                        /* @ngInject */
                        tipo: function (Suscripcion) {
                            return Suscripcion;
                        },
                        funcion: function () {
                            return "cancelar";
                        },
                        /* @ngInject */
                        parametros: function () {
                            return {suscripcionId: pack.suscripcionId}
                        },
                        params: function () {
                            return {
                                title: 'catalogo.packs.cancelar.title',
                                body: 'catalogo.packs.cancelar.confirm'
                            };
                        }
                    }
                }
            ).result.then(function () {
                if (pack.activo) {
                    pack.solicitudCancelacion = true;
                } else {
                    $state.reload();
                }
            });
        };

        vm.solicitar = function (pack) {
            if (vm.modoRegistro)  {
                vm.onPackSeleccionado({pack: pack});
                return;
            }

            $state.go('suscripcionPack', {pack: pack});
        };

        vm.obtenerTextoCategoriaCentro = function (numAlumnos) {
            if (numAlumnos !== undefined) {
                var split = numAlumnos.split("_");
                var numero = split[split.length - 1];
                return {numero: numero};
            }

            return undefined;
        };

        vm.obtenerTextoBoton = function (pack) {
            if (vm.modoRegistro) {
                if (pack.seleccionado)
                    return "catalogo.packs.deseleccionar";

                return "catalogo.packs.seleccionar";
            }

            return "catalogo.packs.solicitar"
        };
    }

})();
