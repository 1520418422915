(function () {
    'use strict';

    angular
        .module('app')
        .factory('Slide', Slide);

    Slide.$inject = ['$resource'];

    function Slide($resource) {
        return $resource('api/admin/slide/:slideId', {slideId: '@id'}, {
            'update': {
                method: 'PUT'
            },
            'save': {
                method: 'POST',
                url: 'api/admin/slide'
            },
            'query': {
                method: 'GET',
                url: 'api/admin/slide',
                isArray: true
            },
            "findAll": {
                method: 'GET',
                url: 'api/public/slide',
                isArray: true
            },
            "subir": {
                method: 'PUT',
                url: 'api/admin/slide/:slideId/subir',
                params: {slideId: '@slideId'}
            },
            "bajar": {
                method: 'PUT',
                url: 'api/admin/slide/:slideId/bajar',
                params: {slideId: '@slideId'}
            }
        });
    }
})();
