(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PreguntaAdminController', PreguntaAdminController);

    /* @ngInject */
    function PreguntaAdminController($state, $stateParams, preguntas, NgTableParams, ModalService, Pregunta) {
        var vm = this;

        vm.tableParams = new NgTableParams({});
        vm.preguntas = preguntas;
        vm.titulo = $stateParams.titulo;

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    resolve: {
                        item: function () {
                            return Pregunta.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.libro.pregunta.delete.title',
                                body: 'admin.libro.pregunta.delete.confirm',
                                property: 'id'
                            };
                        }
                    }
                }
            ).result.then(function (result) {
                var pregunta = vm.preguntas[result.bloque.nombre].find(function (pregunta) {
                    return pregunta.id === result.id;
                });
                var index = vm.preguntas[result.bloque.nombre].indexOf(pregunta);
                vm.preguntas[result.bloque.nombre].splice(index, 1);
            });
        };

        // Eliminar todas
        vm.cleanShowConfirmation = function () {
            ModalService.open({
                    templateUrl: 'app/components/form/confirm/confirm.modal.html',
                    controller: 'ConfirmModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        item: function () {
                            return {};
                        },
                        /* @ngInject */
                        tipo: function (Pregunta) {
                            return Pregunta;
                        },
                        funcion: function () {
                            return "eliminarTodas";
                        },
                        /* @ngInject */
                        parametros: function ($stateParams) {
                            return {libroId: $stateParams.libroId}
                        },
                        params: function () {
                            return {
                                title: 'admin.libro.pregunta.delete.todo.title',
                                body: 'admin.libro.pregunta.delete.todo.confirm'
                            };
                        }
                    }
                }
            ).result.then(function () {
                $state.reload();
            });
        };

        vm.enviarExcel = function (temporal, original) {
            Pregunta.importarExcel({
                archivoTemporal: temporal,
                archivoOriginal: original,
                libroId: $stateParams.libroId
            }).$promise.then(function () {
                $state.reload();
            });
        }
    }

})(angular);
