(function () {
    'use strict';

    // adquirido: Libro adquirido.
    // archivado: Boolean. Libro archivado.
    // onOcultar: Función de callback.
    // lectura: Boolean. Modo lectura.
    // simple: Boolean. Oculta información (para clubes).

    angular
        .module('app')
        .component('miLibroDetails', {
            templateUrl: 'app/public/components/mislibros/mis-libros.details.html',
            controller: MisLibrosController,
            controllerAs: 'ctrl',
            bindings: {
                simple: '<',
                adquirido: '<',
                archivado: '<',
                onOcultar: '<',
                lectura: '<'
            }
        });

    /* @ngInject */
    function MisLibrosController($localStorage, OfflineService, RutasService) {
        var vm = this;

        vm.libro = vm.adquirido.libro;

        vm.ocultar = function (ocultar) {
            vm.onOcultar(vm.adquirido, ocultar);
        };

        // FIXME: Convertir estas funciones en atributos. Cuando se pulse descargar, habrá que cambiar el valor del atributo correspondiente.

        vm.existePdfOffline = function (ta) {
            return OfflineService.existePdfOff(ta);
        };

        vm.descargarPdfOffline = function () {
            OfflineService.descargarComponentesPdf(vm.adquirido, function () {
            });
        };

        vm.existeEpubOffline = function (ta) {
            return OfflineService.existeEpubOff(ta);
        };

        vm.descargarEpubOffline = function () {
            OfflineService.descargarComponentesEpub(vm.adquirido, function () {
            });
        };

        vm.checkConnectionPdfEpubOff = function (ta) {
            return !!(navigator.onLine || OfflineService.existePdfOff(ta) || OfflineService.existeEpubOff(ta));
        };

        vm.checkConnectionPdfOff = function (ta) {
            return !!(navigator.onLine || OfflineService.existePdfOff(ta));
        };

        vm.checkConnectionEpubOff = function (ta) {
            return !!(navigator.onLine || OfflineService.existeEpubOff(ta));
        };

        vm.rutaPortada = function (libro) {
            if (navigator.onLine) {
                return RutasService.getPortada(libro.id, libro.portada, false);
            } else {
                for (var i = 0, len = $localStorage.librosAdquiridos.length; i < len; i++) {
                    if ($localStorage.librosAdquiridos[i].libro.id === libro.id) {
                        return $localStorage.librosAdquiridos[i].libro.portadaBase64;
                    }
                }
            }
        };

        vm.mostrarBotonesLectura = function(){
            return vm.adquirido.fechaCaducidad === null || !isCaducado()
        };

        function isCaducado(){
            var hoy = new Date();
            hoy.setHours(0,0,0,0);
            return new Date(vm.adquirido.fechaCaducidad * 1000) < hoy
        }
    }

})();
