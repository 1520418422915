(function () {
    'use strict';

    angular.module('app')
        .factory('Notificacion', Notificacion);

    /* @ngInject */
    function Notificacion($http) {

        var baseUrl = "api/public/notificacion/";

        return {
            contar: contar,
            find: find,
            leer: leer
        };

        function contar(rol, usuarioId) {
            return $http({
                method: 'GET',
                url: baseUrl + 'contar',
                params: {
                    rol: rol,
                    usuarioId: usuarioId
                },
                ignoreLoadingBar: true
            }).then(function (result) {
                return result.data;
            });
        }

        function find(rol, usuarioId, start, count) {
            return $http({
                method: 'GET',
                url: baseUrl + 'find',
                params: {
                    rol: rol,
                    usuarioId: usuarioId,
                    start: start,
                    count: count
                }
            }).then(function (result) {
                return result.data;
            });
        }

        function leer(id) {
            return $http({
                method: 'POST',
                url: baseUrl + 'leer',
                params: {
                    id: id
                }
            });
        }
    }

})();
