(function () {
    'use strict';

    angular.module('app')
        .factory('DeseoHelper', DeseoHelper);

    /* @ngInject */
    function DeseoHelper($state, Deseo, Principal, Auth, ModalService) {

        return {
            getLista: getLista,
            addDeseo: addDeseo,
            removeDeseo: removeDeseo
        };

        function getLista() {
            return Deseo.getLista();
        }

        function addDeseo(libro) {
            $state.params.deseo = true;
            Auth.authorize(false, "ROLE_AUTHENTICATED");

            if (Principal.isAuthenticated()) {
                // Enviamos el Deseo al servidor
                Deseo.addDeseo({libro: libro}).$promise.then(function () {
                    $state.params.deseo = true;
                });

                // Abrimos la ventana de información
                ModalService.open({
                    templateUrl: 'app/public/components/deseo/deseo.modal.html',
                    /* @ngInject */
                    controller: function ($state, itemData, ModalService) {
                        var vm = this;
                        vm.libro = itemData;
                        vm.close = function () {
                            ModalService.close();
                        };
                        vm.verLista = function () {
                            ModalService.close();
                            $state.go('miespacio/deseos');
                        };
                    },
                    controllerAs: 'ctrl',
                    resolve: {
                        itemData: function () {
                            return libro;
                        }
                    }
                });
            }
        }

        function removeDeseo(deseo) {
            return Deseo.remove(deseo);
        }


    }

})();
