(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/usuarios', {
                parent: 'admin',
                redirectTo: 'admin/usuarios/rol',
                url: '/admin/usuarios',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'admin.list-users.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/user/usuarios/usuarios.template.html'
                    }
                },
                ncyBreadcrumb: {
                    label: "global.menu.usuariosactivos"
                }
            })
            .state('admin/usuarios/rol', {
                parent: 'admin/usuarios',
                params: {
                    id: null,
                    rol: 'ROLE_LECTOR'
                },
                views: {
                    'contentUsuarios': {
                        templateUrl: 'app/admin/user/usuarios/usuarios.list.html',
                        controller: 'UsuariosController',
                        controllerAs: 'ctrl'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario', {
                parent: 'admin/usuarios',
                url: '/:id',
                params: {
                    id: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/user/usuarios/usuario.detalles.template.html',
                        controller: function ($state, usuario) {
                            if ($state.current.name === 'admin/usuario') {
                                var pestana = 'admin/usuario/compras';
                                if (usuario.type === 'centro') {
                                    pestana = 'admin/usuario/suscripciones';
                                }
                                $state.go(pestana, null, {location: 'replace'});
                            }
                        }
                    }
                },
                resolve: {
                    /* @ngInject */
                    usuario: function (User, $stateParams) {
                        return User.getById({id: $stateParams.id}).$promise;
                    }
                },
                ncyBreadcrumb: {
                    label: "global.menu.detalles",
                    parent: "admin/usuarios"
                }
            })
            .state('admin/usuario/compras', {
                parent: 'admin/usuario',
                url: '/compras',
                redirectTo: 'admin/usuario/compras/list',
                resolve: {
                    /* @ngInject */
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/compras/list', {
                parent: 'admin/usuario/compras',
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<compras id="$resolve.id"></compras>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/compra', {
                parent: 'admin/usuario/compras',
                url: '/compra/:compraId',
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<compras-details factura="$resolve.factura" id="$resolve.id"></compras-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    factura: function (Factura, $stateParams) {
                        return Factura.get($stateParams.compraId);
                    },
                    /* @ngInject */
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/suscripciones', {
                parent: 'admin/usuario',
                url: '/suscripciones',
                redirectTo: 'admin/usuario/suscripciones/list',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/suscripciones/list', {
                parent: 'admin/usuario/suscripciones',
                url: '',
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<suscripcion-list suscripciones="$resolve.suscripciones"></suscripcion-list>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    suscripciones: function ($stateParams, Suscripcion) {
                        return Suscripcion.findAllByCentro({id: $stateParams.id}).$promise.then();
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/suscripciones/crear', {
                parent: 'admin/usuario/suscripciones',
                url: '/crear',
                data: {
                    mode: 'create'
                },
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<suscripcion-form item="$resolve.item" libros="$resolve.libros" ' +
                            'ivas="$resolve.ivas" num-alumnos="$resolve.numAlumnos"></suscripcion-form>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    item: function ($stateParams) {
                        return {
                            pack: {},
                            centro: {id: $stateParams.id, type: 'centro'}
                        };
                    },
                    /* @ngInject */
                    libros: function ($stateParams, Libro) {
                        return Libro.findAllForPackPersonalizado({centroId: $stateParams.id}).$promise.then(function (result) {
                            return result.content;
                        });
                    },
                    /* @ngInject */
                    ivas: function (Iva) {
                        return Iva.findAll().$promise.then(function (result) {
                            return result.content;
                        });
                    },
                    /* @ngInject */
                    numAlumnos: function (usuario) {
                        return usuario.numAlumnos;
                    }

                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/lecturas', {
                parent: 'admin/usuario',
                url: '/estadisticas',
                params: {
                    id: null
                },
                views: {
                    'contentUsuario': {
                        template: '<estadisticas-usuario-details estadisticas="$resolve.estadisticas" ' +
                            'estadisticas-lectura="$resolve.estadisticasLectura"></estadisticas-usuario-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    estadisticas: function (Estadisticas, $stateParams) {
                        return Estadisticas.getEstadisticasUsuario({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    estadisticasLectura: function (Estadisticas, $stateParams) {
                        return Estadisticas.getEstadisticasLectura({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/profesores', {
                parent: 'admin/usuario',
                url: '/profesores',
                params: {
                    id: null
                },
                views: {
                    'contentUsuario': {
                        template: '<gestion-profesores id="$resolve.id"></gestion-profesores>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/centro/lecturas', {
                parent: 'admin/usuario',
                url: '/estadisticasCentro',
                redirectTo: 'admin/centro/lecturas/detail',
                params: {
                    id: null,
                    usuario: null
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/centro/lecturas/detail', {
                parent: 'admin/centro/lecturas',
                url: '/lecturas',
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<estadisticas-centro-details id="$resolve.id" estadisticas-lectura="$resolve.estadisticasLectura">' +
                            '</estadisticas-centro-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    estadisticasLectura: function (Estadisticas, $stateParams) {
                        return Estadisticas.getEstadisticasLecturaCentro({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    usuario: function ($stateParams) {
                        return $stateParams.usuario;
                    },
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/alumnosadmin', {
                parent: 'admin/centro/lecturas',
                url: '/alumnos',
                views: {
                    'contentUsuario@admin/usuario': {
                        template:
                            '<estadisticas-usuario-list usuario="$resolve.usuario"></estadisticas-usuario-list>'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/proyectos', {
                parent: 'admin/centro/lecturas',
                redirectTo: 'admin/usuario/proyectos/list',
                url: '/proyectos',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/proyectos/list', {
                parent: 'admin/usuario/proyectos',
                url: '',
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<proyectos-admin id="$resolve.usuario.id" activos="true"></proyectos-admin>'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/proyectos/cerrados', {
                parent: 'admin/usuario/proyectos',
                url: '/cerrados',
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<proyectos-admin id="$resolve.usuario.id" activos="false"></proyectos-admin>'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/proyectos/details', {
                parent: 'admin/usuario/proyectos',
                redirectTo: 'admin/usuario/proyectos/details/tareas',
                url: '/:proyectoId',
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<proyecto proyecto="$resolve.proyecto"></proyecto>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    proyecto: function (Proyecto, $stateParams) {
                        return Proyecto.get({id: $stateParams.proyectoId}).$promise;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/proyectos/details/tareas', {
                parent: 'admin/usuario/proyectos/details',
                views: {
                    'contentProyecto': {
                        template: '<tareas proyecto-id="$resolve.id" editable="false"></tareas>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    id: function($stateParams) {
                        return $stateParams.proyectoId;
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('admin/usuario/proyectos/details/tarea/details', {
                parent: 'admin/usuario/proyectos/details/tareas',
                url: '/tarea/:tareaId',
                data: {
                    pageTitle: 'miespacio.proyecto.edit.title',
                    mode: 'edit'
                },
                views: {
                    'contentUsuario@admin/usuario': {
                        template: '<tarea tarea="$resolve.tarea" comentarios="$resolve.comentarios"></tarea>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    tarea: function (Tarea, $stateParams) {
                        return Tarea.get({id: $stateParams.tareaId}).$promise;
                    },
                    /* @ngInject */
                    comentarios: function (Comentario, tarea) {
                        return Comentario.findAllByTarea({id: tarea.id}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }
})();
