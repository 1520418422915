(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('buscador', {
            parent: 'public',
            url: '/buscador',
            data: {
                pageTitle: 'buscador.avanzada'
            },
            views: {
                'content@': {
                    template: '<buscador-avanzado></buscador-avanzado>'
                }
            }
        });
    }
})();
