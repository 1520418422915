(function () {
    'use strict';

    angular.module('app')
        .factory('NotificacionHelper', NotificacionHelper);

    /* @ngInject */
    function NotificacionHelper($interval, NOTIFICACIONES_TIME) {

        var counting = false;

        return {
            initCount: initCount
        };

        function initCount(callback) {
            if (!counting) {
                $interval(callback, NOTIFICACIONES_TIME);
                counting = true;
            }
        }

    }

})();
