(function () {
    'use strict';

    angular
        .module('app')
        .component('suscripcionList', {
            templateUrl: 'app/public/components/suscripciones/suscripcion.list.html',
            controller: suscripcionListController,
            controllerAs: 'ctrl',
            bindings: {
                suscripciones: '<'
            }
        });

    /* @ngInject */
    function suscripcionListController($state, $stateParams, ModalService) {
        var vm = this;

        vm.showExistentes = function () {
            ModalService.open({
                    templateUrl: 'app/admin/pack/pack.list.modal.html',
                    controller: 'PackListModalController',
                    controllerAs: 'modalCtrl',
                    resolve: {
                        /* @ngInject */
                        packs: function (Pack) {
                            return Pack.filterForCentro({
                                sortProperty: 'nombre',
                                propios: false,
                                adminContext: true,
                                centroId: $stateParams.id
                            }).$promise.then(function (data) {
                                return data.content;
                            });
                        },
                        centroId: function () {
                            return $stateParams.id
                        }
                    },
                    size: 'lg'
                }
            ).result.then(function () {
                $state.reload();
            });
        }
    }

})();
