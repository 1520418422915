(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PerfilFormController', PerfilFormController);

    /* @ngInject */
    function PerfilFormController($timeout, $state, itemData, Usuario, EnumService, Principal, ModalService, AlertService) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group>input#nombre').focus();
        });

        vm.mode = 'create';

        function initForm() {
            vm.item = angular.copy(itemData);

            initImagen();
        }

        initForm();

        // Guardar formulario
        vm.save = function () {
            if (!vm.item.confirmacionInicioCurso) {
                AlertService.clearPermanent(true);
            }

            vm.item.confirmacionInicioCurso = true;
            if (vm.item.centro) {
                vm.item.centro.type = 'centro';
            }

            // FIXME: Apaño temporal para la edición de fechas...
            if (angular.isNumber(vm.item.fechaNacimiento)) {
                vm.item.fechaNacimiento = vm.item.fechaNacimiento / 1000;
            }

            Usuario.edit(vm.item).$promise.then(function (data) {
                vm.item.fechaNacimiento = data.fechaNacimiento * 1000;
                Principal.identity(true);
                // Si es un profesor y se ha desactivado, recargamos para que se actualice el menú
                if (!data.activado || data.centro == null) {
                    $state.reload();
                }
            });
        };

        // Foto de perfil
        function initImagen() {
            if (!vm.item.imagen) {
                vm.item.imagen = {};
            }
            if (vm.item.foto) {
                vm.item.imagen.path = vm.item.foto;
            }
        }

        vm.onRemoveImage = function () {
            vm.item.imagen = {eliminar: true};
        };

        // Darse de baja

        vm.bajaConfirmation = function () {
            ModalService.open({
                    templateUrl: 'app/components/form/confirm/confirm.modal.html',
                    controller: 'ConfirmModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        item: function () {
                            return {};
                        },
                        /* @ngInject */
                        tipo: function (User) {
                            return User;
                        },
                        funcion: function () {
                            return "baja";
                        },
                        parametros: function () {
                        },
                        params: function () {
                            return {
                                title: 'miespacio.miperfil.baja.titulo',
                                body: 'miespacio.miperfil.baja.mensaje'
                            };
                        }
                    }
                }
            ).result.then(function () {
                $state.go("logout");
            });
        };


        // Limpia el campo centro cuando se desmarca el check 'profesor independiente'
        vm.cleanCentro = function () {
            vm.item.centro = null;
        };

        // Enumerados

        EnumService.get("sexo").then(function (data) {
            vm.sexos = data;
        });

        EnumService.get("modalidad").then(function (data) {
            vm.modalidades = data;
        });

        EnumService.get("numAlumnos").then(function (data) {
            vm.categoriasCentro = data;
        });

        vm.usuarioService = Usuario;

    }
})(angular);
