(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PreguntaFormAdminController', PreguntaFormAdminController);

    /* @ngInject */
    function PreguntaFormAdminController($timeout, $state, itemData, $stateParams, previousParams) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group>input:eq(0)').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        vm.canEdit = true;
        vm.continuar = true;

        function initForm() {
            vm.titulo = $stateParams.titulo;
            vm.item.bloque = $stateParams.bloque;
            if (vm.item.bloque === 'BLOQUE_3_A' || vm.item.bloque === 'BLOQUE_3_B') {
                vm.item.tipo = 'ABIERTA';
            } else {
                vm.item.tipo = 'TEST';

                if (!vm.item.subbloque) {
                    vm.item.subbloque = $stateParams.subbloque ? $stateParams.subbloque.toString() : "1";
                } else {
                    vm.item.subbloque = vm.item.subbloque + "";
                }

                vm.subbloques = [];
                var numSubbloques = 0;
                if (vm.item.bloque === 'BLOQUE_1') {
                    numSubbloques = 13;
                } else if (vm.item.bloque === 'BLOQUE_2_A') {
                    numSubbloques = 12;
                } else if (vm.item.bloque === 'BLOQUE_2_B') {
                    numSubbloques = 12;
                }
                for (var i = 1; i <= numSubbloques; i++) {
                    vm.subbloques.push({id: i});
                }
            }

            if (!vm.item.libro) {
                vm.item.libro = {id: $stateParams.libroId};
            }
            if (!vm.item.respuestas) {
                vm.item.respuestas = [{correcta: true}, {}, {}, {}];
            }
        }

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                // Limpieza del formulario
                vm.titulo = "";
                vm.item.bloque = "";
                vm.item.respuestas = [{}, {}, {}, {}];

                var obligatorios = angular.element("text-angular.ng-invalid-required");

                if (obligatorios) {
                    if (vm.continuar) {
                        // $state.reload();
                        $state.go($state.current, {subbloque: vm.item.subbloque}, {reload: true});
                    } else {
                        $state.go('admin/pregunta/list', previousParams);
                    }
                }
            });
        };

        initForm();
    }

})(angular);
