(function () {
    'use strict';

    angular.module('app')
        .factory('Idioma', Idioma);

    /* @ngInject */
    function Idioma(ResourceHelper) {
        return ResourceHelper.getResource("idioma");
    }

})();
