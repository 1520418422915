(function () {
    'use strict';

    // id: ID del centro.
    // Si es diferente de null, es el administrador el que está obteniendo los profesores de un centro.
    // Si ID es null, es el propio centro el que obtiene sus profesores.

    angular.module('app')
        .component('gestionProfesores', {
            templateUrl: 'app/public/components/profesores/gestion-profesores.html',
            bindings: {
                id: '<'
            },
            controller: GestionProfesoresController,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function GestionProfesoresController($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
                                         User, EnumService, ModalService) {
        var vm = this;

        vm.item = User;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        if (vm.id == null) {
            vm.filter = {
                key: undefined,
                rol: 'ROLE_PROFESOR',
                validado: true
            };
            vm.function = "findProfesores";
        } else {
            vm.filter = {
                key: undefined,
                id: vm.id,
                rol: 'ROLE_PROFESOR',
                validado: null
            };
            vm.function = "findProfesoresByAdmin";
        }
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {apellidos: 'asc'}
        }, NgTableHelper.settings(vm));

        vm.sendValidationEmail = function (email) {
            User.email({email: email});
        };

        vm.activar = function (usuario) {
            User.activar({email: usuario.email, activar: !usuario.activado}).$promise.then(function () {
                vm.tableParams.reload();
            });
        };

        vm.desvincular = function (usuario) {
            ModalService.open({
                templateUrl: 'app/components/form/confirm/confirm.modal.html',
                controller: 'ConfirmModalController',
                controllerAs: 'ctrl',
                resolve: {
                    item: function () {
                        return {email: usuario.email};
                    },
                    /* @ngInject */
                    tipo: function (User) {
                        return User;
                    },
                    funcion: function () {
                        return "desvincularProfesor";
                    },
                    parametros: function () {
                        return {email: usuario.email};
                    },
                    params: function () {
                        return {
                            title: 'admin.list-users.gestion.desvincular.title',
                            body: 'admin.list-users.gestion.desvincular.body',
                            property: 'email'
                        };
                    }
                }
            }).result.then(function () {
                vm.tableParams.reload();
            });
        }

        // Enumerados
        EnumService.get("rol").then(function (result) {
            vm.roles = result;
        });

    }
})();
