(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/proyecto', {
                parent: 'miespacio',
                redirectTo: 'miespacio/proyecto/list',
                url: '/proyectos',
                data: {
                    authorities: ['ROLE_CENTRO', 'ROLE_PROFESOR', 'ROLE_LECTOR'],
                    titulo: 'miespacio.proyecto.list.title',
                    tituloImg: 'assets/images/iconos/tarea.png',
                    clase: 'proyectoState'
                }
            })
            .state('miespacio/proyecto/tareasByLibro', {
                parent: 'miespacio/proyecto',
                url: '/tareas-asociadas/:libroId',
                views: {
                    'contentEspacio@miespacio': {
                        template: '<tareas-by-libro tareas="$resolve.tareas"></tareas-by-libro>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    tareas: function (Tarea, $stateParams) {
                        return Tarea.findByProfesorYLibro({libroId: $stateParams.libroId}).$promise;
                    }
                }
            })
            .state('miespacio/proyecto/list', {
                parent: 'miespacio/proyecto',
                url: '/listar',
                params: {
                    activos: true
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<proyectos activos="$resolve.activos"></proyectos>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    activos: function ($stateParams) {
                        return $stateParams.activos;
                    }
                }
            })
            .state('miespacio/proyecto/create', {
                parent: 'miespacio/proyecto',
                url: '/crear',
                data: {
                    authorities: ['ROLE_PROFESOR'],
                    pageTitle: 'miespacio.proyecto.create.title',
                    mode: 'create'
                },
                params: {
                    libroModal: null
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<proyecto-form proyecto="$resolve.proyecto" mode="create" fecha-fin="$resolve.fechaFin"></proyecto-form>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    proyecto: function (Proyecto) {
                        return new Proyecto();
                    },
                    /* @ngInject */
                    fechaFin: function (Configuracion) {
                        return Configuracion.getFechaFinCurso().$promise.then(function (result) {
                            return result.data;
                        });
                    }
                }
            })
            .state('miespacio/proyecto/details', {
                parent: 'miespacio/proyecto',
                url: '/:id',
                redirectTo: 'miespacio/proyecto/details/alumnos',
                data: {
                    pageTitle: 'miespacio.proyecto.edit.title',
                    mode: 'edit'
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<proyecto proyecto="$resolve.proyecto" grupo-nombre="$resolve.grupoNombre"></proyecto>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    proyecto: function (Proyecto, $stateParams) {
                        return Proyecto.get({id: $stateParams.id}).$promise;
                    },
                    /* @ngInject */
                    grupoNombre: function (proyecto, Proyecto, Principal) {
                        return Principal.identity().then(function (data) {
                            if (data.rol === 'ROLE_LECTOR') {
                                return Proyecto.getNombreGrupo({proyectoId: proyecto.id}).$promise;
                            } else {
                                return null;
                            }
                        });
                    }
                }
            })
            .state('miespacio/proyecto/details/tareas', {
                parent: 'miespacio/proyecto/details',
                views: {
                    'contentProyecto@miespacio/proyecto/details': {
                        template: '<tareas proyecto-id="ctrl.proyecto.id" editable="ctrl.proyecto.modificable"></tareas>'
                    }
                }
            })
            .state('miespacio/proyecto/details/alumnos', {
                parent: 'miespacio/proyecto/details',
                views: {
                    'contentProyecto@miespacio/proyecto/details': {
                        template: '<alumnos-proyecto proyecto="ctrl.proyecto" bloqueados="false" editable="ctrl.proyecto.modificable"></alumnos-proyecto>'
                    }
                }
            });
    }
})();
