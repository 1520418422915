(function () {
    'use strict';

    // tarea: tarea a crear/editar/leer
    // mode: create|edit|read

    angular
        .module('app')
        .component('tareaForm', {
            templateUrl: 'app/public/components/proyectos/tarea/tarea.form.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tarea: '<',
                mode: '@'
            }
        });

    /* @ngInject */
    function Controller($state, $window, $rootScope, Libro, AlertService, DIA_EN_MILIS, Configuracion, Tarea) {
        var vm = this;

        vm.libroModal = $state.params.libroModal;

        vm.item = angular.copy(vm.tarea);
        if (vm.item == null) {
            vm.item = {};
        }


        //vm.description = '';
        vm.fechaFin;

        Configuracion.getFechaFinCurso().$promise.then(function (result) {
            vm.fechaFin = result.data;
        });

        vm.fechaExamenOptions = {
            minDate: 'today',
            maxDate: vm.fechaFin * 1000,
            datepickerMode: 'day'
        };

        function initForm() {
            if ($state.params.libroModal != null) {
                vm.item.libro = $state.params.libroModal;
            }

            if (vm.mode === 'edit' && vm.item.id) {
                vm.item.evaluable = !vm.item.noEvaluable;
            }
            vm.item.tipo = 'LECTURA';
            if (!vm.item.examen) {
                vm.item.examen = {duracion: 55};
            }
            if (!vm.item.examenes) {
                if (vm.item.proyecto != null && vm.item.proyecto.tieneGrupos) {
                    vm.item.examenes = [];
                    vm.item.proyecto.grupos.forEach(function (grupo) {
                        vm.item.examenes.push({grupo: grupo, duracion: 55});
                    });
                }
            } else {
                if (!vm.item.examenPorGrupo) {
                    vm.item.examen = vm.item.examenes[0];
                }
            }
        }

        function checkCanSave() {
            vm.canSave = vm.mode === 'create' || vm.mode === 'edit';
        }

        vm.checkHide = function () {
            if (vm.item.libro.hasOwnProperty('evaluable')) {
                if (!vm.item.libro.evaluable) {
                    return true;
                }
            } else if (vm.item.libro.hasOwnProperty('examinable')) {
                if (!vm.item.libro.examinable) {
                    return true;
                }
            } else {
                return false;
            }
        };


        vm.checkEvaluable = function () {

            if (!vm.item.hasOwnProperty('libro')) {
                vm.item.evaluable = false;
                AlertService.error("Seleccione libro primero");
            } else if (vm.item.libro.hasOwnProperty('evaluable')) {
                if (!vm.item.libro.evaluable) {
                    vm.item.evaluable = false;
                    AlertService.warning("El libro seleccionado no es evaluable.");
                }
            } else if (vm.item.libro.hasOwnProperty('examinable')) {
                if (!vm.item.libro.examinable) {
                    vm.item.evaluable = false;
                    AlertService.warning("El libro seleccionado no es evaluable.");
                }
            }

        };

        // FIXME: Cuando pulso Editar -> Cancelar -> Editar -> Cancelar, la fecha se muestra mal

        vm.paginaAnterior = function () {
            $window.history.back();
        };

        vm.editMode = function () {
            vm.mode = 'edit';
            checkCanSave();
            initForm();
        };

        vm.cancel = function () {
            if (vm.mode === 'create') {
                $rootScope.back();
            } else {
                angular.extend(vm.item, vm.tarea);
                vm.mode = 'read';
                checkCanSave();
            }
        };

        // Guardar formulario
        vm.save = function () {
            vm.item.noEvaluable = !vm.item.evaluable;
            if (!vm.item.examenPorGrupo) {
                vm.item.examenes = [vm.item.examen];
            }

            var examen;
            for (var i = 0; i < vm.item.examenes.length; i++) {
                examen = vm.item.examenes[i];
                if (examen && examen.fechaInicio) {
                    var fechaInicio = angular.copy(examen.fechaInicio);
                    if (!angular.isNumber(examen.fechaInicio)) {
                        fechaInicio = examen.fechaInicio.getTime();
                        examen.fechaInicio.setSeconds(0);
                    }
                    if (fechaInicio < new Date()) {
                        AlertService.error("miespacio.proyecto.tarea.error.fecha");
                        return;
                    }
                    // FIXME: Apaño temporal para la edición de fechas...
                    if (angular.isNumber(examen.fechaInicio)) {
                        examen.fechaInicio = examen.fechaInicio / 1000;
                    }
                }
            }

            /*vm.item.$save().then(function (data) {
                if (vm.mode === 'create') {
                    $state.go('miespacio/tarea/details', {id: data.id});
                } else {
                    $state.go('miespacio/tarea/details', {id: data.id}, {reload: true});
                }
            });*/

            Tarea.save(vm.item).$promise.then(function successCallback(response) {
                if (vm.mode === 'create') {
                    $state.go('miespacio/tarea/details', {id: response.id, proyectoId: response.proyecto.id});
                } else {
                    $state.go('miespacio/tarea/details', {id: response.id, proyectoId: response.proyecto.id}, {reload: true});
                }
            }, function errorCallback(response) {
                console.log(response.statusText);
            });

        };

        checkCanSave();
        initForm();

        vm.libroService = Libro;
    }

})();
