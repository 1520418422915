(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/club', {
                parent: 'miespacio',
                redirectTo: 'miespacio/club/list',
                url: '/mis-clubes',
                data: {
                    authorities: ['ROLE_PROFESOR', 'ROLE_LECTOR', 'ROLE_LIBRERIA'],
                    titulo: 'miespacio.club.list.title',
                    tituloImg: 'assets/images/iconos/club.png',
                    clase: 'clubState'
                }
            })
            .state('miespacio/club/list', {
                parent: 'miespacio/club',
                url: '/listar',
                views: {
                    'contentEspacio@miespacio': {
                        templateUrl: 'app/public/cuenta/miespacio/clubes/clubes.html'
                    }
                }
            })
            .state('miespacio/club/create', {
                parent: 'miespacio/club',
                url: '/crear',
                params: {
                    libroModal: null
                },
                views: {
                    'contentEspacio@miespacio': {
                        template: '<club-form club="$resolve.club" mode="create" administra="true"></club-form>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    club: function (Club) {
                        return new Club();
                    }
                }
            })
            .state('miespacio/club/details', {
                parent: 'miespacio/club',
                url: '/:id',
                redirectTo: 'miespacio/club/details/miembros',
                views: {
                    'contentEspacio@miespacio': {
                        template: '<club-details club="$resolve.club" comentarios="$resolve.comentarios" ' +
                            'libro="$resolve.libro" administra="$resolve.administra"></club-details>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    club: function (Club, $stateParams) {
                        return Club.get({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    administra: function (club, Principal) {
                        return Principal.identity().then(function (data) {
                            return data.email === club.administrador.email;
                        });
                    },
                    /* @ngInject */
                    comentarios: function (Comentario, club) {
                        return Comentario.findAllByClub({id: club.id}).$promise.then(function (data) {
                            return data.content;
                        });
                    },
                    /* @ngInject */
                    libro: function ($localStorage, club, Libro) {
                        return Libro.findByUrl({tituloAbrev: club.libro.tituloAbrev}).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            })
            .state('miespacio/club/details/miembros', {
                parent: 'miespacio/club/details',
                views: {
                    'contentClub': {
                        template: '<miembro-list club-id="{{ctrl.club.id}}" administra="ctrl.administra"></miembro-list>'
                    }
                }
            })
            .state('miespacio/club/details/invitaciones', {
                parent: 'miespacio/club/details',
                views: {
                    'contentClub': {
                        template: '<invitaciones club-id="{{ctrl.club.id}}"></invitaciones>'
                    }
                }
            });
    }
})();
