(function () {
    'use strict';

    angular.module('app')
        .factory('ExamenHelper', ExamenHelper);

    /* @ngInject */
    function ExamenHelper(ModalService) {

        return {
            verEjecucion: verEjecucion,
            finalizar: finalizar
        };

        function verEjecucion(tareaId, examenId, ejecucionId) {
            return ModalService.open({
                templateUrl: 'app/public/cuenta/miespacio/proyecto/examen.modal.html',
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: function (ModalService) {
                    var vm = this;

                    vm.dismiss = function () {
                        ModalService.close();
                    };
                },
                size: 'lg',
                resolve: {
                    /* @ngInject */
                    tarea: function (Tarea) {
                        return Tarea.getLight({id: tareaId}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    bloques: function (Examen) {
                        return Examen.findPreguntasPorBloque({examenId: examenId}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    ejecucion: function (Ejecucion) {
                        return Ejecucion.get({id: ejecucionId}).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            });
        }

        function finalizar(timeout) {
            return ModalService.open({
                templateUrl: 'app/public/components/proyectos/examen/examen.finalizar.modal.html',
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: function (ModalService) {
                    var vm = this;
                    vm.close = function () {
                        ModalService.close();
                    };
                },
                backdrop: 'static',
                resolve: {
                    timeout: function () {
                        return timeout;
                    }
                }
            });
        }

    }

})();
