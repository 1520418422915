(function () {
    'use strict';

    angular.module('app')
        .factory('EstadisticasUtil', EstadisticasUtil);

    function EstadisticasUtil() {

        return {
            getEstadisticas: getEstadisticas,
            updateFechas: updateFechas
        };

        /**
         * Devuelve las estadísticas de un campo en concreto.
         *
         * @param estadisticasCompletas
         * @param campo Tipo de los datos de la gráfica a mostrar (fecha|tiempo|palabras|velocidad)
         * @returns Lista de estadísticas
         */
        function getEstadisticas(estadisticasCompletas, campo) {
            var estadisticas = [];
            for (var i = 0, size = estadisticasCompletas.length; i < size; i++) {
                switch (campo) {
                    case "fecha":
                        var dateAux = new Date(estadisticasCompletas[i].fecha * 1000);
                        dateAux.setDate(dateAux.getDate() + 1);
                        estadisticas.push(dateAux.toISOString().substring(0, 10));
                        break;
                    case "tiempo":
                        estadisticas.push(parseInt(estadisticasCompletas[i].tiempo / 60));
                        break;
                    case "palabras":
                        estadisticas.push(estadisticasCompletas[i].palabras / 100);
                        break;
                    case "velocidad":
                        estadisticas.push(Math.round((estadisticasCompletas[i].velocidad) * 100) / 100);
                        break;
                }
            }
            return estadisticas;
        }

        /**
         * Actualiza los datos que se muestran en función del rango de fechas.
         *
         * @param fechaInicio
         * @param fechaFin
         * @param chart
         * @param estadisticasCompletas
         * @param tipoFecha (dia|mes|trimestre|anho)
         */
        function updateFechas(fechaInicio, fechaFin, chart, estadisticasCompletas, tipoFecha) {
            var arrayFechas = getEstadisticas(estadisticasCompletas, "fecha");
            var arrayTiempos = getEstadisticas(estadisticasCompletas, "tiempo");
            var arrayPalabras = getEstadisticas(estadisticasCompletas, "palabras");
            var arrayVelocidades = getEstadisticas(estadisticasCompletas, "velocidad");

            var arrayEstadisticasCompletas = [];
            var dateAux;
            for (var i = 0; i < arrayFechas.length; i++) {
                dateAux = new Date(arrayFechas[i]);
                dateAux.setDate(dateAux.getDate() - 1);
                if ((fechaInicio.getTime() <= new Date(arrayFechas[i]).getTime()) && (dateAux.getTime() <= fechaFin.getTime())) {
                    arrayEstadisticasCompletas[i] = {
                        fecha: new Date(arrayFechas[i]),
                        tiempo: arrayTiempos[i],
                        palabras: arrayPalabras[i],
                        velocidad: arrayVelocidades[i]
                    }
                }
            }
            while ((typeof arrayEstadisticasCompletas[0] == 'undefined') && (arrayEstadisticasCompletas.length > 0)) {
                arrayEstadisticasCompletas.shift();
            }
            arrayEstadisticasCompletas.reverse();
            var groupedBy = _.groupBy(arrayEstadisticasCompletas, function (date) {
                switch (tipoFecha) {
                    case "dia":
                        return date.fecha.toISOString().substring(0, 10);
                        break;
                    case "mes":
                        return date.fecha.toISOString().substring(0, 7);
                        break;
                    case "trimestre":
                        return date.fecha.toISOString().substring(0, 4) + '-'
                            + ((moment(date.fecha, "YYYY-MM-DD").quarter() - 1) * 3 + 1).toString();
                        break;
                    case "anho":
                        return date.fecha.toISOString().substring(0, 4);
                        break;
                }
            });
            var aggregateByDate = _.map(groupedBy, function (invoiceObject, fecha) {
                var tiempo = _.reduce(invoiceObject, function (m, x) {
                    return m + x.tiempo;
                }, 0);
                var palabras = _.reduce(invoiceObject, function (m, x) {
                    return m + x.palabras;
                }, 0);
                return {
                    fecha: fecha,
                    tiempo: tiempo,
                    palabras: palabras,
                    velocidad: (palabras * 100) / tiempo
                };
            });
            var fechas = [];
            var tiempos = [];
            var palabras = [];
            var velocidades = [];
            for (i = 0; i < aggregateByDate.length; i++) {
                fechas.push(aggregateByDate[i].fecha);
                tiempos.push(Math.round(aggregateByDate[i].tiempo * 100) / 100);
                palabras.push(Math.round(aggregateByDate[i].palabras * 100) / 100);
                velocidades.push(Math.round(aggregateByDate[i].velocidad * 100) / 100);
            }
            chart.data.labels = fechas;
            chart.data.datasets[0].data = tiempos;
            chart.data.datasets[1].data = palabras;
            chart.data.datasets[2].data = velocidades;
            switch (tipoFecha) {
                case "dia":
                    angular.merge(chart.options.scales.xAxes[0].time, {
                        unit: 'day', min: new Date(fechaInicio),
                        max: new Date(fechaFin)
                    });
                    break;
                case "mes":
                    angular.merge(chart.options.scales.xAxes[0].time, {
                        unit: 'month', min: new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), 1),
                        max: new Date(fechaFin.getFullYear(), fechaFin.getMonth(), 1)
                    });
                    break;
                case "trimestre":
                    angular.merge(chart.options.scales.xAxes[0].time, {
                        unit: 'quarter',
                        min: new Date(fechaInicio.getFullYear(), (moment(fechaInicio, "YYYY-MM-DD").quarter() - 1) * 3, 1),
                        max: new Date(fechaFin.getFullYear(), (moment(fechaFin, "YYYY-MM-DD").quarter() - 1) * 3, 1)
                    });
                    break;
                case "anho":
                    angular.merge(chart.options.scales.xAxes[0].time, {
                        unit: 'year', min: new Date(fechaInicio.getFullYear(), 0, 1),
                        max: new Date(fechaFin.getFullYear() + 1, 0, 1)
                    });
                    break;
            }
            chart.update();
        }

    }

})();
