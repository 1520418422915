(function () {
    'use strict';

    // tarea: Tarea de la que se va a mostrar la información
    // comentarios: Comentarios asociados a la tarea

    angular
        .module('app')
        .component('tarea', {
            templateUrl: 'app/public/components/proyectos/tarea/tarea.details.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tarea: '<',
                proyecto: '<',
                comentarios: '<'
            }
        });

    function Controller(Principal) {
        var vm = this;

        Principal.identity().then(function (data) {
            if (data.rol === 'ROLE_LECTOR') {
                vm.esAlumno = true;
            }
        });

    }

})();
