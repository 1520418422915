(function () {
    'use strict';

    angular.module('app')
        .factory('Proyecto', Proyecto);

    /* @ngInject */
    function Proyecto(ResourceHelper) {
        var entidad = 'proyecto';

        return ResourceHelper.getResource(entidad, {}, {
            'findByUsuarioActivo': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findByUsuarioActivo',
                isArray: false
            },
            'findAlumnosConNotas': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findAlumnosConNotas',
                isArray: true
            },
            'findAlumnos': {
                method: 'GET',
                url: 'api/public/' + entidad + '/findAlumnos',
                isArray: false
            },
            'bloquearAlumno': {
                method: 'GET',
                url: 'api/public/' + entidad + '/bloquearAlumno'
            },
            'unirse': {
                method: 'GET',
                url: 'api/public/' + entidad + '/unirse',
                isArray: false
            },
            'cerrar': {
                method: 'GET',
                url: 'api/public/' + entidad + '/cerrar'
            },
            'reabrir': {
                method: 'GET',
                url: 'api/public/' + entidad + '/reabrir'
            },
            'duplicar': {
                method: 'GET',
                url: 'api/public/' + entidad + '/duplicar'
            },
            'getNombreGrupo': {
                method: 'GET',
                url: 'api/public/' + entidad + '/getNombreGrupo'
            }
        }, true);
    }

})();
