(function () {
    'use strict';

    angular.module('app')
        .factory('LibroAdquirido', LibroAdquirido);

    /* @ngInject */
    function LibroAdquirido(ResourceHelper) {
        return ResourceHelper.getResource("libro-adquirido", {}, {
            'getByLibroAndUsuarioActivo': {
                method: 'GET',
                url: 'api/public/libro-adquirido/getByLibroAndUsuarioActivo'
            },
            'getByLibroAndUsuario': {
                method: 'GET',
                url: 'api/public/libro-adquirido/getByLibroAndUsuario'
            },
            'filter': {
                method: 'GET',
                params: {
                    estado: 'TODOS'
                },
                url: 'api/public/libro-adquirido/filter',
                isArray: true
            },
            'ocultar': {
                method: 'POST',
                params: {
                    id: '@id',
                    ocultar: '@ocultar'
                },
                url: 'api/public/libro-adquirido/ocultar'
            },
            'cogerPrestado': {
                method: 'GET',
                url: 'api/public/libro-adquirido/cogerPrestado'
            },
            'ultimaPaginaLeida': {
                method: 'GET',
                url: 'api/public/libro-adquirido/ultimaPaginaLeida',
                params: {
                    id: '@id'
                },
                isArray: false
            }
        });
    }

})();
