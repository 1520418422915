(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('compra', {
            parent: 'public',
            redirectTo: 'compra/get',
            url: '/compra',
            params: {
                compra: null
            },
            data: {
                authorities: ['ROLE_AUTHENTICATED'],
                pageTitle: 'global.menu.compra'
            }
        }).state('compra/crear', {
            parent: 'compra',
            params: {
                centroId: null
            },
            resolve: {
                /* @ngInject */
                foo: function ($state, $stateParams, Compra) {
                    console.log("Creando compra:", $stateParams);
                    Compra.crear().$promise.then(function () {
                        $state.go('compra/get');
                    });
                }
            }
        }).state('compra/get', {
            parent: 'compra',
            url: '',
            params: {
                compra: null
            },
            data: {
                pageTitle: 'global.menu.compra'
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/compra/compra.resumen.html',
                    controller: 'CompraController',
                    controllerAs: 'ctrl'
                }
            },
            resolve: {
                /* @ngInject */
                compra: function (Compra, $state) {
                    return Compra.get().$promise.then(function (result) {
                        console.log("compra:" + result);
                        if (!result.id) {
                            $state.go('carro');
                        }
                        return result;
                    });
                }
            }
        }).state('compra/paypal', {
            parent: 'compra',
            url: '/confirmar-paypal?compraId&paymentId&token',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/compra/compra.success.html',
                    /* @ngInject */
                    controller: function ($state, $stateParams, Compra, CarroHelper, Principal, Factura) {
                        var vm = this;
                        vm.loading = true;
                        console.log("Finalizando compra");

                        Compra.confirmarPaypal({
                            compraId: $stateParams.compraId,
                            paymentId: $stateParams.paymentId,
                            token: $stateParams.token
                        }).$promise.then(function (result) {
                            vm.loading = false;
                            CarroHelper.clear();
                            console.log("Compra terminada:" + result);
                            $state.go("miespacio/mislibros");

                            Principal.identity().then(function (data) {
                                if (data.type === 'libreria') {
                                    Factura.descargarCliente($stateParams.compraId);
                                }
                            });

                        }, function () {
                            vm.loading = false;
                            vm.error = true;
                        });
                    },
                    controllerAs: 'ctrl'
                }
            }
        }).state('compra/cancel', {
            parent: 'compra',
            url: '/cancelar?compraId&token',
            views: {
                'content@': {
                    templateUrl: 'app/public/compra/compra.success.html',
                    /* @ngInject */
                    controller: function ($state, $stateParams, Compra, AlertService) {
                        console.log("cancelando compra");

                        Compra.cancelar().$promise.then(function (result) {
                            console.log("Compra cancelada: " + result);
                            AlertService.warning("compra.compra.cancel");
                            $state.go('carro');
                        });
                    },
                    controllerAs: 'ctrl'
                }
            }
        });
    }
})();
