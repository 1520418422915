(function () {
    'use strict';

    angular.module('app')
        .factory('GrupoEdad', GrupoEdad);

    /* @ngInject */
    function GrupoEdad(ResourceHelper) {
        return ResourceHelper.getResource("grupo-edad", {}, {
            'subir': {
                method: 'GET',
                url: 'api/admin/grupo-edad/subir'
            },
            'bajar': {
                method: 'GET',
                url: 'api/admin/grupo-edad/bajar'
            }
        });
    }

})();
