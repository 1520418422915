(function () {
    'use strict';

    angular.module('app')
        .factory('NotaEpub', NotaEpub);

    /* @ngInject */
    function NotaEpub(ResourceHelper) {
        return ResourceHelper.getResource("nota-epub", {}, {
            'findByLibroAdquirido': {
                method: 'GET',
                url: 'api/public/nota-epub/findByLibroAdquirido',
                isArray: true
            },
            'eliminar': {
                method: 'DELETE',
                url: 'api/public/nota-epub/eliminar'
            }
        }, true);
    }

})();
