(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/pregunta";
        var params = {
            base: base,
            baseUrl: "/pregunta",
            parent: 'admin/libro',
            templateUrlBase: "app/admin/libro/pregunta/pregunta",
            controller: 'PreguntaAdminController',
            controllerForm: 'PreguntaFormAdminController',
            translateBase: "admin.libro.pregunta."
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR_CONTENIDO']
            },
            params: {
                libroId: undefined,
                titulo: undefined
            },
            ncyBreadcrumb: {
                parent: "admin/libro/list"
            }
        });

        // Listar
        angular.merge(state.list, {
            resolve: {
                /* @ngInject */
                bloques: function (EnumService) {
                    return EnumService.get("bloque").then(function (result) {
                        return result;
                    });
                },
                /* @ngInject */
                preguntas: function ($stateParams, Pregunta) {
                    return Pregunta.findByLibro({libroId: $stateParams.libroId}).$promise.then(function (data) {
                        return data;
                    });
                }
            }
        });

        // Crear
        angular.merge(state.create, {
            url: '/crear/:bloque',
            params: {
                bloque: undefined,
                titulo: undefined,
                subbloque: undefined
            },
            resolve: {
                /* @ngInject */
                itemData: function (Pregunta) {
                    return new Pregunta();
                }
            }
        });

        // Editar
        angular.merge(state.edit, {
            url: '/:bloque/:id/editar',
            params: {
                bloque: undefined,
                subbloque: undefined
            },
            resolve: {
                /* @ngInject */
                itemData: function ($stateParams, Pregunta) {
                    return Pregunta.get({id: $stateParams.id}).$promise;
                }
            }
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
