(function () {
    'use strict';

    angular
        .module('app')
        .component('misLibros', {
            templateUrl: 'app/public/components/mislibros/mis-libros.list.html',
            controller: MisLibrosController,
            controllerAs: 'ctrl',
            bindings: {
                libros: '<',
                archivados: '<'
            }
        });

    /* @ngInject */
    function MisLibrosController(LibroAdquirido) {
        var vm = this;

        vm.ocultar = function (libroAdquirido, ocultar) {
            LibroAdquirido.ocultar({id: libroAdquirido.id, ocultar: ocultar}).$promise.then(function () {
                vm.libros.splice(vm.libros.indexOf(libroAdquirido), 1);
            });
        };
    }

})();
