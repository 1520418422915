(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('FacturaFormController', FacturaFormController);

    /* @ngInject */
    function FacturaFormController($timeout, $state, $stateParams, itemData, EnumService, Factura) {
        var vm = this;

        $timeout(function () {
            angular.element('input#nombre').focus();
        });

        vm.item = itemData;
        vm.canSave = true;

        if (!vm.item.datosFacturacion) {
            Factura.getDatosFacturacion().then(function (result) {
                vm.item.datosFacturacion = result ? result : {};
                vm.item.datosFacturacion.tipoComprador = 'PARTICULAR';
            });
        }

        // Guardar formulario
        vm.save = function () {
            Factura.saveDatosFacturacion(vm.item).then(function () {
                vm.item.version++;
                $state.go('miespacio/micompra', {id: $stateParams.id});
                Factura.descargar(vm.item.compra.id);

            });
        };

        EnumService.get('tipo-comprador').then(function (result) {
            vm.tiposComprador = result;
        });

    }

})(angular);
