


(function () {
    'use strict';

    angular.module('app').component('slider', {
        bindings: {
            slides: '<'
        },
        controllerAs: 'ctrl',
        templateUrl: 'app/public/components/slider/slider.component.html',
        controller: function ($scope, $timeout, $window) {

            var vm = this;
            vm.$onChanges = function (changes) {
                if (changes.slides) {
                    $timeout(function () {
                        angular.element($window.document.querySelector('#carousel')).carousel('cycle');
                    });
                }
            };
        }
    });
})();
