(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('miespacio/gestionprofesores', {
                parent: 'miespacio',
                url: '/profesores',
                redirectTo: 'miespacio/gestionprofesores/list',
                data: {
                    authorities: ['ROLE_CENTRO'],
                    titulo: 'miespacio.proyecto.centro.profesores',
                    tituloImg: 'assets/images/iconos/profesores.png'
                }
            })
            .state('miespacio/gestionprofesores/planes', {
                parent: 'miespacio/gestionprofesores',
                url: '/:id/planes',
                views: {
                    'contentEspacio@miespacio': {
                        template: '<datos-usuario usuario="$resolve.profesor"></datos-usuario>' +
                        '<div class="cabeceraMiEspacio">' +
                        '<img class="" ng-src="assets/images/iconos/profesores.png" />' +
                        '<h5 translate="miespacio.proyecto.centro.planes" class="tituloMiEspacio"></h5>' +
                        '</div>' +
                        '<proyectos activos="true" id="$resolve.usuarioId"></proyectos>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    usuarioId: function ($stateParams) {
                        return $stateParams.id;
                    },
                    /* @ngInject */
                    profesor: function ($stateParams, User) {
                        return User.getProfesor({id: $stateParams.id}).$promise.then(function (data) {
                            console.log("Profe:", data);
                            return data;
                        });
                    }
                }
            })
            .state('miespacio/gestionprofesores/list', {
                parent: 'miespacio/gestionprofesores',
                url: '/:page',
                params: {
                    page: {value: '1', dynamic: true}
                },
                views: {
                    'contentEspacio@miespacio': {
                        template:
                        '<gestion-profesores></gestion-profesores>'
                    }
                }
            });
    }
})();
