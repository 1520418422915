(function () {
    'use strict';

    angular.module('app')
        .factory('CodigoHelper', CodigoHelper);

    /* @ngInject */
    function CodigoHelper($state, Codigo, Proyecto, Club, AlertService) {

        return {
            usarCodigo: usarCodigo
        };

        // TODO: Antes de hacer la petición, validar el código en el lado cliente (letra de verificación). Mirar cómo está hecho el metodo de Java en RandomUtil. Meter en un servicio.

        function usarCodigo(codigo) {
            switch (codigo.substr(0, 2).toUpperCase()) {
                case "CP":
                    usarCodigoPromocional(codigo);
                    break;
                case "PL":
                    usarCodigoPlanLector(codigo);
                    break;
                case "CL":
                    usarCodigoClubLectura(codigo);
                    break;
                default:
                    AlertService.error("miespacio.codigo.error");
            }
        }

        function usarCodigoPromocional(codigo) {
            codigo = codigo.toUpperCase();
            Codigo.consumir({codigo: codigo}).$promise.then(function () {
                $state.go("miespacio/mislibros/estado", {}, {reload: true});
                codigo = "";
            });
        }

        // TODO: Igual es mejor redirigir a los detalles del club y del proyecto en lugar de a la lista

        function usarCodigoPlanLector(codigo) {
            codigo = codigo.toUpperCase();
            Proyecto.unirse({codigo: codigo}).$promise.then(function () {
                $state.go("miespacio/proyecto/list", {}, {reload: true});
                codigo = "";
            });
        }

        function usarCodigoClubLectura(codigo) {
            codigo = codigo.toUpperCase();
            Club.unirse({codigo: codigo}).$promise.then(function () {
                $state.go("miespacio/club/list", {}, {reload: true});
                codigo = "";
            });
        }

    }
})();
