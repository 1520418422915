(function () {
    'use strict';

    angular.module('app')
        .factory('Promocion', Promocion);

    /* @ngInject */
    function Promocion(ResourceHelper) {
        var entidad = "promocion";

        return ResourceHelper.getResource(entidad, {}, {
            'getDto': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/getDto',
                isArray: false
            },
            'findAll': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/findAll',
                isArray: false
            },
            'findCodigos': {
                method: 'GET',
                url: 'api/admin/' + entidad + '/findCodigos',
                isArray: false
            }
        });
    }

})();
