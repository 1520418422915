
(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER) {
        var base = "admin/admin";
        var params = {
            base: base,
            parent: 'admin/usuarios',
            view: 'contentUsuarios@admin/usuarios',
            baseUrl: "/admin",
            templateUrlBase: "app/admin/user/admin/admin",
            controller: 'AdminAdminController',
            controllerForm: 'AdminFormController',
            translateBase: "admin.admin."
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN']
            }
        });
        // Listar
        angular.merge(state.list, {});

        var genericResolves = {
            /* @ngInject */
            roles: function (EnumService) {
                return EnumService.get("rol-admin").then(function (result) {
                    return result;
                });
            }
        };

        // Crear
        angular.merge(state.create, {
            resolve: angular.merge(genericResolves, {
                /* @ngInject */
                itemData: function (User) {
                    return new User();
                }
            })
        });

        // Editar
        angular.merge(state.edit, {
            resolve: angular.merge(genericResolves, {
                /* @ngInject */
                itemData: function (User, $stateParams) {
                    return User.get({email: $stateParams.id}).$promise;
                }
            })
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
