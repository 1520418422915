(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('miespacio/deseos', {
            parent: 'miespacio',
            url: '/lista-deseos',
            data: {
                pageTitle: 'global.menu.miespacio.listadeseos',
                titulo: 'miespacio.listadeseos.title',
                tituloImg: 'assets/images/iconos/deseos.png'
            },
            views: {
                'contentEspacio': {
                    templateUrl: 'app/public/cuenta/miespacio/deseo/deseo.list.html',
                    controller: 'DeseosController',
                    controllerAs: 'ctrl'
                }
            },
            resolve: {
                /* @ngInject */
                lista: function (DeseoHelper) {
                    return DeseoHelper.getLista();
                }
            }
        });
    }
})();
