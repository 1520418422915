(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('catalogo/todo', {
            parent: 'public',
            url: '/catalogo/?scroll',
            data: {
                pageTitle: 'global.menu.catalogo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/catalogo/catalogo.html',
                    controller: 'CatalogoController',
                    controllerAs: 'ctrl'
                }
            },
            resolve: {
                /* @ngInject */
                libros: function (Libro) {
                    return Libro.filter().$promise.then(function (data) {
                        console.log(data);
                        return data.content;
                    });
                }
            }
        }).state('catalogo', {
            parent: 'public',
            url: '/catalogo?c=:criterio&v=:valor&q=:query&?scroll',
            data: {
                pageTitle: 'global.menu.catalogo'
            },
            params: {
                letra: null,
                query: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/catalogo/catalogo.html',
                    controller: 'CatalogoController',
                    controllerAs: 'ctrl'
                }
            },
            // FIXME: Meter menú lateral en una template para que no se recalcule cada vez que se cambia el estado
            resolve: {
                /* @ngInject */
                libros: function (Libro, $stateParams) {
                    console.log($stateParams);

                    return Libro.findByCriterio({
                        criterio: $stateParams.criterio,
                        valor: $stateParams.valor,
                        letra: $stateParams.letra,
                        query: $stateParams.query
                    }).$promise.then(function (data) {
                        return data.content;
                    });
                }
            }
        });
    }
})();
