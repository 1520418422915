(function () {
    'use strict';

    // Ejecución del examen: Ejecución ya existen del examen, o una nueva en caso de estar iniciando el examen.
    // Tarea: Tarea a la que pertenece el examen.
    // Bloques: Lista de preguntas agrupadas por bloques.

    angular
        .module('app')
        .component('examenDetails', {
            templateUrl: 'app/public/components/proyectos/examen/examen.details.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tarea: '<',
                ejecucion: '<',
                bloques: '<'
            }
        });

    /* @ngInject */
    function Controller(Ejecucion, ModalService) {
        var vm = this;

        console.log("ExamenForm");

        vm.item = vm.ejecucion;

        function initForm() {
            if (!vm.item.fechaInicio) {
                vm.item.fechaInicio = new Date();
            }

            if (!vm.item.respuestas) {
                vm.item.respuestas = [];
            }

            vm.totalPreguntas = 0;
            angular.forEach(vm.bloques, function (preguntas) {
                if (preguntas.length) {
                    vm.totalPreguntas += preguntas.length;
                }

                // Inicializamos las respuestas abiertas
                angular.forEach(preguntas, function (pregunta) {
                    if (pregunta.tipo === 'ABIERTA') {
                        var respuesta = vm.item.respuestas.find(function (respuesta) {
                            return respuesta.pregunta && respuesta.pregunta.id === pregunta.id;
                        });
                        if (!respuesta) {
                            vm.item.respuestas.push({pregunta: pregunta});
                        }
                    }
                });


                // Inicializamos las respuestas asignándolas a las preguntas
                angular.forEach(preguntas, function (pregunta) {
                    var respuesta = vm.item.respuestas.find(function (respuesta) {
                        return respuesta.pregunta && respuesta.pregunta.id === pregunta.id;
                    });
                    if (respuesta) {
                        pregunta.respuesta = respuesta;
                    }
                });
            });
        }

        function calcularNumPreguntasRespondidas() {
            if (!vm.item.respuestas) {
                vm.numPreguntasRespondidas = 0;
                return;
            }
            vm.numPreguntasRespondidas = vm.item.respuestas.filter(function (respuesta) {
                return respuesta && (respuesta.respuestaCorta || respuesta.respuestaTest);
            }).length;
        }

        vm.evaluar = function () {
            var respuesta3A = vm.item.respuestas.find(function (respuesta) {
                return respuesta.pregunta.bloque.nombre === 'BLOQUE_3_A';
            });
            var respuesta3B = vm.item.respuestas.find(function (respuesta) {
                return respuesta.pregunta.bloque.nombre === 'BLOQUE_3_B';
            });
            normalizarRespuesta(respuesta3A);
            normalizarRespuesta(respuesta3B);
            var ejecucion = {ejecucionId: vm.item.id, respuesta3A: respuesta3A, respuesta3B: respuesta3B};

            Ejecucion.evaluar(ejecucion).$promise.then(function (data) {
                ModalService.close(data);
            });
        };

        function normalizarRespuesta(respuesta) {
            if (respuesta) {
                var tipo = 'ABIERTA';
                if (respuesta.respuestaTest) {
                    tipo = 'TEST';
                }
                respuesta.pregunta = {id: respuesta.pregunta.id, tipo: tipo};
            }
        }

        initForm();
        calcularNumPreguntasRespondidas();
    }

})();
