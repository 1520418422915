(function () {
    'use strict';

    // Open: Campo que permite ocultar el popover

    angular
        .module('app')
        .component('notificaciones', {
            templateUrl: 'app/components/notificaciones/notificaciones.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                open: '='
            }
        });

    /* @ngInject */
    function Controller($state, Notificacion, Principal, Libro) {
        var vm = this;

        function getUserParams() {
            return Principal.identity().then(function (data) {
                if (!data) return null;
                return {rol: data.rol, id: data.id};
            });
        }

        // Notificaciones

        vm.notificaciones = [];
        var start = 0;
        var count = 10;
        vm.loadingNotificaciones = false;
        vm.loadedNotificaciones = false;

        vm.cargarNotificaciones = function () {
            if (vm.loadingNotificaciones) return;
            vm.loadingNotificaciones = true;
            getUserParams().then(function (user) {
                if (user) {
                    Notificacion.find(user.rol, user.id, start, count).then(function (data) {
                        for (var i = 0; i <= data.length - 1; i++) {
                            vm.notificaciones.push(data[i]);
                        }
                        start += 1;
                        vm.loadingNotificaciones = false;
                        if (data.length < count) vm.loadedNotificaciones = true;
                    });
                }
            });
        };

        vm.leer = function (notificacion, $event) {
            Notificacion.leer(notificacion.id).then(function () {
                notificacion.estado = 'LEIDA';
            });
            if ($event) {
                $event.stopPropagation();
            }
        };

        vm.abrir = function (notificacion) {
            vm.leer(notificacion);

            switch (notificacion.tipo) {
                case 'REGISTRO':
                    $state.go("admin/gestionaltas");
                    break;
                case 'REGISTRO_PROFESOR':
                    $state.go("miespacio/gestionprofesores");
                    break;
                case 'EXAMEN_FUTURO':
                case 'EXAMEN_CORREGIDO':
                case 'NUEVO_COMENTARIO_TAREA':
                    // FIXME: No tengo el ID del proyecto (proyectoId) y en la URL se muestra: proyectos//tarea
                    $state.go("miespacio/tarea/details", {id: notificacion.identificador}, {reload: true});
                    break;
                case 'NUEVA_RESPUESTA':
                case 'SUGERENCIA_VALORACION':
                    Libro.get({id: notificacion.identificador}).$promise.then(function (data) {
                        $state.go("libro", {tituloAbrev: data.tituloAbrev}, {reload: true});
                    });
                    break;
                case 'NUEVA_RESPUESTA_CLUB':
                case 'DESBLOQUEADO_CLUB_LECTURA':
                    $state.go("miespacio/club/details", {id: notificacion.identificador});
                    break;
                case 'NUEVO_PACK_PREMIUM_SOLICITADO':
                case 'SOLICITUD_CANCELACION_SUSCRIPCION':
                    $state.go('admin/usuario', {id: notificacion.identificador});
                    break;
                case 'NUEVO_PACK_PREMIUM_ACTIVADO':
                case 'NUEVO_PACK_PREMIUM_DESACTIVADO':
                    $state.go('miespacio/packs/mispacks');
                    break;
                case 'SOLICITUD_CAMBIO_EMAIL':
                    $state.go('admin/usuario', {id: notificacion.identificador});
                    break;

            }
            // Ocultamos popover
            vm.open = false;
        };

    }

})();
