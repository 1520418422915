(function () {
    'use strict';

    angular.module('app')
        .factory('BibliotecaService', BibliotecaService);

    function BibliotecaService() {

        return {
            init: init,
            disponible: disponible,
            abrir: abrir,
            gardar: gardar,
            eliminar: eliminar,
            obter: obter,
            eliminarVarios: eliminarVarios
        };

        function init(nomeDb, nomeObjectStore) {
            console.log("Init -> NomeDb -> ", nomeDb);
            this.nomeDb = nomeDb;
            console.log("Init -> thisNomeDb -> ", this.nomeDb);
            this.nomeObjectStore = nomeObjectStore;

            this._onforeachend = null;
        }

        function disponible() {
            return window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        }

        function abrir(onsuccess) {
            if (!disponible()) {
                throw 'indexedDB error: non soportado';
            }
            console.log("Abrir -> thisNomeDb -> ", this.nomeDb);
            var req = indexedDB.open(this.nomeDb, 1);
            req.onerror = (function () {
                throw 'indexedDB error: non se puido abrir a base de datos ' + this.nomeDb;
            }).bind(this);
            req.onsuccess = (function (evt) {
                this.db = evt.target.result;

                if (onsuccess)
                    onsuccess();
            }).bind(this);
            req.onupgradeneeded = (function (evt) {
                this.db = evt.target.result;
                this.db.createObjectStore(this.nomeObjectStore, {keyPath: "id"});
            }).bind(this);
        }

        function gardar(id, blob, onsuccess, onerror) {
            var obj = {id: id, data: blob};
            var store = this.db.transaction([this.nomeObjectStore], 'readwrite').objectStore(this.nomeObjectStore);
            var req = store.put(obj);
            req.onsuccess = (function (evt) {
                if (onsuccess)
                    onsuccess(evt.target.result);
            }).bind(this);
            req.onerror = (function () {
                if (onerror)
                    onerror(req.error);
            }).bind(this);
        }

        function eliminar(id, onsuccess, onerror) {
            var store = this.db.transaction([this.nomeObjectStore], 'readwrite').objectStore(this.nomeObjectStore);
            var req = store.delete(id);
            req.onsuccess = (function () {
                if (onsuccess)
                    onsuccess();
            }).bind(this);
            req.onerror = (function () {
                if (onerror)
                    onerror(req.error);
            }).bind(this);
        }

        function obter(id, onsuccess, onerror) {
            var store = this.db.transaction([this.nomeObjectStore]).objectStore(this.nomeObjectStore);
            var req = store.get(id);
            req.onsuccess = (function (evt) {
                if (onsuccess)
                    onsuccess(evt.target.result);

                return evt.target.result;
            }).bind(this);
            req.onerror = (function () {
                if (onerror)
                    onerror(req.error);
            }).bind(this);
        }

        function eliminarVarios(oncheck, onsuccess, oncomplete, ta) {
            var i = 0;
            var objects = [];

            var store = this.db.transaction([this.nomeObjectStore]).objectStore(this.nomeObjectStore);
            store.openCursor().onsuccess = (function (evt) {
                var cursor = evt.target.result;
                if (cursor) {
                    objects.push({key: cursor.key, value: cursor.value});
                    cursor.continue();
                } else {
                    var _oncomplete = function () {
                        i++;

                        var o = objects[i];

                        var progreso = Math.round((i / objects.length) * 100);
                        $("#libro_" + ta).find(".descargando").html("Borrando... " + progreso + "%");

                        if (i === objects.length || i > objects.length) {
                            if (oncomplete)
                                oncomplete();
                        }
                    };

                    for (var j = 0; j < objects.length; j++) {
                        if (!oncheck) {
                            _oncomplete();
                            continue;
                        }

                        if (oncheck(objects[j].key, objects[j].value)) {
                            var req = this.db.transaction([this.nomeObjectStore], 'readwrite').objectStore(this.nomeObjectStore).delete(objects[j].key);
                            req.onsuccess = (function () {
                                if (onsuccess)
                                    onsuccess();

                                _oncomplete();
                            }).bind(this);
                        } else {
                            _oncomplete();
                        }
                    }
                }
            }).bind(this);
        }
    }

})();
