(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('aviso-legal', {
                parent: 'public',
                url: '/aviso-legal',
                data: {
                    pageTitle: 'footer.info.links.avisolegal'
                },
                views: {
                    'content@': {
                        template: '<static id="aviso-legal"></static>'
                    }
                }
            })
            .state('clubes-de-lectura', {
                parent: 'public',
                url: '/clubes-de-lectura',
                data: {
                    pageTitle: 'global.menu.clubes'
                },
                views: {
                    'content@': {
                        template: '<static id="clubes-de-lectura"></static>'
                    }
                }
            })
            .state('confian', {
                parent: 'public',
                url: '/confian-en-octopusred',
                data: {
                    pageTitle: 'footer.octopusred.links.confian'
                },
                views: {
                    'content@': {
                        template: '<static id="confian"></static>'
                    }
                }
            })
            .state('cookies', {
                parent: 'public',
                url: '/cookies',
                data: {
                    pageTitle: 'footer.info.links.cookies'
                },
                views: {
                    'content@': {
                        template: '<static id="cookies"></static>'
                    }
                }
            })
            // Deshabilitado temporalmente
            // .state('diccionario', {
            //     parent: 'public',
            //     url: '/diccionario',
            //     data: {
            //         pageTitle: 'footer.octopusred.links.diccionario'
            //     },
            //     views: {
            //         'content@': {
            //             template: '<static id="diccionario"></static>'
            //         }
            //     }
            // })
            .state('formas-de-pago', {
                parent: 'public',
                url: '/formas-de-pago',
                data: {
                    pageTitle: 'footer.octopusred.links.formasdepago'
                },
                views: {
                    'content@': {
                        template: '<static id="formas-de-pago"></static>'
                    }
                }
            })
            .state('octopusred-social', {
                parent: 'public',
                url: '/octopusred-social',
                data: {
                    pageTitle: 'global.menu.octosocial'
                },
                views: {
                    'content@': {
                        template: '<static id="octopusred-social"></static>'
                    }
                }
            })
            .state('planes-de-lectura', {
                parent: 'public',
                url: '/planes-de-lectura',
                data: {
                    pageTitle: 'global.menu.planes'
                },
                views: {
                    'content@': {
                        template: '<static id="planes-de-lectura"></static>'
                    }
                }
            })
            .state('planes-de-precios', {
                parent: 'public',
                url: '/planes-de-precios',
                data: {
                    pageTitle: 'footer.octopusred.links.planesdeprecios'
                },
                views: {
                    'content@': {
                        template: '<static id="planes-de-precios"></static>'
                    }
                }
            })
            .state('preguntas-respuestas', {
                parent: 'public',
                url: '/preguntas-respuestas',
                data: {
                    pageTitle: 'footer.octopusred.links.preguntas'
                },
                views: {
                    'content@': {
                        template: '<static id="preguntas-respuestas"></static>'
                    }
                }
            })
            .state('privacidad', {
                parent: 'public',
                url: '/privacidad',
                data: {
                    pageTitle: 'footer.octopusred.links.privacidad'
                },
                views: {
                    'content@': {
                        template: '<static id="privacidad"></static>'
                    }
                }
            })
            .state('que-es-octopusred', {
                parent: 'public',
                url: '/que-es-octopusred',
                data: {
                    pageTitle: 'global.menu.quees'
                },
                views: {
                    'content@': {
                        template: '<static id="que-es-octopusred"></static>',
                        controllerAs: 'ctrl'
                    }
                }
            })
            .state('tienes-un-proyecto', {
                parent: 'public',
                url: '/tienes-un-proyecto',
                data: {
                    pageTitle: 'footer.octopusred.links.tienesproyecto'
                },
                views: {
                    'content@': {
                        template: '<static id="tienes-un-proyecto"></static>'
                    }
                }
            })
            .state('en-construccion', {
                parent: 'public',
                url: '/en-construccion',
                data: {
                    pageTitle: 'global.menu.enconstruccion'
                },
                views: {
                    'content@': {
                        template: '<static id="en-construccion"></static>',
                        controllerAs: 'ctrl'
                    }
                }
            })
        ;
    }
})();
