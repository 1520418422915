(function () {
    'use strict';

    angular.module('app')
        .controller('PackListModalController', PackListModalController);

    /* @ngInject */
    function PackListModalController($translate, ModalService, Compra, packs, centroId) {
        var vm = this;

        vm.packs = packs;
        vm.centroId = centroId;
        vm.packSeleccionado = null;
        vm.dismiss = ModalService.dismiss;

        vm.elegirPack = function (pack) {
            Compra.crearSuscripcionCentroPack({centroId: vm.centroId, packId: pack.id}).$promise.then(function () {
                ModalService.close();
            });
        }

        vm.verCatalogo = function (pack) {
            vm.packSeleccionado = pack;
        };

        vm.ocultarCatalogo = function () {
            vm.packSeleccionado = null;
        }

        for (var i = 0; i < vm.packs.length; i++) {
            vm.packs[i].textoCategoriaCentro = obtenerTextoCategoriaCentro(vm.packs[i].numAlumnos);
        }

        function obtenerTextoCategoriaCentro(categoriaCentro) {
            var split = categoriaCentro.split("_");
            var numero = split[split.length - 1];

            if (categoriaCentro != 'MAS_DE_1000') {
                return $translate.instant('catalogo.packs.alumnoshasta', {numero: numero});
            } else {
                return $translate.instant('catalogo.packs.alumnosmasde', {numero: numero});
            }
        }
    }
})();
