(function () {
    'use strict';
    angular.module('app')
        .component('estadisticasUsuarioDetails', {
            templateUrl: 'app/public/components/estadisticas/estadisticas.usuario.details.html',
            bindings: {
                estadisticas: '<',
                estadisticasLectura: '<'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($stateParams, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS,
                        Estadisticas) {
        var vm = this;
        vm.tipoFecha = "dia";

        // *** Tabla de libros

        vm.item = Estadisticas;
        vm.function = "getEstadisticasUsuarioDetallado";
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            id: vm.estadisticas.usuario.id
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {titulo: 'asc'}
        }, NgTableHelper.settings(vm));

    }
})();
